import { Box, Slide, Typography } from '@mui/material';
import { ContinueButton } from 'components/PDP/Details/components/ContinueButton/ContinueButton';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import {
  bottomContinueBtn,
  bottomContinuePanel,
  bottomContinueWrapper,
} from 'components/PDP/Details/containers/BottomContinuePanel/bottom-continue-panel.styles';
import { selectArrangement } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

interface BottomContinuePanelProps {
  quantity: number;
  selectedVariantName: string;
  onContinue: (event?: React.MouseEvent) => void;
  isBlocked: boolean;
  reveal: boolean;
}

export const BottomContinuePanel: FC<BottomContinuePanelProps> = ({
  quantity,
  selectedVariantName,
  onContinue,
  isBlocked,
  reveal,
}) => {
  const arrangement = useSelector(selectArrangement);

  return (
    <Slide in={reveal} direction="up">
      <Box sx={bottomContinuePanel}>
        <Box sx={bottomContinueWrapper}>
          <div>
            <PureHtmlTypography variant="subtitle2" component="h3">
              {arrangement.name}
            </PureHtmlTypography>
            <Typography variant="body2">{`${quantity}x ${selectedVariantName}`}</Typography>
          </div>
          <Box sx={bottomContinueBtn}>
            <ContinueButton
              dataTest="pdp-details-bottom-panel-continue-button"
              onClick={onContinue}
              disabled={isBlocked}
            />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};
