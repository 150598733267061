import { ApiBaseService } from 'api/apibase.service';
import {
  ChangePasswordRequest,
  UpdateAccountRequest,
} from 'api/account/type/account-request';

class AccountApiService extends ApiBaseService {
  updateAccountInfo(
    updateAccountRequest: UpdateAccountRequest,
  ): Promise<string> {
    return this.apibase
      .post<string>(`/account/update`, updateAccountRequest)
      .then(({ data }) => data);
  }

  changePassword(request: ChangePasswordRequest): Promise<string> {
    return this.apibase
      .post<string>(`/account/changePassword`, request)
      .then(({ data }) => data);
  }
}

export const accountApiService = new AccountApiService();
