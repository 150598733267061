import { FulfillmentOptionType } from 'types/cart.types';
import { TFunctionKeys } from 'i18next';
import {
  TITLE_DELIVERY,
  TITLE_PICKUP,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/translations/locale.constants';
import { isPickup } from 'utils/is-order-type';

export const getTitle = (orderType: FulfillmentOptionType): TFunctionKeys =>
  isPickup(orderType) ? TITLE_PICKUP : TITLE_DELIVERY;
