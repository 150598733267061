import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/styles';
import { Box, Grid, Typography } from '@mui/material';
import { NoReward } from 'components/Rewards/RewardLanding/MyRewards/NoReward';
import { Reward } from 'providers/Session/feature/user.types';
import { formatToLegacyDateString } from 'utils/date';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { strawberryBadge } from 'utils/cloudinaryAssets';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const Rewards: FC = () => {
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const rewards = userAccountInformation?.rewards || ([] as Reward[]);
  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" mb={2}>
          {i18next.t(TK.REWARD_MESSAGE)}
        </Typography>
        {rewards.length <= 0 && <NoReward />}
      </Box>
      {rewards.map((reward) => (
        <Grid key={reward.rewardId} sx={styles.rewardGrid}>
          <img src={strawberryBadge} alt={reward.imageAltTag} height="48px" />
          <Box sx={styles.promoTextContents}>
            <Typography variant="subtitle3" fontWeight="500">
              {reward.title}
            </Typography>
            <Typography variant="body3">
              {i18next.t(TK.PROMO_MESSAGE, {
                message: reward.message,
              })}
            </Typography>
          </Box>
          <Box display="grid" width="22%">
            <Typography variant="subtitle3" fontWeight="500">
              Code: {reward.code}
            </Typography>
            <Typography variant="subtitle3" fontWeight="500">
              {i18next.t(TK.PROMO_EXPIRES)}
              {formatToLegacyDateString(
                new Date(reward.expireDate.toString().replace('Z', '')),
              )}
            </Typography>
          </Box>
        </Grid>
      ))}
      <hr />
    </Box>
  );
};
