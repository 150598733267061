import * as TK from 'containers/RewardsContainer/translations/locale.constants';
import * as yup from 'yup';
import i18next from 'i18next';

const validChars = /^((?![<>]).)*$/g;
const nameRegExp = /^[a-z ,.'-]+$/i;

export const ContactInformationFormSchema = yup.object().shape({
  id: yup.number(),
  accountId: yup.number(),
  title: yup.string(),
  firstName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_ENTER_FIRST_NAME))
    .required(i18next.t(TK.ERROR_ENTER_FIRST_NAME)),
  lastName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_ENTER_LAST_NAME))
    .required(i18next.t(TK.ERROR_ENTER_LAST_NAME)),
  birthday: yup.string(),
  address1: yup
    .string()
    .matches(validChars, i18next.t(TK.ERROR_ENTER_STREET))
    .required(i18next.t(TK.ERROR_ENTER_STREET)),
  address2: yup.string(),
  addressType: yup
    .number()
    .min(1, i18next.t(TK.ERROR_ENTER_ADDRESS_TYPE))
    .required(i18next.t(TK.ERROR_ENTER_ADDRESS_TYPE)),
  city: yup.string().required(i18next.t(TK.ERROR_ENTER_CITY)),
  state: yup.string().required(i18next.t(TK.ERROR_SELECT_STATE)),
  otherState: yup.string().when('state', {
    is: 'Others',
    then: yup
      .string()
      .trim()
      .matches(validChars, i18next.t(TK.ERROR_ENTER_STATE))
      .required(i18next.t(TK.ERROR_ENTER_STATE)),
  }),
  country: yup.string().required(i18next.t(TK.ERROR_ENTER_COUNTRY)),
  zipCode: yup.string().required(i18next.t(TK.ERROR_ENTER_ZIP_CODE)),
  phone: yup
    .string()
    .min(10, `${i18next.t(TK.ERROR_ENTER_PHONE)}.`)
    .required(`${i18next.t(TK.ERROR_ENTER_PHONE)}.`)
    .test(
      'phone',
      i18next.t(TK.CANT_BEGIN_WITH_O_OR_1),
      (value) => !value || (!value.startsWith('1') && !value.startsWith('0')),
    ),
  email: yup
    .string()
    .email(i18next.t(TK.ERROR_ENTER_EMAIL))
    .required(i18next.t(TK.ERROR_ENTER_EMAIL)),
});
