import * as TK from 'translations/locales/translation-keys.constant';
import { HeaderLinks } from 'enums/header-links';
import { Menu, NavigationTypes } from 'components/Header/MobileMenus/types';

export const menus: Menu[] = [
  {
    name: TK.HEADER_FIND_STORE,
    type: NavigationTypes.LINK,
    url: HeaderLinks.STORE_FINDER,
  },
  {
    name: TK.HEADER_NAV_CUSTOMER_SERVICE,
    type: NavigationTypes.MENUS,
    groups: [
      {
        name: TK.HEADER_NAV_CUSTOMER_SERVICE,
        navigations: [
          {
            name: TK.HEADER_HELP_TRACK_YOUR_ORDER,
            url: HeaderLinks.TRACK_ORDER,
          },
          {
            name: TK.HEADER_HELP_CONTACT_US,
            url: HeaderLinks.CONTACT_US,
          },
          {
            name: TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS,
            url: HeaderLinks.PROMOTION_TERMS_CONDITIONS,
          },
          {
            name: TK.HEADER_HELP_CERTIFIED_HAPPINESS,
            url: HeaderLinks.CERTIFIED_HAPPINESS,
          },
          {
            name: TK.HEADER_HELP_FAQS,
            url: HeaderLinks.MOBILE_FAQS,
          },
          {
            name: TK.HEADER_HELP_PRODUCTS_POLICY,
            url: HeaderLinks.PRODUCTS_POLICY,
          },
        ],
      },
    ],
  },
  {
    name: TK.HEADER_NAV_STORES,
    type: NavigationTypes.MENUS,
    groups: [
      {
        name: TK.HEADER_NAV_STORES,
        navigations: [
          {
            name: TK.HEADER_STORES_FRANCHISE_OPPORTUNITIES,
            url: HeaderLinks.FRANCHISE_OPPORTUNITIES,
          },
          {
            name: TK.HEADER_FIND_STORE,
            url: HeaderLinks.STORE_FINDER,
          },
          {
            name: TK.HEADER_STORES_EDIBLE_TREATS,
            url: HeaderLinks.EDIBLE_TREATS,
          },
        ],
      },
    ],
  },
  {
    name: TK.HEADER_NAV_ABOUT,
    type: NavigationTypes.MENUS,
    groups: [
      {
        name: TK.HEADER_NAV_ABOUT_EDIBLE,
        navigations: [
          {
            name: TK.HEADER_ABOUT_WHO_WE_ARE,
            url: HeaderLinks.WHO_WE_ARE,
          },
          {
            name: TK.HEADER_ABOUT_OUR_MISSION,
            url: HeaderLinks.OUR_MISSION,
          },
          {
            name: TK.HEADER_ABOUT_OUR_HISTORY,
            url: HeaderLinks.OUR_HISTORY,
          },
          {
            name: TK.HEADER_ABOUT_FOUNDER,
            url: HeaderLinks.FOUNDER,
          },
          {
            name: TK.HEADER_ABOUT_CONTACT_US,
            url: HeaderLinks.ABOUT_CONTACT_US,
          },
        ],
      },
      {
        name: TK.HEADER_NAV_OTHER,
        navigations: [
          {
            name: TK.HEADER_OTHER_CAREERS,
            url: HeaderLinks.CAREERS,
          },
          {
            name: TK.HEADER_OTHER_PRESS,
            url: HeaderLinks.PRESS,
          },
          {
            name: TK.HEADER_OTHER_BLOG,
            url: HeaderLinks.BLOG,
          },
        ],
      },
    ],
  },
];
