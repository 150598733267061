import * as TK from 'translations/locales/translation-keys.constant';
import {
  ENTER_VALID_ZIP,
  ZIP_SHOULD_BE_VALID,
} from 'utils/pas-control.constants';
import { ResourceLanguage } from 'i18next';
import { common } from 'translations/locales/common';
import { pluralize } from 'translations/utils';

export const locale: ResourceLanguage = {
  translations: {
    ...common,
    [TK.WE_GOT_AN_ERROR]: 'We got an error. Please try again in a few minutes',
    'pdp.general.error.something_went_wrong':
      'Something went wrong. Please try again in few moments',
    'pdp.main.error.smth_went_wrong':
      'Something went wrong while getting available dates for shipment. Please try again in few moments',
    'pdp.main.error.ship_on_given_date': 'Select another date for delivery',
    'pdp.main.error.store_not_found_for_mode': 'Store is not found for mode',
    'pdp.main.error.no_argument_available_for_catalog':
      'No Arrangement available for catalog: {{catalogNumber}}',
    'pdp.main.error.no_catalog_number_available_for_url':
      'No catalog number available for page friend url: {{pageFriendlyUrl}}',
    'pdp.main.error.no_ingredient_available_for_arrangement':
      'No Ingredients available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_products_available_for_arrangement':
      'No Products available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_reviews_available_for_arrangement':
      'No Reviews available for arrangementId: {{arrangementId}}',
    'pdp.main.error.cannot_find_store': 'Cannot find store to process order',
    'pdp.main.error.city_not_found': 'City not found',
    'pdp.main.error.cannot_parse_response': "Can't parse response",
    [ZIP_SHOULD_BE_VALID]: 'Enter a valid zip code',
    [ENTER_VALID_ZIP]: 'Enter a valid zip code to continue',
    'pdp.main.error.provided_zip': 'Zip should be provided',
    'pdp.main.error.provided_date': 'Date should be provided',
    'pdp.main.error.enter_zip_and_date':
      'Enter a zip code and choose a date to continue',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL]: 'Entrez le modèle de voiture',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR]:
      'Entrez la couleur de la voiture',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL]:
      'Entrez le modèle du véhicule',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR]:
      'Entrez la couleur du véhicule',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS]:
      'Entrez un modèle de voiture valide',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS]:
      'Entrez une couleur de voiture valide',
    [TK.NUMBER_OF_SIZES_ONE]: 'Taille unique',
    [TK.NUMBER_OF_SIZES_TWO]: 'Deux tailles',
    [TK.NUMBER_OF_SIZES_THREE]: 'Trois tailles',
    [TK.NUMBER_OF_SIZES_FOUR]: 'Quatre tailles',
    [TK.NUMBER_OF_SIZES_FIVE]: 'Cinq tailles',
    [TK.NUMBER_OF_SIZES_SIX]: 'Six tailles',
    [TK.NUMBER_OF_SIZES_SEVEN]: 'Sept tailles',
    [TK.NUMBER_OF_SIZES_EIGHT]: 'Huit tailles',
    [TK.NUMBER_OF_SIZES_NINE]: 'Neuf tailles',
    [TK.NUMBER_OF_SIZES_TEN]: 'Dix tailles',
    [TK.NUMBER_OF_SIZES_STARTING_AT]: 'À partir de',
    [TK.NUMBER_OF_SIZES_NUMERIC_QUANTITY]: 'Tailles commençant à',
    [TK.NUMBER_OF_SIZES_NO_SIZES]: 'Il n’y a pas de tailles disponibles',
    [TK.IMAGE_NOT_AVAILABLE]: 'Non disponible',
    [TK.CLOSE]: 'Fermer',
    [TK.DAY_OF_WEEK_SUNDAY_LONG]: 'Dimanche',
    [TK.DAY_OF_WEEK_MONDAY_LONG]: 'Lundi',
    [TK.DAY_OF_WEEK_TUESDAY_LONG]: 'Mardi',
    [TK.DAY_OF_WEEK_WEDNESDAY_LONG]: 'Mercredi',
    [TK.DAY_OF_WEEK_THURSDAY_LONG]: 'Jeudi',
    [TK.DAY_OF_WEEK_FRIDAY_LONG]: 'Vendredi',
    [TK.DAY_OF_WEEK_SATURDAY_LONG]: 'Samedi',
    [TK.DAY_OF_WEEK_SUNDAY_SHORT]: 'Sun',
    [TK.DAY_OF_WEEK_MONDAY_SHORT]: 'Mon',
    [TK.DAY_OF_WEEK_TUESDAY_SHORT]: 'Tue',
    [TK.DAY_OF_WEEK_WEDNESDAY_SHORT]: 'Wed',
    [TK.DAY_OF_WEEK_THURSDAY_SHORT]: 'Thu',
    [TK.DAY_OF_WEEK_FRIDAY_SHORT]: 'Fri',
    [TK.DAY_OF_WEEK_SATURDAY_SHORT]: 'Sat',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_UP]: 'Inscrivez-vous',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_OUT]: 'Se déconnecter',
    [TK.HEADER_USER_PROFILE_ACCOUNT]: 'Compte',
    [TK.HEADER_USER_PROFILE_MY_REWARDS]: 'Mes récompenses',
    [TK.HEADER_USER_PROFILE_FAQ]: 'FAQs & Avantages',
    [TK.HEADER_USER_PROFILE_MY_INFORMATION]: 'Mes informations',
    [TK.HEADER_USER_PROFILE_ORDER_HISTORY]: 'Historique des commandes',
    [TK.HEADER_USER_PROFILE_GIFT_REMINDERS]: 'Rappels de cadeaux',
    [TK.HEADER_USER_PROFILE_ADDRESS_BOOK]: 'Carnet d’adresses',
    [TK.HEADER_HELP]: 'Aider',
    [TK.HEADER_FIND_STORE]: 'Trouver un magasin',
    [TK.HEADER_EDIBLE_REWARDS]: 'Récompenses Edible',
    [TK.HEADER_SHOPPING_CART]: 'Caddie',
    [TK.SUBSCRIPTION_FOOTER_TITLE]:
      'Inscrivez-vous aux promotions et offres spéciales.',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL]: 'Adresse Courriel',
    [TK.SUBSCRIPTION_FOOTER_FORM_SUBMIT]: 'Soumettre',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR]:
      'Veuillez saisir une adresse e-mail valide',
    [TK.SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR]:
      'Saisir une e-mail valide',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Acheter maintenant',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE]: 'Voir ',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND]: 'et',
    [TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS]:
      'les Conditions Générales',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER]: ' pour plus de détails.',
    [TK.SUBSCRIPTION_EMAIL_FORM_LABEL]: 'Adresse e-mail*',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Acheter maintenant',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Découvrez votre réduction',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT]:
      'Rejoignez notre liste d’abonnés pour des économies immédiates sur votre prochaine commande de ramassage.',
    [TK.NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT]:
      'Rejoignez notre liste de diffusion par e-mail ou SMS pour ',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT]:
      'économies instantanées',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT]: 'instantané',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT]: 'économies',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT]:
      'sur votre prochaine commande de ramassage',
    [TK.SUBSCRIPTION_FIRST_POPUP_TITLE]:
      'Quelque chose que vous allez adorer !',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_VAL]: '15',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_PER]: '%',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT]: 'de rabais',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_STAR]: '*',
    [TK.SUBSCRIPTION_FIRST_POPUP_DESCRIPTION]:
      'sur votre prochaine commande de ramassage lorsque vous vous inscrivez pour recevoir nos courriels dès aujourd’hui !',
    [TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE]: 'Super !',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST]:
      'Merci de vous être inscrit ! Vérifiez votre boîte de réception',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST]:
      'pour obtenir 15% de rabais sur votre prochain achat en ramassage.',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE]:
      'Connectez-vous avec nous sur les réseaux sociaux :',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT]:
      'Commandez un cadeau fraîchement préparé pour toutes les occasions !',
    [TK.SUBSCRIPTION_IMAGE_TITLE]:
      'Délicieuses Fraises enrobées de Chocolat design®',
    [TK.ERROR_PHONE]: 'Numéro de téléphone invalide',
    [TK.ERROR_EMAIL]: 'Adresse courriel invalide',
    [TK.REQUIRED_EMAIL]: 'L’adresse courriel est obligatoire',
    [TK.ERROR_AGREE]: 'Vous devez accepter la politique',
    [TK.HOME_PAGE_VIEW_ALL]: 'Voir tout',
    [TK.HOME_PAGE_CAROUSEL_FORWARD_CONTROL]:
      'Faire avancer le carrousel pour révéler plus de produits',
    [TK.HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL]:
      'Faire reculer le carrousel pour afficher les produits précédents',
    [TK.NEAREST_STORE_WEEKLY_HOURS]:
      'Afficher les heures hebdomadaires du magasin le plus proche',
    [TK.NEAREST_STORE_PHONE_CONTACT]:
      'Appeler le magasin Edible le plus proche de votre emplacement',
    [TK.NEAREST_STORE_DIRECTIONS]:
      'Obtenir les indications pour le magasin Edible le plus proche de votre emplacement',
    [TK.HOME_PAGE_H1_HEADING]:
      'Bouquets de fruits Edible Arrangements, Fraises enrobées de chocolat et Livraison de cadeaux',
    [TK.FRANCHISE_BANNER_TITLE]: 'la meilleure opportunité de franchise',
    [TK.FRANCHISE_BANNER_BODY]:
      'Ouvrez votre propre magasin Edible® et rejoignez notre communauté passionnée et mondiale de franchises',
    [TK.FRANCHISE_BANNER_BUTTON]: 'En savoir plus',
    [TK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL]: 'Inscrivez-vous',
    [TK.POPULAR_CATEGORIES]: 'Catégories populaires',
    [TK.LOG_IN]: 'S’identifier',
    [TK.SUBSCRIPTION_TERMS_OF_USE]: 'Voir les conditions d’utilisation',
    [TK.SUBSCRIPTION_FORM_PHONE_LABEL]: 'Numéro de Téléphone',
    [TK.SUBSCRIPTION_FORM_PHONE_ERROR]:
      'Veuillez saisir un numéro de téléphone valide',
    [TK.SUBSCRIPTION_FORM_SIGNUP_PHONE_ERROR]:
      'Entrez un numéro de téléphone valide',
    [TK.SUBSCRIPTION_FORM_POLICY_LABEL]: 'J’accepte les',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP]:
      'J’accepte de recevoir des messages texte automatisés périodiques de ou au nom de Edible Arrangements, LLC edible.com des promotions, des offres spéciales et des réductions au numéro de téléphone fourni ci-dessus. Je comprends que mon consentement n’est pas requis et ne constitue pas une condition de tout achat. Les frais standard de messagerie texte et de données s’appliquent. Tous les transporteurs ne sont pas couverts. Je comprends que le fait de cocher cette case constitue ma signature. Pour vous désinscrire, répondez à un SMS EA avec STOP to stop et HELP for Help.',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL]: 'de Communication.',
    [TK.SUBSCRIPTION_FORM_POLICY_ERROR]: 'Veuillez vérifier la politique',
    [TK.SUBSCRIPTION_SIGNUP_POLICY_ERROR]: 'Veuillez accepter pour continuer',
    [TK.SOCIAL_LINKS_TITLE]: 'Suivez-nous',
    [TK.PAYMENT_OPTIONS_TITLE]: 'Paiements Sécurisés Par',
    [TK.NEAREST_STORE_TITLE]: 'Votre plus Proche Edible Boutique',
    [TK.NEAREST_STORE_TODAY_LABEL]: 'Aujourd’hui',
    [TK.NEAREST_STORE_DIRECTIONS_LABEL]: 'Obtenir L’itinéraire',
    [TK.TERMS_OF_USE_LABEL]: 'Conditions d’Utilisation',
    [TK.PRIVACY_POLICY_LABEL]: 'Politique de Confidentialité',
    [TK.CALIFORNIA_PRIVACY_RIGHTS_LABEL]: 'California Droit à la vie Privée',
    [TK.CORPORATE_PROMOTION_LABEL]: 'Conditions Générales de la Promotion',
    [TK.COOKIE_SETTINGS_LABEL]: 'Paramètres des Cookies',
    [TK.CORPORATE_COPYRIGHT_LABEL]:
      'Edible Arrangements, LLC. Tous droits réservés.',
    [TK.DESKTOP_SEARCH_PLACEHOLDER]: 'Rechercher',
    [TK.SEARCH_SUGGESTED_RESULTS]: 'Résultats Suggérés:',
    [TK.SEARCH_NO_SUGGESTIONS]: 'Aucune suggestion trouvée',
    [TK.SEARCHING_TEXT]: 'Recherche de suggestions',
    [TK.ALL_RESULTS]: 'Tous les Résultats',
    [TK.RESULT]: 'Résultat',
    [pluralize(TK.RESULT)]: 'Résultats',
    [TK.HEADER_NAV_MORE_LINKS]: 'Plus de Liens',
    [TK.HEADER_NAV_LANGUAGE]: 'Langue',
    [TK.HEADER_NAV_CUSTOMER_SERVICE]: 'Service Client',
    [TK.HEADER_NAV_STORES]: 'Magasins',
    [TK.HEADER_NAV_ABOUT]: 'Environ',
    [TK.HEADER_NAV_ABOUT_EDIBLE]: 'Environ Edible Arrangements',

    [TK.HEADER_HELP_TRACK_YOUR_ORDER]: 'Suivez Votre Commande',
    [TK.HEADER_HELP_CONTACT_US]: 'Contactez-nous',
    [TK.HEADER_HELP_ALLERGY_SAFETY_INFORMATION]:
      'Informations sur les Allergies et la Sécurité',
    [TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS]:
      'Conditions Générales de la Promotion',
    [TK.HEADER_HELP_CERTIFIED_HAPPINESS]: 'Bonheur Certifié',
    [TK.HEADER_HELP_FAQS]: 'FAQs',
    [TK.HEADER_HELP_PRODUCTS_POLICY]: 'Politique Relative aux Produits',

    [TK.HEADER_STORES_FRANCHISE_OPPORTUNITIES]: 'Opportunités de Franchise',
    [TK.HEADER_STORES_EDIBLE_TREATS]: 'Edible® Traite',
    [TK.HEADER_ABOUT_WHO_WE_ARE]: 'Qui Sommes-nous',
    [TK.HEADER_ABOUT_OUR_MISSION]: 'Notre mission: to wow you®',
    [TK.HEADER_ABOUT_OUR_HISTORY]: 'Notre histoire',
    [TK.HEADER_ABOUT_FOUNDER]: 'Fondateur, Tariq Farid',
    [TK.HEADER_ABOUT_CONTACT_US]: 'Contactez-nous',
    [TK.HEADER_OTHER_CAREERS]: 'Carrières',
    [TK.HEADER_OTHER_PRESS]: 'Presser',
    [TK.HEADER_OTHER_BLOG]: 'Blog',
    [TK.HEADER_NAV_OTHER]: 'Autre',
    [TK.CHOOSE_VARIANT]: 'Choisissez une Option:',
    [TK.SINGLE_VARIANT]: 'Taille Unique:',
    [TK.VIEW_DETAILS]: 'Voir les Détails',
    [TK.HIDE_DETAILS]: 'Masquer les Détails',
    [TK.ENTER_ONE_PHONE_NUMBER]:
      'Veuillez entrer au moins un numéro de téléphone.',
    [TK.ENTER_ONE_VALID_PHONE_NUMBER]:
      'Veuillez entrer un numéro de téléphone domicile/travail/portable valide.',
    [TK.CANT_BEGIN_WITH_O_OR_1]:
      "Le numéro ne peut pas commencer par '0' ou '1'. Veuillez fournir un numéro de téléphone à 10 chiffres, en commençant par le code régional.",
    'pdp.product_code': 'Code de Produit',
    'pdp.availability.where_is_it_going': 'Où va cette gâterie?',
    'pdp.availability.postal_code_label': 'Entrez le Code Postal',
    'pdp.availability.postal_code_validate_error':
      'Veuillez entrer un Code Postal valide',
    'pas_control.title': 'Livraison & Décroche',
    'pdp.availability.zip_unfortunately_does_not_service':
      'Pardon! Ce produit n’est pas disponible dans la zone que vous avez sélectionnée.',
    'pdp.date_selection.when': 'Quand?',
    'pdp.date_selection.today': 'Aujourd’hui',
    'pdp.date_selection.tomorrow': 'Demain',
    'pdp.date_selection.date_picker': 'Sélectionnez la Date',
    'filters.filters': 'Filtres',
    'filters.clear_all': 'Effacer Tous les Filtres',
    'plp.filter_chip.clear_filters': 'Effacer Tous les Filtres',
    'sort.sort_by': 'Trier Par',
    'sort_select.price': 'Prix',
    'sort_select.relevance': 'Pertinence',
    'sort_select.price_desktop_asc': 'Prix — Faible à Élevé',
    'sort_select.price_desktop_desc': 'Prix — Élevé à Faible',
    'pdp.delivery_method.question': 'Comment aimeriez-vous l’obtenir?',
    'pdp.accordion.description': 'Environ',
    'pdp.accordion.ingredients': 'Description du Produit',
    'pdp.accordion.questions': 'Questionne? Demandez-le!',
    'reviews.review': 'Critiques',
    'reviews.reviews': 'Critiques',
    'pdp.accordion.faq': 'Produit FAQs',
    'plp.load_more_button': 'Charger Plus',
    'pdp.button.continue': 'Continuer',
    'upsell.continue': 'Continuer',
    'plp.call-center-welcome-modal.continue': 'Continuer',
    'signup_back.text': 'Retour',
    'common.back': 'Retour',
    'auth.dialog.modal.header': "S'identifier",
    'customer_service_page.track_order_title': 'Suivez Votre Commande',
    'customer_service_page.track_order_helper_text':
      'Pour suivre l’état actuel de votre commande, veuillez entrer votre numéro de commande',
    'customer_service_page.track_order_order_number_label_text':
      'Numéro de Commande',
    'customer_service_page.track_order_tooltip_text':
      'Votre numéro de commande se trouve dans l’e-mail de confirmation de commande que vous avez reçu de notre part.',
    'customer_service_page.track_order_btn_text': 'Suivre la Commande',
    'customer_service_page.edible_home_page': 'Domicile',
    'customer_service_page.track_order_order_number_valid_error':
      'Le numéro de commande que vous avez entré n’est pas valide',
    'customer_service_page.track_order_order_number_required_error':
      'Numéro de Commande Requis',
    'customer_service_page.track_order_order_number_over_error':
      'Le numéro de commande doit comporter au maximum 12 caractères',
    'customer_service_page.track_order_order_number_under_error':
      'Le numéro de commande doit comporter au moins 10 caractères',
    'customer_service_page.track_order_order_not_found_title_error':
      'Commande Introuvable',
    'customer_service_page.track_order_order_not_found_error_message':
      'Aucun enregistrement de ce numéro de suivi ne peut être trouvé pour le moment, veuillez vérifier le numéro et réessayer plus tard. Pour obtenir de l’aide, veuillez communiquer avec',
    'customer_service_page.track_order_customer_service': 'Service Client',
    'contact_us.text': 'Contactez-nous',
    'contact_us_form.header':
      'Nous promettons de rendre les moments WOW possibles pour chaque invité, c’est vous! S’il y a un problème avec votre commande, l’aide est à portée de clic.',
    'contact_us_form.reason': 'En quoi pouvons-nous vous aider?',
    'contact_us_form.order': 'Numéro de Commande',
    'contact_us_form.user_type':
      'Avez-vous passé la commande (Client) ou avez-vous reçu la commande en cadeau (Destinataire)?',
    'contact_us_form.first_name': 'Prénom',
    'contact_us_form.last_name': 'Nom',
    'contact_us_form.email': 'Messagerie électronique',
    'contact_us_form.phone': 'Numéro de téléphone',
    'contact_us_form.message': 'Message',
    'contact_us_form.first_name_spl_chars': 'Entrez un prénom valide',
    'contact_us_form.last_name_spl_chars': 'Entrez un nom de famille valide',
    'contact_us_form.send_request': 'Envoyer une Demande',
    'contact_us_form.best_time_to_call': 'Meilleur moment pour appeler',
    'contact_us_form.provide_information':
      'Veuillez fournir autant d’informations que possible. Un membre de l’équipe vous contactera dès que possible.',
    'pdp.delivery_method.pickup_in_store': 'Cueillette en Magasin',
    'pdp.delivery_method.free': 'Gratuit',
    'pdp.delivery_method.hand_delivery': 'Livraison',
    'store_selector.title.pickup': 'Sélectionnez un magasin de ramassage local',
    'upsell.title': 'Améliorez Votre Cadeau',
    'upsell.subtitle':
      'Ajoutez une touche personnelle à votre arrangement avec des ballons, un ours en peluche en peluche, des fruits gastronomiques trempés dans du chocolat et plus encore!',
    'upsell.options.show_all': 'Afficher tout',
    show_more: 'Afficher plus',
    show_less: 'Afficher moins',
    'upsell.options.show_more': 'Afficher Plus',
    'session.lock.reset': 'Réinitialisation',
    'order.header.pickup': 'Informations sur le Ramassage',
    'order.header.shipment': 'Informations sur L’expédition',
    'order.header.delivery': 'Informations de Livraison',
    'recipient_form.recipient_name': 'Nom du Destinataire',

    'recipient_form.first_name_placeholder': 'Prénom',
    'recipient_form.last_name_placeholder': 'Nom',
    'recipient_form.address_title': 'Adresse',
    'recipient_form.address_type_label': 'Type D’adresse',

    'recipient_form.business_name_label': '{{type}} nom',
    'recipient_form.city_label': 'Ville / Village',
    'recipient_form.po_box_note':
      'Veuillez noter que nous ne pouvons pas livrer à des boîtes postales.',
    'recipient_form.street_address': 'Adresse Municipale',

    'recipient_form.apt_floor_suite_button': 'Apt/Étage/Suite',
    'recipient_form.apt_floor_suite_label': 'Apt/Étage/Suite (facultatif)',
    'recipient_form.business_warning':
      'Nous ne conseillons pas les livraisons le jour même après 15h00 car de nombreuses entreprises ferment à 17h00. Votre magasin local peut vous contacter si vous passez une commande le jour même pour la livraison afin de reporter votre commande à un autre jour ouvrable.',
    'recipient_form.choose_option': 'Veuillez Choisir',
    'recipient_form.pickup_time': 'Heure de Ramassage',
    'recipient_form.enter_first_name': 'Entrez le prénom.',
    'recipient_form.enter_last_name': 'Entrez le nom de famille.',
    'login_or_address_book.login_text':
      'Connectez-vous au carnet d’adresses Access',
    'pas_control.select_local_pickup_shop':
      'Sélectionnez un magasin de ramassage local',
    'pickup_form.identification': 'Identification',
    'pickup_form.phone.label': 'Numéro de Téléphone de Ramassage',
    'pickup_form.date_time.header': 'Date & Heure',
    'pickup_form.date_time.label': 'Heure de Ramassage',
    'pickup_form.date_time.alert.on': 'Ramassage le:',
    'pickup_form.instruction.button': 'Instructions de Ramassage',
    'pickup_form.instruction.header': 'Instructions de Ramassage',
    'pickup_form.instruction.input.label':
      'Instructions de Ramassage (facultatif)',
    'instructions.delivery_instructions_hint':
      'Carrefours, points de repère, informations pour les communautés fermées, etc.',
    'order.occasion.header': 'Quelle est L’occasion?',
    'order.occasion.alert':
      'Aidez-nous à mieux comprendre les occasions et les événements tendance afin que nous puissions améliorer nos produits',
    'order.occasion.select.label': 'Occasion ou Événement (facultatif)',
    'order.occasion.option_title': 'Veuillez Choisir',
    'greetings_cards.heartfelt_message': 'Laissez-leur un message sincère!',
    'greetings_cards.gift_message_free_hint':
      'Nos friandises feront bonne impression, mais votre carte peut être un souvenir qu’ils chériront pour les années à venir.',
    'greetings_cards.gift_message_free_explain':
      'Ce message apparaîtra sur l’étiquette volante de votre cadeau. Il ne sera pas enfermé dans une enveloppe.',
    'greetings_cards.enter_card_message': 'Entrez un Message Cadeau Gratuit',
    'greetings_cards.remove_card_message': 'Supprimer le Message Cadeau',
    'greetings_cards.optional': 'facultatif',
    'greetings_cards.top_pick': 'PREMIER CHOIX',
    'greetings_cards.premium_card': 'Ajouter une carte Premium',
    'greetings_cards.remove_card': 'Retirer la Carte',
    'greetings_cards.card_preview': 'Aperçu de la carte',
    'greetings_cards.printible_iframe_error':
      'Erreur de prise en charge du navigateur: Le navigateur ne prend pas en charge iframe.',
    'greetings_cards.printible_subtitle':
      'Téléchargez votre <strong>propre photo</strong> ou utilisez une carte préconçue avec votre <strong>message personnalisé</strong>',
    'greetings_cards.complimentary_card': 'Carte Gratuite',
    'pdp.button.add_to_cart': 'Ajouter au Panier',
    'pdp.button.redicting': 'Redirection...',
    'pdp.button.submitting': 'Soumettre...',
    'session.lock.more_info': 'Plus d’infos',
    'session.lock.less_info': 'Moins d’infos',
    'store_selector.show_more_delivery_stores':
      'Afficher Plus de Magasins de Livraison',
    'order.contacts.alert':
      'Veuillez fournir au moins un numéro de téléphone du destinataire, nous utiliserons ce numéro en cas de complications à l’accouchement',
    'order.contacts.header': 'Coordonnées du Bénéficiaire',
    'order.contacts.add.button': 'Téléphone Cellulaire et/ou Professionnel',
    'order.contacts.phone': 'Téléphone Résidentiel',
    'order.contacts.cell_phone': 'Téléphone Portable',
    'order.contacts.work_phone': 'Téléphone Professionnel',
    'order.contacts.email.label': 'E-mail du Destinataire (facultatif)',
    'instructions.delivery_instructions': 'Instructions de Livraison',
    'instructions.delivery_instructions_label':
      'Instructions de Livraison (facultatif)',
    'instructions.read_our_delivery_policies':
      'Lisez nos Politiques de Livraison',
    'instructions.please_dont_enter_time':
      'Veuillez ne pas saisir de demande de délai de livraison ici.',
    'auth.log_in.error.header': 'Votre compte est verrouillé',
    'auth.log_in.error.locked':
      'Vous avez eu trop de tentatives de connexion infructueuses. Veuillez réessayer plus tard',
    'auth.log_in.error.try_again': 'Veuillez réessayer après un certain temps',
    'auth.log_in.error.invalid_credentials':
      'Veuillez entrer votre adresse e-mail et votre mot de passe',
    'auth.log_in.error.not_exist':
      'Nous n’avons pas pu trouver d’utilisateur avec cet e-mail et ce mot de passe. Veuillez réessayer',
    'auth.log_in.error._exception':
      'Nous avons un problème pour vous inscrire, désolé pour la gêne occasionnée. Veuillez réessayer plus tard',
    'auth.dialog.modal.registration.tip': 'Vous n’avez pas de compte?',
    'auth.dialog.modal.registration': 'Créer un Compte',
    'auth.log_in.form.email.label': 'Courriel',
    'auth.log_in.form.password.label': 'Mot de Passe',
    'auth.log_in.form.password.placeholder': 'au moins 8 caractères',
    'auth.log_in.form.reset.label': 'Vous avez oublié votre mot de passe?',
    'auth.log_in.form.signing.in': 'Connexion...',
    'auth.log_in.form.success': 'Succès',
    'auth.log_in.form.sign.in': 'Connexion',
    'auth.err.email.invalid': 'Veuillez saisir une adresse e-mail valide',
    'auth.log_in.err.password.required': 'Le mot de passe est requis',
    'auth.log_in.err.email.required': 'L’adresse e-mail est requise',
    'auth.log_in.dialog.reset.header': 'Mot de passe oublié',
    'auth.reset.tip':
      'Entrez votre adresse e-mail ci-dessous et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    'auth.reset.email.label': 'Courriel',
    'auth.reset.submitting': 'Connexion',
    'auth.reset.submit': 'Connexion',
    'auth.reset.sent.1': 'L’aide est en route!',
    'auth.reset.sent.2':
      'Nous vous avons envoyé un e-mail avec des instructions sur la façon de réinitialiser votre mot de passe.',
    'auth.reset.sent.3': 'Soyez à l’affût bientôt!',
    'auth.reset.status.not_found':
      'Désolé, nous n’avons pas pu trouver cette adresse e-mail. Veuillez réessayer ou créer un nouveau compte',
    'signup_email_already_exist.text':
      'Vous êtes déjà membre Edible Rewards. Connectez-vous à votre compte pour voir vos avantages.',

    'signup.text': 'Créer un Nouveau Compte',
    'signup_account_infromation.text': 'Informations sur le Compte',
    'signup_your_name.text': 'Votre Nom',
    'signup_birthday.text': 'Anniversaire',
    'signup_email_address.text': 'Adresse Courriel',
    'signup_email_not_valid_error.text':
      'Veuillez saisir une adresse e-mail valide',
    'signup_password.text': 'Mot de Passe',
    'signup_password.strength': 'Force du mot de Passe',
    'signup_password.invalid':
      'Le mot de passe doit avoir au moins 1 caractère spécial (!@#$%^&*)',
    'signup_password.minimum':
      'Le mot de passe doit contenir au moins 8 caractères',
    'signup.verify_password_error': 'Vérifier le mot de Passe',
    'signup.verify_password_match': 'Les mots de passe doivent correspondre',
    'signup_first_name.text': 'Prénom',
    'signup_phone_number.text': 'Numéro de Téléphone',
    'signup_last_name.text': 'Nom',
    'signup_birthday.tooltip':
      'Dites-nous votre date de naissance et votre mois et nous vous enverrons un cadeau d’anniversaire gratuit!',
    'signup_birth_date.text': 'Jour',
    'signup_birth_day.text': 'Jour',
    'signup_birth_day.error': 'Entrez le jour de naissance',
    'signup_birth_month.text': 'Mois',
    'signup_birth_month.error': 'Entrez le mois de naissance',
    'signup_birth_year.text': 'Année (facultatif)',
    'signup_birth_year.placeholder': 'Année',
    'signup_password.requirements':
      'Votre mot de passe doit comporter au moins 8 caractères et contenir au moins 1 caractère spécial (!@#$%^&*).',
    'signup_first_name.required': 'Entrez le prénom',
    'signup_last_name.required': 'Entrez le nom de famille',
    'signup_first_name.error': 'Entrez un prénom valide',
    'signup_last_name.error': 'Entrez un nom de famille valide',
    'signup_phone_number.error': 'Entrez un numéro de téléphone valide',
    'signup_field.required': 'Ce champ est obligatoire',
    'signup_form.choose_option': 'Veuillez Choisir',
    'signup_create_account_button.text': 'Créer un Compte',
    'signup_success_title.text': 'Félicitations!',
    'signup_success_content.text':
      'Visitez Mon compte pour voir vos récompenses disponibles.',
    'signup_my_account_button.text': 'Mon Compte',
    'signup_shop_now_button.text': 'Acheter Maintenant',
    'signup_link_terms_of_use.text': 'Conditions d’utilisation',
    'signup_link_privacy_policy.text': 'Notre politique de confidentialité',

    'signup_link_products_policy.text': 'Politique Relative aux Produits',
    'signup_link_edible_rewards.text': 'Edible Rewards® Termes & conditions',
    'signup_link_click_here.text': 'cliquez ici',
    'signup_terms_and_conditions.text':
      'En cliquant sur le bouton marqué CRÉER UN COMPTE ou par tout autre acte de votre part d’utiliser le site Edible Arrangements® ou nos services de quelque manière que ce soit, vous reconnaissez avoir lu et compris et accepté d’être légalement lié par tous les termes et conditions de notre site Web {{termsOfUse}}, {{privacyPolicy}}, {{productsPolicy}}, {{edibleRewards}} et toutes les conditions d’utilisation supplémentaires ou n’acceptent pas d’être liés par toutes les conditions d’utilisation ou conditions supplémentaires, {{clickHere}} pour plus d’informations concernant les Edible Rewards®.',
    'signup_contact_information.text': 'Coordonnées',
    'search.helper.text': 'Search for products, stores and information...',
  },
};
