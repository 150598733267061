import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { Box, Button, Typography } from '@mui/material';
import { ChangePasswordForm } from 'components/Rewards/RewardLanding/Account/ChangePasswordForm';
import { EditAccountForm } from 'components/Rewards/RewardLanding/Account/EditAccountForm';
import { format } from 'date-fns';
import { getAddressType, getCountryFullName } from 'components/Header/utils';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';
import i18next from 'i18next';

export const Account: FC = () => {
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const account = userAccountInformation?.accountInfo;
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isEditAccount, setIsEditAccount] = useState(false);

  const handlePasswordChangeClick = () => {
    setIsChangingPassword((prevState) => !prevState);
  };
  const handleEditClick = () => {
    setIsEditAccount((prevState) => !prevState);
  };
  return (
    <>
      <Box sx={styles.accountContainer}>
        <Box>
          <Box
            sx={{
              display: isEditAccount ? 'block' : ['block', 'flex', 'flex'],
            }}
          >
            <Typography
              variant="h4"
              sx={styles.accountTitle}
              width={['325px', '455px', 'auto']}
            >
              {i18next.t(TK.ACCOUNT_PASSWORD_TITLE)}
            </Typography>
            {account && (
              <Box sx={{ display: 'flex' }}>
                {isEditAccount ? (
                  <Box>
                    <EditAccountForm
                      account={account}
                      setIsEditAccount={setIsEditAccount}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Button sx={styles.editAccount} onClick={handleEditClick}>
                      {i18next.t(TK.ACTION_EDIT)}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {account && isEditAccount === false && (
            <>
              <Box sx={{ marginTop: '20px' }}>
                <Typography
                  variant="subtitle1"
                  mb={2}
                  sx={styles.accountSubTitle}
                >
                  {i18next.t(TK.ACCOUNT_PASSWORD_SUBTITLE)}
                </Typography>
                <Box sx={styles.accountGridList}>
                  {account[0].title && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_TITLE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {' '}
                        {account[0].title}
                      </Typography>
                    </Box>
                  )}
                  {account[0].firstName && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_FIRST_NAME)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].firstName ? account[0].firstName : ''}
                      </Typography>
                    </Box>
                  )}
                  {account[0].lastName && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_LAST_NAME)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].lastName ? account[0].lastName : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={styles.accountGridList}>
                  {(account[0].cellPhone ||
                    account[0].workPhone ||
                    account[0].homePhone) && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.PHONE_NUMBER)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].cellPhone ||
                          account[0].workPhone ||
                          account[0].homePhone}
                      </Typography>
                    </Box>
                  )}
                  {account[0].email && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.EMAIL_ADDRESS)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].email ? account[0].email : ''}
                      </Typography>
                    </Box>
                  )}
                  {account[0].birthday && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_BIRTHDAY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].birthday
                          ? format(
                              new Date(
                                account[0].birthday.toString().replace('Z', ''),
                              ),
                              'MM/dd',
                            )
                          : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.addressContainer}>
                {account && (
                  <Typography
                    variant="subtitle1"
                    mb={2}
                    sx={styles.accountSubTitle}
                  >
                    {i18next.t(TK.ACCOUNT_MY_ADDRESS)}
                  </Typography>
                )}
                {account[0].addressType > 0 && (
                  <Box sx={styles.accountGridList}>
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_ADDRESS_TYPE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {getAddressType(
                          account[0].addressType ? account[0].addressType : 0,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box sx={styles.accountGridList}>
                  {account[0].address1 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_STREET_ADDRESS)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].address1 ? account[0].address1 : ''}
                      </Typography>
                    </Box>
                  )}
                  {account[0].address2 && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_APARTMENT)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].address2 ? account[0].address2 : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={styles.accountGridList}>
                  {account[0].countryId && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_COUNTRY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {getCountryFullName(
                          account[0].countryId ? account[0].countryId : 0,
                        )}
                      </Typography>
                    </Box>
                  )}
                  {account[0].city && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_CITY)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].city ? account[0].city : ''}
                      </Typography>
                    </Box>
                  )}
                  {account[0].stateAbbreviation && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_STATE)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].stateAbbreviation
                          ? account[0].stateAbbreviation
                          : ''}
                      </Typography>
                    </Box>
                  )}
                  {account[0].area && (
                    <Box sx={styles.accountInfoSubContainer}>
                      <Typography sx={styles.accountInfoTitle}>
                        {i18next.t(TK.ACCOUNT_ZIP)}
                      </Typography>
                      <Typography sx={styles.accountInfoDetails}>
                        {account[0].area ? account[0].area : ''}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px' }} />
      <Typography variant="h4" mb={2} sx={styles.accountTitle}>
        {i18next.t(TK.PASSWORD_TITLE)}
      </Typography>
      {account && (
        <Box>
          {isChangingPassword ? (
            <ChangePasswordForm setIsChangingPassword={setIsChangingPassword} />
          ) : (
            <Box>
              <Typography ml={2}>Password</Typography>
              <Typography ml={2}>•••••••••••</Typography>
              <Button
                sx={styles.changePassword}
                onClick={handlePasswordChangeClick}
              >
                {i18next.t(TK.ACCOUNT_CHANGE_PASSWORD)}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
