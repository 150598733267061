import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/Header/Menus/AccountMenu/styles';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { Key } from 'enums/key';
import { MenuButton } from 'components/Header/Menus/AccountMenu/MenuButton';
import { analytics } from 'service/adobe-analytics/analytics';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useMenu } from 'hooks/useMenu';
import { useOpenLogInDialog } from 'components/Authentication/hooks/control';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, KeyboardEvent, memo, useEffect, useRef } from 'react';
import i18next from 'i18next';

export const GuestMenu: FC = memo(() => {
  useTranslation();
  const openLogInDialog = useOpenLogInDialog();
  const ref = useRef<HTMLDivElement>(null);
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const onLoginClick = () => {
    handleClose();
    openLogInDialog();
    analytics.headerMenuItemClicked(i18next.t(TK.LOG_IN));
    segment.menuItemClicked(
      i18next.t(TK.LOG_IN),
      '',
      userSession,
      userAccountInformation,
    );
  };

  useEffect(() => {
    if (anchorEl) {
      analytics.headerMenuItemClicked(
        i18next.t(TK.HEADER_USER_PROFILE_ACCOUNT),
      );
      segment.menuItemClicked(
        i18next.t(TK.HEADER_USER_PROFILE_ACCOUNT),
        '',
        userSession,
        userAccountInformation,
      );
    }
  }, [anchorEl, handleClick]);

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleClose();
    }
    if (
      e.key === Key.Tab &&
      ref.current != null &&
      (e.target as HTMLDivElement).getAttribute('data-test') ===
        'edible-rewards-header'
    ) {
      ref.current.focus();
    }
  };

  return (
    <>
      <MenuButton onClick={handleClick} open={open}>
        {i18next.t(TK.HEADER_USER_PROFILE_ACCOUNT)}
      </MenuButton>
      <Menu
        id="account-menu"
        data-test="account-div"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'help-menu-button',
        }}
      >
        <Box
          sx={styles.authButtons}
          tabIndex={-1}
          onKeyDown={onKeyDown}
          ref={ref}
        >
          <Button data-test="log-in" onClick={onLoginClick} tabIndex={0}>
            {i18next.t(TK.LOG_IN)}
          </Button>
          <Typography variant="body2">{i18next.t(TK.OR)}</Typography>
          <Button
            data-test="sign-up"
            onClick={() => {
              analytics.headerMenuItemClicked(
                i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_UP),
              );
              segment.menuItemClicked(
                i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_UP),
                '/account/sign-up',
                userSession,
                userAccountInformation,
              );
            }}
            href="/account/sign-up"
            tabIndex={0}
          >
            {i18next.t(TK.HEADER_USER_PROFILE_MENU_SIGN_UP)}
          </Button>
        </Box>
        <Box tabIndex={-1} onKeyDown={onKeyDown}>
          <MenuItem
            onClick={() => {
              analytics.headerMenuItemClicked(
                i18next.t(TK.HEADER_EDIBLE_REWARDS),
              );
              segment.menuItemClicked(
                i18next.t(TK.HEADER_EDIBLE_REWARDS),
                HeaderLinks.REWARDS,
                userSession,
                userAccountInformation,
              );
              handleClose();
            }}
            component="a"
            href={HeaderLinks.REWARDS}
            data-test="edible-rewards-header"
            tabIndex={0}
          >
            <Typography variant="subtitle2">
              {i18next.t(TK.HEADER_EDIBLE_REWARDS)}
            </Typography>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
});
