import * as TK from 'translations/locales/translation-keys.constant';
import {
  ENTER_VALID_ZIP,
  ZIP_SHOULD_BE_VALID,
} from 'utils/pas-control.constants';
import { ResourceLanguage } from 'i18next';
import { common } from 'translations/locales/common';

export const locale: ResourceLanguage = {
  translations: {
    ...common,
    [TK.WE_GOT_AN_ERROR]: 'We got an error. Please try again in a few minutes',
    'pdp.general.error.something_went_wrong':
      'Something went wrong. Please try again in few moments',
    'pdp.main.error.smth_went_wrong':
      'Something went wrong while getting available dates for shipment. Please try again in few moments',
    'pdp.main.error.ship_on_given_date': 'Select another date for delivery',
    'pdp.main.error.store_not_found_for_mode': 'Store is not found for mode',
    'pdp.main.error.no_argument_available_for_catalog':
      'No Arrangement available for catalog: {{catalogNumber}}',
    'pdp.main.error.no_catalog_number_available_for_url':
      'No catalog number available for page friend url: {{pageFriendlyUrl}}',
    'pdp.main.error.no_ingredient_available_for_arrangement':
      'No Ingredients available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_products_available_for_arrangement':
      'No Products available for arrangementId: {{arrangementId}}',
    'pdp.main.error.no_reviews_available_for_arrangement':
      'No Reviews available for arrangementId: {{arrangementId}}',
    'pdp.main.error.cannot_find_store': 'Cannot find store to process order',
    'pdp.main.error.city_not_found': 'City not found',
    'pdp.main.error.cannot_parse_response': "Can't parse response",
    [ZIP_SHOULD_BE_VALID]: 'Enter a valid zip code',
    [ENTER_VALID_ZIP]: 'Enter a valid zip code to continue',
    'pdp.main.error.provided_zip': 'Zip should be provided',
    'pdp.main.error.provided_date': 'Date should be provided',
    'pdp.main.error.enter_zip_and_date':
      'Enter a zip code and choose a date to continue',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL]: 'Enter Car Model',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR]: 'Enter Car Color',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL]: 'Enter Vehicle Model',
    [TK.RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR]: 'Enter Vechicle Color',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS]:
      'Enter a valid car model',
    [TK.RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS]:
      'Enter a valid car color',
    [TK.NUMBER_OF_SIZES_ONE]: 'One Size',
    [TK.NUMBER_OF_SIZES_TWO]: 'Two Sizes',
    [TK.NUMBER_OF_SIZES_THREE]: 'Three Sizes',
    [TK.NUMBER_OF_SIZES_FOUR]: 'Four Sizes',
    [TK.NUMBER_OF_SIZES_FIVE]: 'Five Sizes',
    [TK.NUMBER_OF_SIZES_SIX]: 'Six Sizes',
    [TK.NUMBER_OF_SIZES_SEVEN]: 'Seven Sizes',
    [TK.NUMBER_OF_SIZES_EIGHT]: 'Eight Sizes',
    [TK.NUMBER_OF_SIZES_NINE]: 'Nine Sizes',
    [TK.NUMBER_OF_SIZES_TEN]: 'Ten Sizes',
    [TK.NUMBER_OF_SIZES_STARTING_AT]: 'Starting At',
    [TK.NUMBER_OF_SIZES_NUMERIC_QUANTITY]: 'Sizes Starting At',
    [TK.NUMBER_OF_SIZES_NO_SIZES]: 'There are no available sizes',
    [TK.IMAGE_NOT_AVAILABLE]: 'Not available',
    [TK.CLOSE]: 'Close',
    [TK.DAY_OF_WEEK_SUNDAY_LONG]: 'Sunday',
    [TK.DAY_OF_WEEK_MONDAY_LONG]: 'Monday',
    [TK.DAY_OF_WEEK_TUESDAY_LONG]: 'Tuesday',
    [TK.DAY_OF_WEEK_WEDNESDAY_LONG]: 'Wednesday',
    [TK.DAY_OF_WEEK_THURSDAY_LONG]: 'Thursday',
    [TK.DAY_OF_WEEK_FRIDAY_LONG]: 'Friday',
    [TK.DAY_OF_WEEK_SATURDAY_LONG]: 'Saturday',
    [TK.DAY_OF_WEEK_SUNDAY_SHORT]: 'Sun',
    [TK.DAY_OF_WEEK_MONDAY_SHORT]: 'Mon',
    [TK.DAY_OF_WEEK_TUESDAY_SHORT]: 'Tue',
    [TK.DAY_OF_WEEK_WEDNESDAY_SHORT]: 'Wed',
    [TK.DAY_OF_WEEK_THURSDAY_SHORT]: 'Thu',
    [TK.DAY_OF_WEEK_FRIDAY_SHORT]: 'Fri',
    [TK.DAY_OF_WEEK_SATURDAY_SHORT]: 'Sat',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_UP]: 'Sign Up',
    [TK.HEADER_USER_PROFILE_MENU_SIGN_OUT]: 'Sign Out',
    [TK.HEADER_USER_PROFILE_ACCOUNT]: 'Account',
    [TK.HEADER_USER_PROFILE_MY_REWARDS]: 'My Rewards',
    [TK.HEADER_USER_PROFILE_FAQ]: 'FAQs & Benefits',
    [TK.HEADER_USER_PROFILE_MY_INFORMATION]: 'My Information',
    [TK.HEADER_USER_PROFILE_ORDER_HISTORY]: 'Order History',
    [TK.HEADER_USER_PROFILE_GIFT_REMINDERS]: 'Gift Reminders',
    [TK.HEADER_USER_PROFILE_ADDRESS_BOOK]: 'Address Book',
    [TK.HEADER_HELP]: 'Help',
    [TK.HEADER_FIND_STORE]: 'Find a Store',
    [TK.HEADER_EDIBLE_REWARDS]: 'Edible Rewards',
    [TK.HEADER_SHOPPING_CART]: 'Shopping Cart',
    [TK.HEADER_HELP_TRACK_YOUR_ORDER]: 'Track Your Order',
    [TK.HEADER_HELP_CONTACT_US]: 'Contact Us',
    [TK.HEADER_HELP_ALLERGY_SAFETY_INFORMATION]: 'Allergy & Safety Information',
    [TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS]: 'Promotion Terms & Conditions',
    [TK.HEADER_HELP_CERTIFIED_HAPPINESS]: 'Certified Happiness',
    [TK.HEADER_HELP_FAQS]: 'FAQs',
    [TK.HEADER_HELP_PRODUCTS_POLICY]: 'Products Policy',
    [TK.HEADER_STORES_FRANCHISE_OPPORTUNITIES]: 'Franchise Opportunities',
    [TK.HEADER_STORES_EDIBLE_TREATS]: 'Edible® treats',
    [TK.HEADER_ABOUT_WHO_WE_ARE]: 'Who we are',
    [TK.HEADER_ABOUT_OUR_MISSION]: 'Our mission: to wow you®',
    [TK.HEADER_ABOUT_OUR_HISTORY]: 'Our history',
    [TK.HEADER_ABOUT_FOUNDER]: 'Founder, Tariq Farid',
    [TK.HEADER_ABOUT_CONTACT_US]: 'Contact Us',
    [TK.HEADER_OTHER_CAREERS]: 'Careers',
    [TK.HEADER_OTHER_PRESS]: 'Press',
    [TK.HEADER_OTHER_BLOG]: 'Blog',
    [TK.HEADER_NAV_CUSTOMER_SERVICE]: 'Customer Service',
    [TK.HEADER_NAV_STORES]: 'Stores',
    [TK.HEADER_NAV_ABOUT]: 'About',
    [TK.HEADER_NAV_ABOUT_EDIBLE]: 'About Edible Arrangements',
    [TK.HEADER_NAV_OTHER]: 'Other',
    [TK.HEADER_NAV_MENU]: 'Menu',
    [TK.HEADER_NAV_MORE_LINKS]: 'More Links',
    [TK.HEADER_NAV_LANGUAGE]: 'Language',
    [TK.SLIDER_NEXT]: 'Next',
    [TK.SLIDER_PREVIOUS]: 'Previous',
    [TK.SUBSCRIPTION_FOOTER_TITLE]:
      'Sign up for promotions and special offers.',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL]: 'Email Address',
    [TK.SUBSCRIPTION_FOOTER_FORM_SUBMIT]: 'Submit',
    [TK.SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR]:
      'Please enter a valid email address',
    [TK.SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR]: 'Enter a valid email',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Shop Now',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE]: 'See ',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND]: 'and',
    [TK.SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS]: 'Terms & Conditions',
    [TK.SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER]: ' for details.',
    [TK.SUBSCRIPTION_EMAIL_FORM_LABEL]: 'Email Address*',
    [TK.SUBSCRIPTION_SHOP_NOW]: 'Shop Now',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Unlock your discount',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT]:
      'Join our email list for ',
    [TK.NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT]:
      'Join our email or SMS list for ',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT]: 'instant savings',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT]: 'instant',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT]: 'savings',
    [TK.NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT]:
      'on your next pickup order',
    [TK.SUBSCRIPTION_FIRST_POPUP_TITLE]: 'Something You’ll Love!',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_VAL]: '15',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_PER]: '%',
    [TK.VIEW_DETAILS]: 'View Details',
    [TK.HIDE_DETAILS]: 'Hide Details',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT]: 'off',
    [TK.SUBSCRIPTION_FIRST_POPUP_OFFER_STAR]: '*',
    [TK.SUBSCRIPTION_FIRST_POPUP_DESCRIPTION]:
      'your next pickup order when you sign up to receive our emails today!',
    [TK.SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE]: 'sweet!',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST]:
      'Thanks for signing up! Check your email inbox',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST]:
      'for 15% off your next pickup purchase.',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE]:
      'Connect with us on social media:',
    [TK.SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT]:
      'Order a freshly-crafted gift for any occasion!',
    [TK.SUBSCRIPTION_IMAGE_TITLE]:
      'Delicious Fruit design® Dipped Strawberries',
    [TK.SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL]: 'Sign Up',
    [TK.ERROR_PHONE]: 'Invalid phone number',
    [TK.ERROR_EMAIL]: 'Invalid email',
    [TK.REQUIRED_EMAIL]: 'Email is required',
    [TK.ERROR_AGREE]: 'You must agree to the policy',
    [TK.LOG_IN]: 'Log In',
    [TK.SUBSCRIPTION_TERMS_OF_USE]: 'See Terms of Use',
    [TK.SUBSCRIPTION_FORM_PHONE_LABEL]: 'Phone Number',
    [TK.SUBSCRIPTION_FORM_PHONE_ERROR]: 'Enter a valid phone #',
    [TK.SUBSCRIPTION_FORM_POLICY_LABEL]: 'I agree to the',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP]:
      'I agree to receive periodic automated text messages from or on behalf of Edible Arrangements, LLC edible.com promotions, special offers, and discounts at the phone number provided above. I understand that my consent is not required and is not a condition of any purchase. Standard text message and data rates apply. Not all carriers covered. I understand that checking this box constitutes my signature. To opt-out, reply to an EA text with STOP to stop, and HELP for Help.',
    [TK.SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL]: 'Communications Policy.',
    [TK.SUBSCRIPTION_FORM_POLICY_ERROR]: 'Please check the policy',
    [TK.SUBSCRIPTION_SIGNUP_POLICY_ERROR]: 'Please agree to continue',
    [TK.SOCIAL_LINKS_TITLE]: 'Follow Us',
    [TK.PAYMENT_OPTIONS_TITLE]: 'Secure Payments By',
    [TK.NEAREST_STORE_TITLE]: 'Your Nearest Edible Store',
    [TK.NEAREST_STORE_TODAY_LABEL]: 'Today',
    [TK.NEAREST_STORE_DIRECTIONS_LABEL]: 'Get Directions',
    [TK.TERMS_OF_USE_LABEL]: 'Terms of Use',
    [TK.PRIVACY_POLICY_LABEL]: 'Privacy Policy',
    [TK.CALIFORNIA_PRIVACY_RIGHTS_LABEL]: 'California Privacy Rights',
    [TK.CORPORATE_PROMOTION_LABEL]: 'Promotion Terms and Conditions',
    [TK.COOKIE_SETTINGS_LABEL]: 'Your Privacy Choices',
    [TK.CORPORATE_COPYRIGHT_LABEL]:
      'Edible Arrangements, LLC. All rights reserved.',
    [TK.DESKTOP_SEARCH_PLACEHOLDER]: 'Looking for something special?',
    [TK.SEARCH_HELPER_TEXT]: 'Search for products, stores and information...',
    [TK.SEARCH_SUGGESTED_RESULTS]: 'Suggested Results:',
    [TK.SEARCH_NO_SUGGESTIONS]: 'No suggestions found',
    [TK.SEARCHING_TEXT]: 'Searching for suggestions',
    [TK.ALL_RESULTS]: 'All Results',
    [TK.CHOOSE_VARIANT]: 'Choose an Option:',
    [TK.SINGLE_VARIANT]: 'Single Size:',
    [TK.ENTER_ONE_PHONE_NUMBER]: 'Enter a valid phone number',
    [TK.ENTER_ONE_VALID_PHONE_NUMBER]:
      'Please enter a valid home/work/cell phone number.',
    [TK.CANT_BEGIN_WITH_O_OR_1]:
      "Number can't begin with '0' or '1'. Please provide a 10-digit phone number, starting with the area code.",
    [TK.HOME_PAGE_VIEW_ALL]: 'View All',
    [TK.HOME_PAGE_CAROUSEL_FORWARD_CONTROL]:
      'Move carousel forward to revel more products',
    [TK.HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL]:
      'Move carousel backwards to previous products',
    [TK.NEAREST_STORE_WEEKLY_HOURS]: 'Display nearest store weekly hours',
    [TK.NEAREST_STORE_PHONE_CONTACT]:
      'Call the Edible Store closest to your location',
    [TK.NEAREST_STORE_DIRECTIONS]:
      'Get directions to the closest Edible Store to your location',
    [TK.HOME_PAGE_H1_HEADING]:
      'Edible Arrangements Fruit Bouquets, Chocolate Covered Strawberries & Gift Delivery',
    [TK.FRANCHISE_BANNER_TITLE]: 'the sweetest deal in franchising!',
    [TK.FRANCHISE_BANNER_BODY]:
      'Open your own Edible® storefront and join our passionate, global community of franchise owners',
    [TK.FRANCHISE_BANNER_BUTTON]: 'Learn More',
    [TK.POPULAR_CATEGORIES]: 'Popular Categories',
  },
};
