import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import { StoreData } from 'components/LocalSeoPage/State/types';
import { StructuredDataValues } from 'utils/seo/google/enums';
import { getData } from 'components/LocalSeoPage/State/feature/selectors';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

type Location = { host: string; protocol: string; pathname: string };

const generate = (
  { host, protocol, pathname }: Location,
  storesByCity: StoreData[],
  city: string,
  state: string,
): string => {
  return JSON.stringify(
    pickBy(
      {
        '@context': StructuredDataValues.SCHEMA,
        '@type': 'FoodEstablishment',
        name: 'Edible Arrangements',
        url: `${protocol}//${host}${pathname}`,
        logo: 'https://resources.ediblearrangements.com/resources/en-us/i/revamp_p/edible_arrangement_logo.png',
        description: `${i18next.t(TK.CITY_PAGE_DESCRIPTION, {
          replace: {
            State: state,
            City: city,
          },
        })}`,
        department: [
          storesByCity.map((store) => {
            return {
              '@type': 'FoodEstablishment',
              name: `${store.ModifiedName}`,
              url: `${protocol}//${host}/stores/${store.PageFriendlyURL}`,
              telephone: store.PhoneNumber,
              address: {
                '@type': 'PostalAddress',
                streetAddress: store.MapAddress.split(',')[0],
                addressLocality: city,
                addressRegion:
                  store.MapAddress.split(',').length > 1
                    ? store.MapAddress.split(',')[2].substr(0, 3).trim()
                    : store.MapAddress,
                postalCode:
                  store.MapAddress.split(',').length > 1
                    ? store.MapAddress.split(',')[2].substr(3, 6).trim()
                    : '',
                addressCountry: 'USA',
              },
              areaServed: {
                '@type': 'City',
                name:
                  store.MapAddress.split(',').length > 1
                    ? store.MapAddress.split(',')[1].trim()
                    : '',
              },
            };
          }),
        ],
      },
      isNotUndefined,
    ),
    null,
    2,
  );
};

export function useStructuredCityData(): string {
  const { state, city } = useParams<{ state: string; city: string }>();
  const storeData = useSelector(getData);

  const storesByCity = storeData.filter((store) =>
    store.Address.includes(city || ''),
  );

  const [structure, setStructure] = useState<string>(
    generate(window.location, storesByCity, city || '', state || ''),
  );
  useEffect(() => {
    setStructure(
      generate(window.location, storesByCity, city || '', state || ''),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return structure;
}
