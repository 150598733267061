import { FLEX_FREE } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates/translations/suggested-dates.locale.constants';
import { Typography } from '@mui/material';
import { addCurrency } from 'utils/add-currency';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  fee: number;
  isDelivery: boolean;
  isSameDay: boolean;
  isOnlyShipmentAvailable: boolean;
};

export const FlexFee: FC<Props> = ({
  fee,
  isDelivery,
  isSameDay,
  isOnlyShipmentAvailable,
}) => {
  const deliveryFee =
    fee !== 0 && (isSameDay ? `+${addCurrency(fee)}*` : `+${addCurrency(fee)}`);

  return (
    <Typography
      variant="subtitle2"
      color={fee === 0 && isDelivery ? 'primary' : undefined}
    >
      {!isOnlyShipmentAvailable && (deliveryFee || i18next.t(FLEX_FREE))}
    </Typography>
  );
};
