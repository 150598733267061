import * as TK from 'components/LocalSeoPage/translations/locale.constants';
import { StoreData } from 'components/LocalSeoPage/State/types';
import { StructuredDataValues } from 'utils/seo/google/enums';
import { getData } from 'components/LocalSeoPage/State/feature/selectors';
import { isNotUndefined } from 'utils/is-not-undefined';
import { pickBy } from 'utils/pick-by';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

type Location = { host: string; protocol: string; pathname: string };

const getAddressPiece = (
  address: string,
  addressPiece: 'street' | 'city' | 'state' | 'zip',
): string => {
  if (addressPiece === 'street') return address.split(',')[0];
  if (addressPiece === 'city')
    return address.split('<br>')[1].trim().split(',')[0];
  if (addressPiece === 'state') {
    const splitUpAddress = address.split('<br>')[1].split(' ');
    const stateAbbreviation = splitUpAddress[splitUpAddress.length - 2];
    return stateAbbreviation;
  }
  if (addressPiece === 'zip') {
    const splitUpAddress = address.split('<br>')[1].split(' ');
    const zip = splitUpAddress[splitUpAddress.length - 1];
    return zip;
  }
  return 'No Address Piece Found';
};

const generate = (
  { host, protocol, pathname }: Location,
  storeData: StoreData[],
  state: string,
): string => {
  return JSON.stringify(
    pickBy(
      {
        '@context': StructuredDataValues.SCHEMA,
        '@type': 'FoodEstablishment',
        name: 'Edible Arrangements',
        url: `${protocol}//${host}${pathname}`,
        logo: 'https://resources.ediblearrangements.com/resources/en-us/i/revamp_p/edible_arrangement_logo.png',
        description: `${i18next.t(TK.STATE_SEO_STATE_PAGE_DESCRIPTION, {
          replace: {
            State: state,
          },
        })}`,
        department: [
          storeData.map((store) => {
            return {
              '@type': 'FoodEstablishment',
              name: `${store.FormalName}`,
              url: `${protocol}//${host}/stores/${store.PageFriendlyURL}`,
              telephone: store.PhoneNumber,
              address: {
                '@type': 'PostalAddress',
                streetAddress: `${getAddressPiece(store.MapAddress, 'street')}`,
                addressLocality: `${getAddressPiece(store.Address, 'city')}`,
                addressRegion: `${getAddressPiece(store.Address, 'state')}`,
                postalCode: `${getAddressPiece(store.Address, 'zip')}`,
                addressCountry: 'USA',
              },
              areaServed: `US-${getAddressPiece(store.Address, 'state')}`,
            };
          }),
        ],
      },
      isNotUndefined,
    ),
    null,
    2,
  );
};

export function useStructuredStateData(): string {
  const storeData = useSelector(getData);
  const { state } = useParams<{ state: string }>();
  const [structure, setStructure] = useState<string>(
    generate(window.location, storeData, state || ''),
  );

  useEffect(() => {
    if (state) {
      setStructure(generate(window.location, storeData, state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return structure;
}
