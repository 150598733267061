import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'components/PDP/Details/components/OwnBox/translations/own-box.locale.constants';
import * as styles from 'components/PDP/Details/components/OwnBox/styles';
import { ARRANGEMENT_TITLE_ELEMENT } from 'components/PDP/Details/constants';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { BottomContinuePanel } from 'components/PDP/Details/containers/BottomContinuePanel/BottomContinuePanel';
import {
  Box,
  Grid,
  Step,
  StepContent,
  Stepper,
  Typography,
} from '@mui/material';
import { ContinueButton } from 'components/PDP/Details/components/ContinueButton/ContinueButton';
import { ContinueButtonSkeleton } from 'components/PDP/Details/components/ContinueButton/ContinueButtonSkeleton';
import { DessertBoardCarouselControl } from 'components/PDP/Details/components/OwnBox/DessertBoardCarouselControl';
import { DessertBoardPreviewImage } from 'components/PDP/Details/components/OwnBox/DessertBoardPreviewImage';
import { DessertBoardType } from 'api/products/types';
import { HideUpMd, useIsUpMd } from 'utils/hidden/hide-up-md';
import { HideUpSm } from 'utils/hidden/hide-up-sm';
import { KeyConst } from 'components/Paypal/enums/key.enum';
import { LimitAlert } from 'components/PDP/Details/components/OwnBox/LimitAlert/LimitAlert';
import { LockAlert } from 'components/Session/Alert/LockAlert';
import {
  OwnBoxAddon,
  OwnBoxOptions,
} from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxAddonItem } from 'components/PDP/Details/components/OwnBox/OwnBoxAddonItem/OwnBoxAddonItem';
import { OwnBoxDessertBoardItem } from 'components/PDP/Details/components/OwnBox/OwnBoxDessertBoardItem/OwnBoxDessertBoardItem';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import {
  PayLaterMessage,
  calculatePayLaterAmount,
} from 'components/Paypal/PayLaterMessage';
import { PaypalSkeleton } from 'components/Paypal/PaypalSkeleton';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductOptionsSkeleton } from 'components/PDP/Details/components/ProductOptions/components/ProductOptionsSkeleton';
import { Quantity } from 'components/PDP/Details/components/ProductOptions/components/Quantity/Quantity';
import { QuantityButtonSkeleton } from 'components/PDP/Details/components/ProductOptions/components/Quantity/QuantityButtonSkeleton';
import { ROOT_CONTAINER_ID } from 'utils/root';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { StepSummary } from 'components/PDP/Details/components/OwnBox/StepSummary/StepSummary';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { ViewAvailableProductsAlert } from 'components/PDP/Details/components/ProductOptions/components/ViewAvailableProductsAlert/ViewAvailableProductsAlert';
import { analytics } from 'service/adobe-analytics/analytics';
import { animateScroll, scroller } from 'react-scroll';
import {
  bakedStep,
  dippedBerriesStep,
  dippedFruitStep,
  notSelectedStep,
  orderTypeStep,
} from 'components/PDP/Details/components/PasControl/contants/steps';
import {
  checkIsBlocked,
  selectOwnBox,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { dessertBoardSettings } from 'components/PDP/Details/components/OwnBox/settings';
import { getOwnBoxBoardOptions } from 'components/PDP/Details/components/OwnBox/utils/get-own-box-options';
import { isEmpty } from 'utils/is-empty';
import { isSelected } from 'components/PDP/Details/components/OwnBox/utils/is-selected';
import {
  payPalEnabled,
  payPalVersion,
} from 'redux/settings/settings.selectors';
import {
  selectIsAreaError,
  selectIsAreaPending,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.selectors';
import { selectIsLoading } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import { toggleAddon } from 'components/PDP/Details/components/OwnBox/utils/toggle-addon';
import { updateDessertBoardQuantity } from 'components/PDP/Details/components/OwnBox/utils/update-dessert-board-quantity';
import { useInView } from 'react-intersection-observer';
import { useOnlyXs } from 'utils/hidden/use-only-xs';
import { useSelector } from 'react-redux';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import React, { FC, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

const CAROUSEL_MIN_ITEMS = 8;

type Props = {
  setOwnBoxAddons: (addons: UpsellItem[]) => void;
  unavailable?: boolean;
  product: Product | null;
  selectedOwnBoxAddons: UpsellItem[];
  quantity: number;
  setQuantity: (d: number) => void;
  onContinue: (event?: React.MouseEvent) => void;
  focusedAddonId: number;
  onAnimationEnd: () => void;
  productUnavailable?: boolean;
  hidePasAlert: () => void;
  setClickOwnBoxAddon: (addon: OwnBoxAddon[]) => void;
  clickOwnBoxAddon: OwnBoxAddon[];
  onChangeStep: (step: AvailabilityStep) => void;
};

export const OwnBoxDessertBoard: FC<Props> = ({
  setOwnBoxAddons,
  unavailable,
  quantity,
  setQuantity,
  product,
  onContinue,
  selectedOwnBoxAddons = [],
  focusedAddonId,
  onAnimationEnd,
  productUnavailable,
  hidePasAlert,
  setClickOwnBoxAddon,
  clickOwnBoxAddon,
  onChangeStep,
}) => {
  const isPayPalEnabled = useSelector(payPalEnabled);
  const isPayPalVersion = useSelector(payPalVersion);

  const ownBox = useSelector(selectOwnBox);
  const isAreaError = useSelector(selectIsAreaError);
  const isAreaLoading = useSelector(selectIsAreaPending);
  const ownBoxPending = useSelector(checkIsBlocked);
  const isAvailabilityLoading = useSelector(selectIsLoading);

  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [addons] = useState<OwnBoxAddon[]>([...ownBox.addons]);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);

  const isLoading = ownBoxPending || isAvailabilityLoading;
  const isDisabled = isLoading || isAreaLoading;
  /* TODO: move outside of the product options after old PAS is removed */
  const isContinueBlocked = isDisabled || isAreaError;
  const { ref: continueButtonRef, inView: isMainContinueButtonInView } =
    useInView();

  const [backedPreviewImage, setBackedPreviewImage] = useState<string[]>([]);
  const [dippedBerriesPreviewImage, setdippedBerriesPreviewImage] = useState<
    string[]
  >([]);
  const [dippedFruitsPreviewImage, setdippedFruitsPreviewImage] = useState<
    string[]
  >([]);

  const bakedImgArr: string[] = [];
  const dippedBerriesImgArr: string[] = [];
  const dippedFruitsImgArr: string[] = [];

  const { ref: ownBoxContainerRef, inView: isOwnBoxContainerInView } =
    useInView({
      triggerOnce: false,
      threshold: 1,
      trackVisibility: true,
      delay: 1000,
    });

  const {
    ref: ownBoxMobileGrid,
    inView: isOwnBoxMobileGridInView,
    entry: ownBoxMobileGridEntry,
  } = useInView({
    triggerOnce: false,
    threshold: 1,
    trackVisibility: true,
    delay: 500,
  });

  const sliderRef = useRef<Slider | null>(null);

  const isMobile = useOnlyXs();
  const isTabletDesktop = useIsUpMd();
  const addOnIds = [...new Set(addons.map((addon) => addon.addonId))];
  const boxOptions = getOwnBoxBoardOptions(OwnBoxTypes.DESSERT_BOARD);

  const showContent = !unavailable && !isLoading;

  const showPayLaterMessage =
    showContent &&
    isPayPalEnabled &&
    isPayPalVersion === KeyConst.PaypalVersion;

  const ItemBoardTypeName = {
    [DessertBoardType.BakedGoods]: 'baked goods',
    [DessertBoardType.DippedBerries]: 'dipped berries',
    [DessertBoardType.DippedFruits]: 'dipped fruit',
  };

  const scrollToOwnBoxVertical = () => {
    if (!isOwnBoxContainerInView && isTabletDesktop) {
      scroller.scrollTo(ARRANGEMENT_TITLE_ELEMENT, {
        containerId: ROOT_CONTAINER_ID,
        smooth: 'true',
      });
    } else if (!isOwnBoxMobileGridInView && !isTabletDesktop) {
      const ownBoxScrollOffsetHeight =
        (ownBoxMobileGridEntry?.boundingClientRect.bottom || 0) +
        (ownBoxMobileGridEntry?.boundingClientRect.height || 0) -
        (ownBoxMobileGridEntry?.rootBounds?.height || 0);
      animateScroll.scrollTo(ownBoxScrollOffsetHeight, {
        containerId: ROOT_CONTAINER_ID,
      });
    }
  };

  const scrollToOwnBoxItemHorizontal = (focusedItemId: number) => {
    const index = addons.findIndex((item) => item.id === focusedItemId);
    if (index !== -1) {
      sliderRef.current?.slickGoTo(Math.floor(index / 2), false);
    }
  };

  useEffect(() => {
    if (focusedAddonId && !isOwnBoxContainerInView) {
      scrollToOwnBoxVertical();
    }
    if (isOwnBoxContainerInView && !isMobile) {
      scrollToOwnBoxItemHorizontal(focusedAddonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons, isTabletDesktop, focusedAddonId, isOwnBoxContainerInView]);

  useEffect(() => {
    if (isAlertShown && isEmpty(selectedOwnBoxAddons)) {
      setIsAlertShown(false);
    }
  }, [isAlertShown, selectedOwnBoxAddons]);

  const [activeStepperStep, setActiveStepperStep] = useState<number>(bakedStep);
  const [lastStepperStep, setLastStepperStep] = useState<number>(bakedStep);

  useEffect(() => {
    if (clickOwnBoxAddon.length === 0) {
      setActiveStepperStep(bakedStep);
    }
  }, [clickOwnBoxAddon]);

  const handleClickOwnBoxAddon = (
    ownBoxAddon: OwnBoxAddon,
    options: OwnBoxOptions,
    index: number,
    event?: React.MouseEvent,
  ): void => {
    event?.stopPropagation();
    hidePasAlert();
    if (options.type === DessertBoardType.BakedGoods) {
      setActiveStepperStep(bakedStep);
    }
    if (options.type === DessertBoardType.DippedBerries) {
      setActiveStepperStep(dippedBerriesStep);
    }
    if (options.type === DessertBoardType.DippedFruits) {
      setActiveStepperStep(dippedFruitStep);
    }

    const isOwnBoxAddonSelected = isSelected(selectedOwnBoxAddons, ownBoxAddon);
    let categoriesCount = 0;
    if (
      !clickOwnBoxAddon.some((addon) => addon.addonId === ownBoxAddon.addonId)
    ) {
      // eslint-disable-next-line no-param-reassign
      ownBoxAddon.count = 1;
      clickOwnBoxAddon.push(ownBoxAddon);
    } else {
      const arrIndex = clickOwnBoxAddon.findIndex(
        (addon) => addon.addonId === ownBoxAddon.addonId,
      );
      categoriesCount = clickOwnBoxAddon[arrIndex].count || 0;

      const isSelectionLimit = categoriesCount === options.optionsLimit;
      if (isSelectionLimit && !isOwnBoxAddonSelected) {
        setIsAlertShown(true);
        return;
      }

      if (categoriesCount < options.optionsLimit)
        // eslint-disable-next-line no-param-reassign
        clickOwnBoxAddon[arrIndex].count = categoriesCount + 1;
      if (isOwnBoxAddonSelected)
        // eslint-disable-next-line no-param-reassign
        clickOwnBoxAddon[arrIndex].count = categoriesCount - 1;
    }
    setClickOwnBoxAddon(clickOwnBoxAddon);

    if (selectedOwnBoxAddons.length === 11 && isOwnBoxAddonSelected) {
      setIsAlertShown(false);
    }

    setOwnBoxAddons(
      updateDessertBoardQuantity(
        toggleAddon(selectedOwnBoxAddons, ownBoxAddon),
        options,
        ownBoxAddon,
        clickOwnBoxAddon,
      ),
    );

    const type = ItemBoardTypeName[options.type || 0];
    analytics.cyoSelection(type, ownBoxAddon?.name, index + 1);
  };

  const handleContinue = (event?: React.MouseEvent) => {
    if (isAlertShown) {
      setIsAlertShown(false);
    }
    onContinue(event);
  };

  // eslint-disable-next-line consistent-return
  const ownBoxAddonItems = (addOns: OwnBoxAddon[], option: OwnBoxOptions) => {
    const addOnsToDisplay = addOns || addons;
    if (!boxOptions) {
      return addOnsToDisplay.map((addon, index) => (
        <OwnBoxAddonItem
          key={addon.id}
          onSelect={() => handleClickOwnBoxAddon(addon, option, index)}
          addon={addon}
          selected={isSelected(selectedOwnBoxAddons, addon)}
          focused={focusedAddonId === addon.id}
          onAnimationEnd={onAnimationEnd}
          isContainerInView={isOwnBoxContainerInView}
        />
      ));
    }
    if (boxOptions) {
      return addOnsToDisplay.map((addon, index) => (
        <OwnBoxDessertBoardItem
          key={addon.id}
          onSelect={() => handleClickOwnBoxAddon(addon, option, index)}
          addon={addon}
          selected={isSelected(selectedOwnBoxAddons, addon)}
          focused={focusedAddonId === addon.id}
          onAnimationEnd={onAnimationEnd}
          isContainerInView={isOwnBoxContainerInView}
        />
      ));
    }
  };
  const toggleActiveStepperStep = (step: number) => {
    if (step === activeStepperStep) {
      setActiveStepperStep(notSelectedStep);
      return;
    }
    setActiveStepperStep(step);
  };

  useEffect(() => {
    const backedAddons = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.BakedGoods,
      ),
    );
    backedAddons.map((item) => {
      return bakedImgArr.push(item.option.image);
    });
    const dippedBerriesAdon = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.DippedBerries,
      ),
    );
    dippedBerriesAdon.map((item) => {
      return dippedBerriesImgArr.push(item.option.image);
    });
    const dippedFruitsAdon = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.DippedFruits,
      ),
    );
    dippedFruitsAdon.map((item) => {
      return dippedFruitsImgArr.push(item.option.image);
    });

    setLastStepperStep(1);
    setLastStepperStep(2);
    setLastStepperStep(3);

    setBackedPreviewImage(bakedImgArr);
    setdippedBerriesPreviewImage(dippedBerriesImgArr);
    setdippedFruitsPreviewImage(dippedFruitsImgArr);

    if (
      backedAddons.length === 0 ||
      dippedBerriesAdon.length === 0 ||
      dippedFruitsAdon.length === 0
    ) {
      onChangeStep(AvailabilityStep.DESSERT_OWN_BOX_ADDONS);
    }

    if (backedAddons.length === 3 && activeStepperStep <= bakedStep) {
      setActiveStepperStep(1);
    }
    if (
      dippedBerriesAdon.length === 6 &&
      activeStepperStep === dippedBerriesStep
    ) {
      setActiveStepperStep(2);
    }
    if (
      dippedFruitsAdon.length === 2 &&
      activeStepperStep === dippedFruitStep
    ) {
      setActiveStepperStep(3);
    }
  }, [selectedOwnBoxAddons]);

  return (
    <>
      <LimitAlert
        hideAlert={() => setIsAlertShown(false)}
        isShown={isAlertShown}
      />
      <Box sx={styles.wrapper} ref={ownBoxContainerRef}>
        {showContent && (
          <>
            <ShowUpMd>
              <Box sx={{ mt: 2, mb: 2 }}>
                <LockAlert />
              </Box>
            </ShowUpMd>
          </>
        )}
        {isLoading && isEmpty(addons) ? (
          <ProductOptionsSkeleton />
        ) : (
          <>
            <HideUpSm>
              {addons.length <= CAROUSEL_MIN_ITEMS && (
                <Box>
                  <Grid
                    ref={ownBoxMobileGrid}
                    container
                    spacing={1}
                    sx={styles.grid}
                  >
                    {ownBoxAddonItems(addons, boxOptions[0])}
                  </Grid>
                  <Box sx={{ mx: 2.5, mt: 3 }}>
                    <LockAlert />
                  </Box>
                </Box>
              )}
            </HideUpSm>
            <ShowUpSm>
              {addons.length <= CAROUSEL_MIN_ITEMS && (
                <Grid container spacing={1} sx={styles.grid}>
                  {ownBoxAddonItems(addons, boxOptions[0])}
                </Grid>
              )}
            </ShowUpSm>
            {addons.length >= CAROUSEL_MIN_ITEMS && (
              <Box sx={styles.dessertCarousel}>
                <Stepper orientation="vertical" connector={null}>
                  <Step
                    active={activeStepperStep === bakedStep}
                    completed={clickOwnBoxAddon.some(
                      (addon) =>
                        addon.dessertBoxType === DessertBoardType.BakedGoods &&
                        addon.count === 3,
                    )}
                    disabled={lastStepperStep < bakedStep}
                  >
                    <Box sx={styles.borderStyle} mb={2}>
                      <StepSummary
                        availabilityStep={
                          AvailabilityStep.OWN_DESSERT_BOARD_BAKED
                        }
                        setActiveStep={toggleActiveStepperStep}
                        clickable={lastStepperStep >= orderTypeStep}
                        preview={
                          <DessertBoardPreviewImage
                            imgPreview={
                              activeStepperStep !== bakedStep
                                ? backedPreviewImage
                                : []
                            }
                          />
                        }
                      >
                        <Box
                          sx={styles.description}
                          ref={descriptionRef}
                          width="235px"
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="500"
                            sx={{ mt: 1, mb: 1, width: 'max-content' }}
                          >
                            {boxOptions[0].title}
                          </Typography>
                        </Box>
                      </StepSummary>
                      <StepContent sx={styles.contentStyle}>
                        <Typography variant="body2" sx={styles.titleStyle}>
                          {i18next.t(TK.OWN_BOX_SUBTITLE)}
                        </Typography>
                        <Box mt={3}>
                          <Slider
                            {...dessertBoardSettings}
                            rows={2}
                            ref={sliderRef}
                            swipe={!focusedAddonId}
                            nextArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-next-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowForwardIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                            prevArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-prev-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowBackIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                          >
                            {ownBoxAddonItems(
                              addons
                                .filter(
                                  (addon) => addon.addonId === addOnIds[0],
                                )
                                .map((addon) => ({
                                  ...addon,
                                  dessertBoxType: DessertBoardType.BakedGoods,
                                })),
                              boxOptions[0],
                            )}
                          </Slider>
                        </Box>
                      </StepContent>
                    </Box>
                  </Step>
                  <Step
                    active={activeStepperStep === dippedBerriesStep}
                    completed={clickOwnBoxAddon.some(
                      (addon) =>
                        addon.dessertBoxType ===
                          DessertBoardType.DippedBerries && addon.count === 6,
                    )}
                    disabled={lastStepperStep < dippedBerriesStep}
                  >
                    <Box sx={styles.borderStyle} mb={2}>
                      <StepSummary
                        availabilityStep={
                          AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_BERRIES
                        }
                        setActiveStep={toggleActiveStepperStep}
                        clickable={lastStepperStep >= orderTypeStep}
                        preview={
                          <DessertBoardPreviewImage
                            imgPreview={
                              activeStepperStep !== dippedBerriesStep
                                ? dippedBerriesPreviewImage
                                : []
                            }
                          />
                        }
                      >
                        <Box
                          sx={styles.description}
                          ref={descriptionRef}
                          width="235px"
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="500"
                            sx={{ mt: 1, mb: 1, width: 'max-content' }}
                          >
                            {boxOptions[1].title}
                          </Typography>
                        </Box>
                      </StepSummary>
                      <StepContent sx={styles.contentStyle}>
                        <Typography variant="body2" sx={styles.titleStyle}>
                          {i18next.t(TK.OWN_BOX_SUBTITLE)}
                        </Typography>
                        <Box mt={3}>
                          <Slider
                            {...dessertBoardSettings}
                            rows={2}
                            ref={sliderRef}
                            swipe={!focusedAddonId}
                            nextArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-next-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowForwardIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                            prevArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-prev-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowBackIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                          >
                            {ownBoxAddonItems(
                              addons
                                .filter(
                                  (addon) => addon.addonId === addOnIds[1],
                                )
                                .map((addon) => ({
                                  ...addon,
                                  dessertBoxType:
                                    DessertBoardType.DippedBerries,
                                })),

                              boxOptions[1],
                            )}
                          </Slider>
                        </Box>
                      </StepContent>
                    </Box>
                  </Step>
                  <Step
                    active={activeStepperStep === dippedFruitStep}
                    completed={clickOwnBoxAddon.some(
                      (addon) =>
                        addon.dessertBoxType ===
                          DessertBoardType.DippedFruits && addon.count === 2,
                    )}
                    disabled={lastStepperStep < dippedFruitStep}
                  >
                    <Box sx={styles.borderStyle} mb={2}>
                      <StepSummary
                        availabilityStep={
                          AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_FRUIT
                        }
                        setActiveStep={toggleActiveStepperStep}
                        clickable={lastStepperStep >= orderTypeStep}
                        preview={
                          <DessertBoardPreviewImage
                            imgPreview={
                              activeStepperStep !== dippedFruitStep
                                ? dippedFruitsPreviewImage
                                : []
                            }
                          />
                        }
                      >
                        <Box
                          sx={styles.description}
                          ref={descriptionRef}
                          width="235px"
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="500"
                            sx={{ mt: 1, mb: 1, width: 'max-content' }}
                          >
                            {boxOptions[2].title}
                          </Typography>
                        </Box>
                      </StepSummary>
                      <StepContent sx={styles.contentStyle}>
                        <Typography variant="body2" sx={styles.titleStyle}>
                          {i18next.t(TK.OWN_BOX_SUBTITLE)}
                        </Typography>
                        <Box mt={3}>
                          <Slider
                            {...dessertBoardSettings}
                            rows={2}
                            ref={sliderRef}
                            swipe={!focusedAddonId}
                            nextArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-next-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowForwardIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                            prevArrow={
                              <DessertBoardCarouselControl
                                data-test="pdp-details-own-box-prev-button"
                                disabled={!!focusedAddonId}
                              >
                                <ArrowBackIos fontSize="medium" />
                              </DessertBoardCarouselControl>
                            }
                          >
                            {ownBoxAddonItems(
                              addons
                                .filter(
                                  (addon) => addon.addonId === addOnIds[2],
                                )
                                .map((addon) => ({
                                  ...addon,
                                  dessertBoxType: DessertBoardType.DippedFruits,
                                })),
                              boxOptions[2],
                            )}
                          </Slider>
                        </Box>
                      </StepContent>
                    </Box>
                  </Step>
                </Stepper>
                <HideUpMd>
                  <Box sx={{ mt: 3 }}>
                    <LockAlert />
                  </Box>
                </HideUpMd>
              </Box>
            )}
          </>
        )}
        <>
          {showPayLaterMessage && product ? (
            <Box mt={3}>
              <PayLaterMessage
                amount={calculatePayLaterAmount(product.price, quantity)}
              />
            </Box>
          ) : (
            <PaypalSkeleton />
          )}
          <Box
            mt={3}
            mx={[2.5, 0]}
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
          >
            <Box width={1}>
              {showContent && product ? (
                <Quantity
                  quantity={quantity}
                  setQuantity={setQuantity}
                  disabled={isDisabled}
                  selectedProduct={product}
                />
              ) : (
                <QuantityButtonSkeleton />
              )}
            </Box>
            <Box width={1} ml={[0, 2]}>
              {showContent ? (
                <ContinueButton
                  ref={continueButtonRef}
                  onClick={handleContinue}
                  disabled={isContinueBlocked}
                  dataTest="pdp-details-own-box-continue-button"
                />
              ) : (
                <ContinueButtonSkeleton ref={continueButtonRef} />
              )}
            </Box>
          </Box>
        </>
        {productUnavailable && (
          <Box mt={2}>
            <ViewAvailableProductsAlert />
          </Box>
        )}
        <ShowUpSm>
          <BottomContinuePanel
            selectedVariantName={product?.name ?? ''}
            quantity={quantity}
            onContinue={handleContinue}
            isBlocked={isContinueBlocked}
            reveal={!isMainContinueButtonInView}
          />
        </ShowUpSm>
      </Box>
    </>
  );
};
