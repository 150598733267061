import * as TK from 'components/PDP/Details/components/PasControl/components/StoreSelector/translations/locale.constants';
import { Alert } from '@mui/material';
import { selectError } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export const ErrorAlert: FC = () => {
  const error = useSelector(selectError);

  return (
    <Alert
      data-test="pdp-pas-stores-closed-error"
      severity="error"
      sx={{ mb: [0, 1], mt: [3, 1] }}
    >
      {i18next.t(error ? error?.data.key : TK.ERROR_NO_STORES, {
        replace: error?.data.vars,
      })}
    </Alert>
  );
};
