import * as TK from 'containers/CartContainer/translations/locale.constants';

export const cartCommonLocale = {
  [TK.TITLE]: 'Cart',
  [TK.CONTINUE_SHOPPING]: 'Continue Shopping',
  [TK.START_SHOPPING]: 'Start Shopping',
  [TK.SHOP_NOW]: 'Shop Now',
  [TK.SUGGESTED_PRODUCTS]: 'Are You Forgetting Anything?',
  [TK.SUGGESTED_PRODUCTS_EMPTY]: 'We Think You’ll Like These Popular Gifts',
  [TK.VIEW_ALL_PRODUCTS]: 'View All Products',
  [TK.NEED_HELP]: 'Need Help?',
  [TK.CHANGE_SELECTION]: 'Change Selection',
  [TK.PRICE_EA]: 'ea',
};
