import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.locale.constants';
import { DeliveryAvailability } from 'api/availability-on-date/types/delivery-availability.interface';
import { FreeDeliveryMessage } from 'components/Cart/Details/free-delivery-message';
import { FulfillmentOptionType, OrderType } from 'types/cart.types';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { RadioButton } from 'components/common/Buttons/RadioButton/RadioButton';
import { Title } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/Title';
import { Typography } from '@mui/material';
import { getOrderTypeDataTestValue } from 'components/PDP/Details/components/PasControl/utils/get-order-type-data-test-value';
import { isPickup } from 'utils/is-order-type';
import { orderTypesDescriptionTK } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/order-type-description';
import { shouldShowSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-arrangements';
import { shouldShowSuggestedDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-dates';
import InnerHTML from 'dangerously-set-html-content';
import React, { FC, KeyboardEvent } from 'react';
import i18next from 'i18next';

type Props = {
  type: FulfillmentOptionType;
  availability: OrderTypeAvailability | DeliveryAvailability;
  disabled?: boolean;
  onKeyDown?: (e: KeyboardEvent<HTMLLabelElement>, value: string) => void;
};

const getDescription = (
  type: FulfillmentOptionType,
  availability: OrderTypeAvailability,
) => {
  if (shouldShowSuggestedDates(availability)) {
    return i18next.t(
      isPickup(type)
        ? TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES
        : TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_DATES,
    );
  }

  if (shouldShowSuggestedArrangements(availability)) {
    return i18next.t(
      isPickup(type)
        ? TK.PICKUP_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS
        : TK.DELIVERY_NOT_AVAILABLE_FOR_DATE_SUGGEST_PRODUCTS,
    );
  }

  return availability.message || i18next.t(orderTypesDescriptionTK[type]);
};

export const DeliveryOption: FC<Props> = ({
  type,
  availability,
  disabled,
  onKeyDown,
}) => {
  let description = getDescription(type, availability);
  const pickupFree =
    type === OrderType.Pickup ? `<b> ${i18next.t(TK.FREE)}</b>` : '';
  description += pickupFree;
  const isAnyDeliveryAndNextDay = sessionStorage.getItem(
    'isAnyDeliveryAndNextDay',
  );
  const CartMerchandiseTotal = Number(
    sessionStorage.getItem('CartMerchandiseTotal'),
  );

  const isUnlockFree =
    CartMerchandiseTotal > 0 && isAnyDeliveryAndNextDay === 'true';

  return (
    <RadioButton
      key={type}
      value={type}
      disabled={disabled}
      labelPosition="start"
      sx={{
        mb: 1,
        '&:focus': { outline: 'none' },
      }}
      dataTest={getOrderTypeDataTestValue(type, 'order-type')}
      onKeyDown={onKeyDown}
    >
      <Title availability={availability} type={type} />
      {description && (
        <>
          <Typography component="p" variant="body3" mt={0.5}>
            <InnerHTML html={description} />
          </Typography>
          {type === OrderType.Delivery && (
            <FreeDeliveryMessage isUnlockPdpMessage={isUnlockFree} />
          )}
        </>
      )}
    </RadioButton>
  );
};
