import { Box } from '@mui/material';
import { Faq } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/FAQ';
import { ProductAccordion } from 'components/PDP/Details/components/ProductAccordion/ProductAccordion';
import {
  checkIsBlocked,
  selectArrangement,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

type Props = {
  reviewsOpen: boolean;
  setReviewsOpen: (open: boolean) => void;
};

export const ArrangementPanel: FC<Props> = ({
  reviewsOpen,
  setReviewsOpen,
}) => {
  const arrangement = useSelector(selectArrangement);
  const arrangementPending = useSelector(checkIsBlocked);

  return (
    <>
      <ProductAccordion
        loading={arrangementPending}
        arrangement={arrangement}
        reviewsOpen={reviewsOpen}
        setReviewsOpen={setReviewsOpen}
      />
      <Box mt={6}>
        <Faq />
      </Box>
    </>
  );
};
