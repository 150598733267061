import { AddSessionStoreRanking } from 'utils/session-storage';
import {
  AddressType,
  FulfillmentOptionType,
  LegacyAddToCartParams,
  OrderType,
  UpdatePickUpTimeParams,
} from 'types/cart.types';
import { AddressValidation } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/AddressValidation';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { Back } from 'components/PDP/GoBack/Back';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import { Box } from '@mui/material';
import { CallCenter } from 'components/PLP/CallCenter';
import { Contact } from 'api/contacts.api.service';
import { Curbside } from 'enums/curbside';
import { Details } from 'components/PDP/Details/Details';
import { Gallery } from 'lib/curalate/components/Gallery';
import { HeadMeta } from 'components/PDP/Arrangement/HeadMeta';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { MainAlert } from 'components/common/MainAlert';
import { MarginWrapper } from 'components/common/MarginWrapper/styles';
import { LazyOrder as Order } from 'components/PDP/Order/LazyOrder';
import { OrderTypeAvailability } from 'api/availability-on-date/types/order-type-availability.interface';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { Recipient } from 'redux/cart/cart.types';
import { Revalidation } from 'containers/PDPContainer/types/revalidation.types';
import { Step, isDetails } from 'containers/PDPContainer/enums/step.enum';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { UpsellType } from 'enums/upsell-types.enum';
import { LazyUpsells as Upsells } from 'components/PDP/Upsells/LazyUpsells';
import { addToLegacyCart, updateLegacyCart } from 'redux/cart/cart.actions';
import { addToLocalStorage } from 'utils/local-storage';
import { appInsights } from 'utils/telemetry/app-insights';
import { batch, useDispatch, useSelector } from 'react-redux';
import { cordialAnalytics } from 'service/cordial/cordial';
import { extractUpsells } from 'containers/PDPContainer/utils/extract-upsells';
import { fetchRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/actions';
import { fetchStores } from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.actions';
import { fetchSuggestedAddresses } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/action';
import { format } from 'date-fns';
import { formatToLegacyDateString } from 'utils/date';
import { getArea } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.actions';
import { getAvailabilityOnDate } from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/actions';
import { getDefaultFlexCartParam } from 'containers/PDPContainer/utils/get-default-flex-cart-param';
import { getInvalidArea } from 'containers/PDPContainer/utils/get-invalid-area';
import { getIsAddToCartBlocked } from 'containers/PDPContainer/feature/selectors';
import {
  getIsRecipientExist,
  selectRecipientId,
} from 'components/Session/feature/selectors';
import {
  getOwnBoxAddons,
  removeOwnBoxAddons,
} from 'containers/PDPContainer/utils/filter-upsells';
import {
  getResult,
  getSuggestedAddresses,
} from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/selectors';
import { getSearchParams } from 'utils/getSearchParams';
import { getSuggestedCity } from 'containers/PDPContainer/feature/pdp-container.selectors';
import {
  isDelivery,
  isFulfillType,
  isPickup,
  isShipment,
} from 'utils/is-order-type';
import { isEmpty, isNotEmpty } from 'utils/array/size';
import { isFulfilled, isStateFulfilled } from 'utils/status.comparer';
import { isNone } from 'containers/PDPContainer/utils/is-revalidation-step';
import { isNotNull, isNull } from 'utils/null.utils';
import { isNotUndefined } from 'utils/is-not-undefined';
import { isNotValid, isValid } from 'api/fulfillment/utils';
import { isPasFilled } from 'containers/PDPContainer/feature/pas/utils/is-pas-filled';
import { mapContactToRecipient } from 'containers/PDPContainer/pdp-container.utils';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { pickBy } from 'utils/pick-by';
import { populateRecipientData } from 'utils/populate-recipient-data';
import { reset as resetAddressValidation } from 'components/PDP/Order/RecipientForm/ShipmentDeliveryForm/AddressValidation/feature/slice';
import {
  resetAvailabilitySession,
  updateAvailabilitySession,
} from 'redux/session/availability/availability.action';
import { resetErrors as resetCartErrors } from 'redux/cart/cart.slice';
import { resetState as resetOnDate } from 'redux/PDP/availability/on-date/availability-on-date.slice';
import { resetSelectedContacts } from 'containers/AddressBook/feature/slice';
import { retrieveRecipientAvailability } from 'components/Session/Alert/feature/actions';
import { root } from 'utils/root';
import { selectAreaState } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.selectors';
import {
  selectArrangement,
  selectIsOwnBox,
  selectOwnBox,
  selectProductsIds,
} from 'redux/PDP/arrangement/arrangement.selectors';
import {
  selectDateAvailabilityData,
  selectIsDateAvailabilityFulfilled,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import {
  selectIsFulfilled as selectIsStoresFulfilled,
  selectStores,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { selectRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import {
  selectSessionArea,
  selectSessionDate,
  selectSessionSelectedDate,
  selectSessionStore,
} from 'redux/session/availability/availability.selectors';
import { selectStoreRanking } from 'components/StoreDetails/feature/selectors';
import { setDYContext } from 'service/dynamic-yield/dy-context.hook';
import { useAAShipmentRecipientFormEvent } from 'containers/PDPContainer/hooks/analytics/use-aa-shipment-recipient-form-event';
import { useAAStoreSelectedEvent } from 'containers/PDPContainer/hooks/analytics/use-aa-store-selected-event';
import { useArrangementReviews } from 'components/PDP/Details/components/Reviews/use-arrangement-reviews';
import { useCartPrintibleFiller } from 'containers/PDPContainer/hooks/use-cart-printible-filler';
import { useClearArrangementOnUnmount } from 'hooks/use-clear-arrangement-on-unmount';
import { useGTagProductAddedToCart } from 'containers/PDPContainer/hooks/analytics/use-gtag-product-added-to-cart';
import { useGetCartRecipientId } from 'containers/PDPContainer/hooks/use-get-cart-recipient-id';
import { useIsC8Enabled } from 'lib/bazaarvoice/hooks/use-is-bv-and-c8-enabled';
import { useIsSaturdayNonResidentialShipment } from 'containers/PDPContainer/hooks/use-is-saturday-non-residential-shipment';
import { usePageAnalytics } from 'containers/PDPContainer/hooks/analytics/use-page-analytics';
import { usePageArrangement } from 'containers/PDPContainer/hooks/use-page-arrangement';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useProductAddedToCartRedirect } from 'containers/PDPContainer/hooks/use-product-added-to-cart-redirect';
import { useRefererAlias } from 'components/PDP/GoBack/use-referer-alias';
import { useRelatedCategoryName } from 'containers/PDPContainer/hooks/analytics/use-track-arrangement/use-related-category-name';
import { useResetPrintibleOnAreaChange } from 'hooks/use-reset-printible-area-change';
import { useScrollToTopOnMount } from 'hooks/scroll/use-scroll-to-top-on-mount';
import { useShouldShowTestStore } from 'components/PDP/Details/components/PasControl/components/StoreSelector/hooks/use-should-show-test-store';
import { useSyncRecipientCity } from 'containers/PDPContainer/hooks/use-sync-recipient-city';
import { useSyncSessionArea } from 'containers/PDPContainer/hooks/use-sync-session-area';
import { useUpdatedCartRedirect } from 'containers/PDPContainer/hooks/use-updated-cart-redirect';
import { zipAvailability } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.slice';
import React, { FC, RefObject, useEffect, useState } from 'react';

export const NULL_RECIPIENT: Recipient = {
  addressType: AddressType.NotSpecified,
  address1: '',
  address2: '',
  company: '',
  cardMessage: '',
  cellPhone: '',
  phone: '',
  instructions: '',
  workPhone: '',
  cityMlId: 0,
  firstName: '',
  lastName: '',
  occasionId: 0,
  pickupTime: '',
  recipientEmail: '',
  fulfillmentDate: '',
  curbside: Curbside.NO,
  carColor: '',
  carModel: '',
  upsellItems: [],
};

interface Props {
  bottomContainer: RefObject<HTMLDivElement>;
}

const PDPContainer: FC<Props> = ({ bottomContainer }) => {
  useScrollToTopOnMount();
  usePageAnalytics();
  usePageArrangement();
  useRefererAlias();
  useArrangementReviews();
  useSyncSessionArea();
  useResetPrintibleOnAreaChange();
  useClearArrangementOnUnmount();
  const dispatch = useDispatch();
  const isAddToCartBlocked = useSelector(getIsAddToCartBlocked);
  const dateAvailability = useSelector(selectDateAvailabilityData);
  const isDateAvailabilityFulfilled = useSelector(
    selectIsDateAvailabilityFulfilled,
  );

  const recipients = useSelector(selectRecipients);

  const arrangement = useSelector(selectArrangement);
  const availabilityCheckState = useSelector(({ availabilityCheck: a }) => a);
  const [firstSuggestedAddress] = useSelector(getSuggestedAddresses);
  const suggestedCity = useSelector(getSuggestedCity);
  const isRecipientExist = useSelector(getIsRecipientExist);
  const selectedContact = useSelector(
    (state) => state.addressBook.data.selectedContact,
  );
  const [recipient, setRecipient] = useState<Recipient>(NULL_RECIPIENT);
  const [revalidation, setRevalidation] = useState<Revalidation>(
    Revalidation.None,
  );
  const [isPasUpdated, setIsPasUpdated] = useState<boolean>(false);
  const [product, setProductState] = useState<Product | null>(null);
  const setProduct: React.Dispatch<React.SetStateAction<Product | null>> = (
    value,
  ) => {
    if (typeof value === 'object' && value?.sku)
      setDYContext('PRODUCT', [value.sku]);

    setProductState(value);
  };
  const [clickOwnBoxAddon, setClickOwnBoxAddon] = useState<OwnBoxAddon[]>([]);
  const [step, setStep] = useState<Step>(Step.DETAILS);
  const [quantity, setQuantity] = useState<number>(1);
  const areaState = useSelector(selectAreaState);
  const sessionArea = useSelector(selectSessionArea);
  const sessionStore = useSelector(selectSessionStore);
  const sessionDate = useSelector(selectSessionSelectedDate);
  const selectedSessionDate = useSelector(selectSessionDate);
  const optionsIds = useSelector(selectProductsIds);
  const availabilitySession = useSelector(
    ({ availabilitySession: s }) => s.data,
  );
  const recipientId = useSelector(selectRecipientId);
  const isOwnBox = useSelector(selectIsOwnBox);
  const user = useSelector(({ userSession: { data } }) => data);
  const cartRecipientId = useGetCartRecipientId();
  const fillCartWithPrintible = useCartPrintibleFiller();
  const isC8Enabled = useIsC8Enabled();
  const ownBox = useSelector(selectOwnBox);
  const isCookie12 =
    ownBox.type === OwnBoxTypes.COOKIE_BOX && product?.name.includes('12');

  const pasData = usePasData();
  const retrievedRecipientID = Number(getSearchParams('rid'));
  const currentRecipient = [...recipients].filter(
    (rec) => rec.id === retrievedRecipientID,
  );
  const isRecipientValid = isNotEmpty(currentRecipient);
  const category = useRelatedCategoryName();
  const [addonButtonPosition, setAddonButtonPosition] = useState<
    string | undefined
  >();
  useEffect(() => {
    batch(() => {
      dispatch(resetAvailabilitySession());
      dispatch(pas.reset());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = (nextStep: Step) => {
    if (root) {
      root.scrollTop = 0;
    }
    setStep(nextStep);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    dispatch(fetchRecipients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isRecipientValid &&
      isPasFilled(pasData) &&
      isPasUpdated &&
      step !== Step.FORM
    ) {
      goTo(Step.FORM);
    }
  }, [pasData, isRecipientValid, step, isPasUpdated]);

  useEffect(() => {
    if (isRecipientValid) {
      const selectedDate = new Date(
        format(new Date(currentRecipient[0].fulfillmentDate), 'MM/dd/yyyy'),
      );
      dispatch(
        updateAvailabilitySession({
          currentRecipientId: currentRecipient[0].id,
          serviceOption: currentRecipient[0].orderType,
          areaId: currentRecipient[0].area.id,
          areaName: currentRecipient[0].area.name,
          storeId: currentRecipient[0].storeId,
          selectedDate,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecipientValid]);

  useEffect(() => {
    const { serviceOption } = availabilitySession;
    if (
      isRecipientValid &&
      availabilitySession.isSet &&
      serviceOption &&
      serviceOption !== OrderType.WalkIn
    ) {
      dispatch(
        pas.set({
          date: selectedSessionDate,
          area: sessionArea,
          orderType: serviceOption,
          store: sessionStore,
        }),
      );
      setIsPasUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitySession]);

  useSyncRecipientCity(recipient.cityMlId, setRecipient);
  useAAStoreSelectedEvent();
  useAAShipmentRecipientFormEvent(
    product,
    recipient.upsellItems,
    step,
    addonButtonPosition,
  );
  useProductAddedToCartRedirect(
    quantity,
    product,
    recipient.upsellItems,
    pasData.store?.id || 0,
  );
  useUpdatedCartRedirect(cartRecipientId);
  useGTagProductAddedToCart(
    arrangement,
    product,
    category,
    quantity,
    recipient.upsellItems,
  );

  const setUpsellItems = (upsellItems: UpsellItem[]): void => {
    setRecipient((prev) => ({
      ...prev,
      upsellItems,
    }));
  };

  const extraUpsells = recipient.upsellItems.filter(removeOwnBoxAddons);
  const ownBoxUpsells = recipient.upsellItems.filter(getOwnBoxAddons);

  const setOwnBoxAddonsToUpsell = (ownBoxAddons: UpsellItem[]) => {
    setUpsellItems([...extraUpsells, ...ownBoxAddons]);
  };
  useEffect(() => {
    setStep(Step.DETAILS);

    if (product && !isOwnBox) {
      setProduct(null);
    } else if (isOwnBox && isNotEmpty(arrangement.products)) {
      setProduct(arrangement.products[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrangement.id]);

  useEffect(() => {
    if (isOwnBox) {
      setUpsellItems(ownBoxUpsells);
    } else {
      setUpsellItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasData.store?.id]);

  const isSaturdayNonResidentialShipment =
    useIsSaturdayNonResidentialShipment();

  const validationStatus = useSelector(getResult);
  const area = useSelector(({ area: { data } }) => data);

  const rankedStoreid = useSelector(selectStoreRanking);
  const anotherAddToCart = (fulfillment: Recipient): void => {
    if (isNull(product) || !isPasFilled(pasData)) {
      return;
    }
    const { orderType, date, store } = pasData;
    const selectedCityName =
      area.cities.find(({ mlId }) => mlId === fulfillment.cityMlId)?.name || '';
    let orderTypeDependencies;
    if (isPickup(orderType)) {
      orderTypeDependencies = {
        AddressType: AddressType.NotSpecified,
        FulfillmentDate: fulfillment.fulfillmentDate,
        IsCurbSide: fulfillment.curbside === Curbside.YES,
        CarInfo:
          fulfillment.curbside === Curbside.YES
            ? `${fulfillment.carModel}|${fulfillment.carColor}`
            : '',
      };
    } else {
      orderTypeDependencies = {
        AddressType: fulfillment.addressType || AddressType.Residential,
        FulfillmentDate: formatToLegacyDateString(date),
      };
    }

    let addToCartData: LegacyAddToCartParams = {
      ...orderTypeDependencies,
      ...getDefaultFlexCartParam(),
      OrderType: orderType,
      EditRecipientID: `${cartRecipientId}`,
      Address1: fulfillment.address1,
      Address2: fulfillment.address2 || '',
      Company: fulfillment.company || '',
      AreaID: area.id,
      AreaName: area.name,
      ArrangementGroupID: arrangement.groupId,
      ArrangementID: arrangement.id,
      ArrangementSizeID: IS_MOBILE_HOST
        ? product.arrangementSizeId
        : `${product.arrangementSizeId}`,
      CityMLID: `${fulfillment.cityMlId}`,
      CityName: `${selectedCityName}`,
      FirstName: fulfillment.firstName,
      LastName: fulfillment.lastName,
      HomePhone: fulfillment.phone,
      CellPhone: fulfillment.cellPhone,
      WorkPhone: fulfillment.workPhone,
      RecipientEmail: fulfillment.recipientEmail,
      OccassionID: `${fulfillment.occasionId}`,
      DeliveryOrPickupInstructions: fulfillment.instructions,
      ProductID: IS_MOBILE_HOST ? product.id : `${product.id}`,
      Quantity: quantity,
      StateCode: `${area.stateCode}`,
      StoreID: store.id,
      CardMessage: fulfillment.cardMessage,
      AddonItems: extractUpsells(fulfillment.upsellItems, UpsellType.AddOn),
      UpgradeItems: extractUpsells(fulfillment.upsellItems, UpsellType.Upgrade),
      ServiceOption: orderType,
      CurrentRecipientID: recipientId,
      VaultedCartID: user?.vaultedCartId || '',
    };

    addToCartData = fillCartWithPrintible(addToCartData);
    addToCartData = pickBy(
      addToCartData,
      isNotUndefined,
    ) as LegacyAddToCartParams;
    dispatch(addToLegacyCart(addToCartData));
    if (isDelivery(orderType) && rankedStoreid > 0) {
      AddSessionStoreRanking(
        store.id,
        rankedStoreid,
        area?.name === store.areaName,
      );
    }
    cordialAnalytics.addToCart(
      arrangement.catalogNumber,
      arrangement.name,
      quantity,
      product.price,
      product.name,
      category,
    );
  };

  useEffect(() => {
    if (cartRecipientId) {
      const newRecipientData = populateRecipientData(availabilitySession);
      const data = {
        ...newRecipientData,
        upsellItems: [...ownBoxUpsells],
      };
      setRecipient(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRecipientId]);

  useEffect(() => {
    addToLocalStorage(window.location.search);
    if (isFulfilled(availabilityCheckState.status)) {
      dispatch(
        pas.set({
          area: sessionArea,
          date: sessionDate,
          orderType: availabilitySession.serviceOption as FulfillmentOptionType,
          store: sessionStore,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityCheckState]);

  useEffect(() => {
    if (
      !isSaturdayNonResidentialShipment &&
      validationStatus &&
      isValid(validationStatus)
    ) {
      anotherAddToCart(recipient);
      dispatch(resetAddressValidation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationStatus]);

  useEffect(() => {
    if (optionsIds.length && recipients.length) {
      recipients.forEach((currRecipient) => {
        dispatch(
          retrieveRecipientAvailability({
            ids: optionsIds,
            storeId: currRecipient.storeId,
            areaId: currRecipient.area.id,
            orderType: currRecipient.orderType,
            fulfillmentDate: new Date(currRecipient.fulfillmentDate),
            recipientId: currRecipient.id,
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrangement, recipients]);

  const updatePickUpTime = (fulfillment: Recipient): void => {
    const { orderType } = pasData;
    const selectedCityName =
      area.cities.find(({ mlId }) => mlId === fulfillment.cityMlId)?.name || '';

    const updatePickUpTimeDate: UpdatePickUpTimeParams = {
      StateCode: area.stateCode,
      AreaName: area.name,
      ServiceOption: orderType,
      AreaID: area.id,
      FirstName: fulfillment.firstName,
      LastName: fulfillment.lastName,
      EditCartRecipientID: cartRecipientId,
      AddressType: AddressType.NotSpecified,
      Company: fulfillment.company || '',
      Address1: fulfillment.address1,
      Address2: fulfillment.address2 || '',
      CityMLID: `${fulfillment.cityMlId}`,
      CityName: `${selectedCityName}`,
      HomePhone: fulfillment.phone,
      CellPhone: fulfillment.cellPhone,
      WorkPhone: fulfillment.workPhone,
      RecipientEmail: fulfillment.recipientEmail,
      OccasionID: fulfillment.occasionId,
      FulFillmentDate: fulfillment.fulfillmentDate,
      IsCurbSide: fulfillment.curbside === Curbside.YES,
      CarInfo:
        fulfillment.curbside === Curbside.YES
          ? `${fulfillment.carModel}|${fulfillment.carColor}`
          : '',
    };
    dispatch(updateLegacyCart(updatePickUpTimeDate));
  };

  const addToCart = (fulfillment: Recipient): void => {
    if (!isPasFilled(pasData)) {
      return;
    }
    const { orderType } = pasData;
    if (isRecipientValid && isPasFilled(pasData) && cartRecipientId) {
      updatePickUpTime(fulfillment);
      return;
    }
    if (isShipment(orderType) || isDelivery(orderType)) {
      const city =
        area.cities.find(({ mlId }) => mlId === fulfillment.cityMlId)?.name ||
        '';
      if (!city) {
        return;
      }

      dispatch(
        fetchSuggestedAddresses({
          city,
          country: area.countryCode,
          state: area.stateCode,
          street1: fulfillment.address1,
          street2: fulfillment.address2,
          zip: area.name,
        }),
      );
      return;
    }

    anotherAddToCart(fulfillment);
  };

  const onSuggestedAddressDialogClose = () => {
    appInsights.trackEvent({ name: 'Suggested address dialog closed' });
    dispatch(resetAddressValidation());
  };

  const onVerifyAddressDialogClose = () => {
    appInsights.trackEvent({ name: 'Verify address dialog closed' });
    dispatch(resetAddressValidation());
  };

  const onSuggestedAddressDialogGoBack = () => {
    appInsights.trackEvent({ name: 'Suggested address navigated to details' });
    dispatch(resetAddressValidation());
    goTo(Step.DETAILS);
  };

  const rejectRevalidation = (rejectedStep: Revalidation) => {
    setRevalidation(Revalidation.None);
    goTo(Step.DETAILS);

    const orderTypeOnRejection =
      rejectedStep === Revalidation.OnDate
        ? OrderType.NotSpecified
        : pasData.orderType;

    dispatch(
      pas.set({
        isOpened: true,
        orderType: orderTypeOnRejection,
        store: null,
      }),
    );
  };

  const onSuggestedAddressDialogSelectAddress = () => {
    if (firstSuggestedAddress.street1.toLowerCase().includes('po box')) {
      setRecipient({ ...recipient, address1: firstSuggestedAddress.street1 });
      onSuggestedAddressDialogClose();
      return;
    }
    if (
      !firstSuggestedAddress ||
      !firstSuggestedAddress.zip ||
      isNull(pasData.area)
    ) {
      return;
    }
    const [suggestedZip] = firstSuggestedAddress.zip.split('-');
    appInsights.trackEvent({
      name: 'PDP suggested address selected',
      properties: { sameZip: area.name === suggestedZip },
    });

    const data = {
      ...recipient,
      address1: firstSuggestedAddress.street1,
      address2: firstSuggestedAddress.street2 || '',
      cityMlId: suggestedCity?.mlId ?? area.cities[0].mlId,
      upsellItems:
        area.name !== suggestedZip
          ? [...ownBoxUpsells]
          : [...recipient.upsellItems],
    };
    dispatch(resetAddressValidation());
    setRecipient(data);

    if (area.name === suggestedZip) {
      anotherAddToCart(data);
      dispatch(resetAddressValidation());
      return;
    }
    dispatch(getArea(suggestedZip));
    setRevalidation(Revalidation.Area);
    dispatch(resetAvailabilitySession());
  };

  const onSelectOriginalAddress = () => {
    onVerifyAddressDialogClose();
    anotherAddToCart(recipient);
  };

  const changeZipCode = (
    zip?: string,
    options?: { redirect: boolean; shouldValidate?: boolean },
  ): void => {
    dispatch(resetAvailabilitySession());

    if (options?.shouldValidate) {
      dispatch(zipAvailability.reset());
    }
    if (options?.redirect || !zip) {
      dispatch(
        pas.clearAndSet({
          area: zip ? getInvalidArea(zip) : null,
        }),
      );
      goTo(Step.DETAILS);
      return;
    }

    dispatch(getArea(zip));
    setRevalidation(Revalidation.Area);
  };

  const selectContact = (contact: Contact) => {
    appInsights.trackEvent({ name: 'Address book contact selected' });

    if (area.name !== contact.areaML?.name) {
      setRecipient((prev) => ({
        ...prev,
        ...mapContactToRecipient(contact),
        upsellItems: isOwnBox ? [...ownBoxUpsells] : [],
      }));

      const contactAreaName = contact.areaML?.name ?? contact.areaOther;
      changeZipCode(contactAreaName, {
        redirect: isNull(contact.areaML),
        shouldValidate: isNull(contact.areaML),
      });
    } else {
      setRecipient((prev) => ({
        ...prev,
        ...mapContactToRecipient(contact),
      }));
      dispatch(resetSelectedContacts());
    }
  };

  useEffect(() => {
    if (
      revalidation === Revalidation.Area &&
      isStateFulfilled(areaState) &&
      isNotNull(pasData.date)
    ) {
      dispatch(
        getAvailabilityOnDate({
          areaId: area.id,
          arrangementId: arrangement.id,
          date: pasData.date,
        }),
      );
      dispatch(pas.set({ area }));

      setRevalidation(Revalidation.OnDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areaState.data?.id,
    arrangement.id,
    pasData.date,
    dispatch,
    revalidation,
  ]);

  useEffect(() => {
    if (
      !pasData.area?.id &&
      !isDetails(step) &&
      isNone(revalidation) &&
      pasData.date
    ) {
      goTo(Step.DETAILS);
      dispatch(resetOnDate());
      dispatch(resetCartErrors());
      dispatch(pas.openAndClear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pasData.area?.id]);

  const shouldShowTestStore = useShouldShowTestStore();
  useEffect(() => {
    const { orderType, date } = pasData;

    if (
      !(revalidation === Revalidation.OnDate && isDateAvailabilityFulfilled) ||
      isNull(area) ||
      isNull(date)
    ) {
      return;
    }

    if (!isFulfillType(orderType)) {
      rejectRevalidation(Revalidation.OnDate);
      return;
    }

    const keys: Record<FulfillmentOptionType, keyof AvailabilityOnDate> = {
      [OrderType.Delivery]: 'delivery' as keyof AvailabilityOnDate,
      [OrderType.Shipment]: 'shipment' as keyof AvailabilityOnDate,
      [OrderType.Pickup]: 'pickup' as keyof AvailabilityOnDate,
    };

    const key: keyof AvailabilityOnDate = keys[orderType];
    if (
      !(dateAvailability[key] as OrderTypeAvailability).available ||
      !date ||
      !arrangement.id ||
      !product?.id
    ) {
      rejectRevalidation(Revalidation.OnDate);
    } else {
      dispatch(
        fetchStores({
          areaId: area.id,
          areaName: area.name,
          date,
          orderType,
          test: shouldShowTestStore,
          arrangementId: arrangement.id,
          productId: product.id,
        }),
      );
      setRevalidation(Revalidation.StoreSelection);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    pasData.area?.id,
    arrangement.id,
    pasData.date,
    dateAvailability,
    isDateAvailabilityFulfilled,
    pasData.orderType,
    product?.id,
    revalidation,
    shouldShowTestStore,
  ]);

  const stores = useSelector(selectStores);
  const isStoresFulfilled = useSelector(selectIsStoresFulfilled);

  useEffect(() => {
    if (!isPasFilled(pasData)) {
      return;
    }
    if (revalidation === Revalidation.StoreSelection && isStoresFulfilled) {
      if (isEmpty(stores)) {
        rejectRevalidation(Revalidation.StoreSelection);
        return;
      }

      const { orderType, store } = pasData;

      if (isPickup(orderType)) {
        if (stores.some(({ id }) => store.id === id)) {
          setRevalidation(Revalidation.Cart);
        } else {
          rejectRevalidation(Revalidation.Cart);
          return;
        }
      }
      if (isShipment(orderType)) {
        if (stores.some(({ id }) => store.id === id)) {
          setRevalidation(Revalidation.Cart);
        } else {
          dispatch(pas.set({ store: stores[0] }));
          setRevalidation(Revalidation.Cart);
        }
      }
      if (isDelivery(orderType)) {
        if (stores.some(({ id }) => store.id === id)) {
          setRevalidation(Revalidation.Cart);
        } else if (stores.length === 1) {
          dispatch(pas.set({ store: stores[0] }));
          setRevalidation(Revalidation.Cart);
        } else {
          rejectRevalidation(Revalidation.Cart);
        }
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    pasData.store?.id,
    pasData.orderType,
    recipient,
    revalidation,
    stores,
    isStoresFulfilled,
  ]);

  useEffect(() => {
    if (
      revalidation === Revalidation.Cart &&
      isNotNull(selectedContact) &&
      isPasFilled(pasData)
    ) {
      setRevalidation(Revalidation.None);
      dispatch(resetSelectedContacts());
      if (!isRecipientExist) {
        dispatch(
          updateAvailabilitySession({
            storeId: pasData.store.id,
            areaId: pasData.area.id,
            areaName: pasData.area.name,
            selectedDate: pasData.date,
            serviceOption: pasData.orderType,
            currentRecipientId: recipientId,
          }),
        );
      }

      return;
    }
    if (revalidation === Revalidation.Cart) {
      dispatch(resetSelectedContacts());
      anotherAddToCart(recipient);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [revalidation]);

  return (
    <>
      <Backdrop opened={revalidation !== Revalidation.None} />
      <HeadMeta />
      <Box mx="auto" px={0} maxWidth={[1, 688, 1060]} width={1}>
        <MarginWrapper>
          <Back step={step} setStep={goTo} />
        </MarginWrapper>
        <Box mt={3}>
          {isDetails(step) && (
            <Details
              quantity={quantity}
              setQuantity={setQuantity}
              product={product}
              setProduct={setProduct}
              setOwnBoxAddons={setOwnBoxAddonsToUpsell}
              selectedOwnBoxAddons={ownBoxUpsells}
              bottomContainer={bottomContainer}
              setClickOwnBoxAddon={setClickOwnBoxAddon}
              clickOwnBoxAddon={clickOwnBoxAddon}
              onSubmit={() => {
                goTo(Step.UPSELLS);
              }}
              isCookie12={isCookie12}
            />
          )}
          {step === Step.UPSELLS && product && isPasFilled(pasData) && (
            <MarginWrapper data-test="pdp-upsells-page">
              <Upsells
                orderType={pasData.orderType}
                storeId={pasData.store.id}
                date={pasData.date}
                selections={recipient.upsellItems}
                onContinue={(buttonPosition) => {
                  goTo(Step.FORM);
                  setAddonButtonPosition(buttonPosition);
                }}
                setSelections={setUpsellItems}
                recipient={recipient}
                clickOwnBoxAddon={clickOwnBoxAddon}
                product={{
                  product,
                  quantity,
                }}
                isCookie12={isCookie12}
              />
            </MarginWrapper>
          )}
          {step === Step.FORM && isPasFilled(pasData) && (
            <Order
              date={pasData.date}
              recipient={recipient}
              setRecipient={setRecipient}
              orderType={pasData.orderType}
              addToCart={addToCart}
              selectedDate={pasData.date}
              selectedStore={pasData.store}
              selectedStoreId={pasData.store.id}
              pickupStore={pasData.store}
              changeZipCode={changeZipCode}
              clickOwnBoxAddon={clickOwnBoxAddon}
              processing={
                isAddToCartBlocked || revalidation !== Revalidation.None
              }
              selectContact={selectContact}
              isCookie12={isCookie12}
            />
          )}
        </Box>
        {isC8Enabled && step === 0 && product && (
          <Gallery
            sx={{ pt: 5, pb: 10 }}
            arrangement={arrangement}
            product={product}
          />
        )}
      </Box>
      <AddressValidation
        open={Boolean(
          validationStatus &&
            (isNotValid(validationStatus) || isSaturdayNonResidentialShipment),
        )}
        isSaturdayNonResidentialShipment={isSaturdayNonResidentialShipment}
        onClose={onSuggestedAddressDialogClose}
        isShipmentOrder={isShipment(pasData.orderType)}
        onSelectOriginalAddress={onSelectOriginalAddress}
        onGoBack={onSuggestedAddressDialogGoBack}
        onSelect={onSuggestedAddressDialogSelectAddress}
      />
      <MainAlert />
      <CallCenter />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PDPContainer;
