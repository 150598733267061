import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { ArrangementGroupOccasion } from 'api/arrangement-group.api.service';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  CHARS_LIMIT,
  CHARS_PER_LINE,
  MAX_LIMITED_TEXT_FIELD_LINES_NUMBER,
  MAX_SYMBOLS_ALLOWED,
  REACHING_TEXT_TRIGGER,
} from 'components/Rewards/RewardLanding/MyRewards/Reminder/constants';
import { Close } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { Forms } from 'service/adobe-analytics/enums';
import { GiftRemindersResponse } from 'api/rewards/types/reminders-response.type';
import { LimitedTextField } from 'components/PDP/Order/common/components/LimitedTextField/LimitedTextField';
import { Option } from 'components/common/Select/Option';
import {
  ReminderFormValues,
  ReminderParams,
  schema,
} from 'components/Rewards/RewardLanding/MyRewards/Reminder/types';
import { analytics } from 'service/adobe-analytics/analytics';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useRef } from 'react';
import i18next from 'i18next';

export type Props = {
  open?: boolean;
  onConfirm?: () => void;
  onReject: () => void;
  giftReminderDetails?: GiftRemindersResponse;
  addEditReminder: (data: ReminderParams) => void;
  occasionCategories: ArrangementGroupOccasion[];
};

export const AddReminderDialog: FC<Props> = ({
  onConfirm,
  onReject,
  open = false,
  giftReminderDetails,
  addEditReminder,
  occasionCategories,
}) => {
  const reminderFormRef = useRef<HTMLTextAreaElement>(null);
  const defaultValues = {
    title: giftReminderDetails?.title,
    occasionDate: giftReminderDetails?.occasionDate,
    occasionId: giftReminderDetails?.occasionId,
    advanceNotificationDays: giftReminderDetails?.advanceNotificationDays,
    recurring: giftReminderDetails?.recurring,
    note: giftReminderDetails?.note,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<ReminderFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues,
  });
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId =
    accountInfo.length && accountInfo[0].id ? accountInfo[0].id : 0;

  const onSubmit = handleSubmit((data) => {
    analytics.formRewards(Forms.GiftReminder);
    addEditReminder(data);
    reset();
    onReject();
  });

  const onCancel = () => {
    reset();
    onReject();
  };

  const onBlurMessage = (value: string) => {
    if (!value) {
      setError('note', { type: 'manual' });
    }
    setValue('note', value);
  };

  const onLinesNumberChanged = (lines: number) => {
    return lines;
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: '770px !important',
          height: ['710px', '550px', '550px'],
          overflow: 'hidden',
        },
      }}
      sx={styles.reminderDialogContainer}
      onClose={onCancel}
      open={open}
    >
      <DialogTitle>
        <Typography variant="h3" align="center">
          {i18next.t(TK.GIFT_REMINDER)}
        </Typography>
        <IconButton
          onClick={onCancel}
          sx={styles.closeBtnStyle}
          aria-label={i18next.t(TK.CLOSE)}
          size="large"
          color="secondary"
          data-test="reminder-dialog-close"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ margin: 'auto', padding: '7px 0px 0px 0px' }}>
          <Box sx={styles.reminderTopContainer} justifyContent="center">
            <input type="hidden" {...register('accountId')} value={accountId} />
            <input
              type="hidden"
              {...register('id')}
              value={Number(giftReminderDetails?.id || 0)}
            />
            <TextField
              error={Boolean(errors.title)}
              helperText={
                errors.title ? (
                  <span data-test="reminder-form-title-error">
                    {i18next.t(TK.REMINDER_TITLE_MSG)}
                  </span>
                ) : (
                  <span data-test="reminder-form-title-error">
                    {i18next.t(TK.REMINDER_TITLE_SUB_MSG)}
                  </span>
                )
              }
              label={i18next.t(TK.REMINDER_TITLE)}
              placeholder={i18next.t(TK.REMINDER_TITLE)}
              variant="outlined"
              type="text"
              sx={styles.inputLeftStyle}
              defaultValue={giftReminderDetails?.title}
              {...register('title')}
            />
            <Controller
              control={control}
              name="occasionDate"
              render={({ field: { value } }) => (
                <FormControl>
                  <DatePicker
                    label={i18next.t(TK.REMINDER_OCCASION_DATE)}
                    value={value ?? null}
                    onChange={(newValue) =>
                      newValue ? setValue('occasionDate', newValue) : null
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={styles.inputRightStyle}
                        {...params}
                        error={Boolean(errors.occasionDate)}
                      />
                    )}
                  />
                  {errors?.occasionDate && (
                    <FormHelperText error>
                      {errors.occasionDate?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Box
            sx={styles.reminderTopContainer}
            justifyContent="center"
            width="103%"
          >
            <FormControl sx={styles.formControl}>
              <InputLabel color="secondary">
                {i18next.t(TK.REMINDER_OCCASION)}
              </InputLabel>
              <Select
                error={Boolean(errors.occasionId)}
                {...register('occasionId')}
                label={i18next.t(TK.REMINDER_OCCASION)}
                defaultValue={giftReminderDetails?.occasionId || 0}
                MenuProps={{ disableScrollLock: true }}
                sx={styles.inputLeftStyle}
              >
                <Option value="0">
                  {i18next.t(TK.REMINDER_OCCASION_ERROR)}
                </Option>
                {occasionCategories.map((data) => (
                  <Option key={data.id} value={data.id}>
                    {data.name}
                  </Option>
                ))}
              </Select>
              {errors?.occasionId && (
                <FormHelperText error>
                  {i18next.t(TK.REMINDER_OCCASION_ERROR)}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={styles.formControl}>
              <InputLabel color="secondary">
                {i18next.t(TK.REMINDER_REMIND_ME)}
              </InputLabel>
              <Select
                error={Boolean(errors.advanceNotificationDays)}
                {...register('advanceNotificationDays')}
                label={i18next.t(TK.REMINDER_REMIND_ME)}
                defaultValue={giftReminderDetails?.advanceNotificationDays || 1}
                MenuProps={{ disableScrollLock: true }}
                sx={styles.inputRightStyle}
              >
                <Option value="1">{i18next.t(TK.PRIOR_1_DAY)}</Option>
                <Option value="2">{i18next.t(TK.PRIOR_2_DAYS)}</Option>
                <Option value="5">{i18next.t(TK.PRIOR_5_DAYS)}</Option>
                <Option value="8">{i18next.t(TK.PRIOR_8_DAYS)}</Option>
                <Option value="10">{i18next.t(TK.PRIOR_10_DAYS)}</Option>
                <Option value="15">{i18next.t(TK.PRIOR_15_DAYS)}</Option>
              </Select>
              {errors?.advanceNotificationDays && (
                <FormHelperText error>
                  {i18next.t(TK.REMINDER_REMIND_ME_ERROR)}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={giftReminderDetails?.recurring || true}
                />
              }
              label="Remind me every year"
              {...register('recurring')}
            />
          </Box>
          <FormControl sx={styles.formControl}>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange } }) => (
                <Box sx={styles.textLimitField}>
                  <LimitedTextField
                    label={i18next.t(TK.REWARDS_TEXT)}
                    placeholder={i18next.t(TK.REMINDER_NOTES)}
                    chars={CHARS_LIMIT}
                    max_chars_allowed={MAX_SYMBOLS_ALLOWED}
                    reaching_text_trigger={REACHING_TEXT_TRIGGER}
                    rows={MAX_LIMITED_TEXT_FIELD_LINES_NUMBER}
                    charsPerLine={CHARS_PER_LINE}
                    setValue={onChange}
                    onBlur={onBlurMessage}
                    onLinesNumberChanged={onLinesNumberChanged}
                    inputRef={reminderFormRef}
                    value={getValues('note') || ''}
                    dataTest="reminder-note-message"
                    fieldName="note"
                  />
                </Box>
              )}
            />
          </FormControl>
          <Box
            sx={{ margin: '0 5px' }}
            display="flex"
            justifyContent="center"
          />
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button
            onClick={onCancel}
            sx={styles.cancelBtnStyle}
            variant="outlined"
            color="primary"
          >
            {i18next.t(TK.CANCEL_TEXT)}
          </Button>
          <Button
            onClick={onConfirm}
            sx={styles.saveBtnStyle}
            variant="contained"
            color="primary"
            type="submit"
          >
            {i18next.t(TK.REWARD_DIALOG_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
