import * as TK from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/translations/delivery-methods.locale.constants';
import { FulfillmentOptionType } from 'types/cart.types';
import { TFunctionKeys } from 'i18next';
import { isPickup } from 'utils/is-order-type';

export function getPreviewLocaleKey(
  orderType: FulfillmentOptionType,
): TFunctionKeys {
  return isPickup(orderType) ? TK.PICK_UP : TK.DELIVERY;
}
