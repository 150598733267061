import { SxProps } from '@mui/system';
import { Theme, alpha } from '@mui/material/styles';
import { colors } from 'utils/theme/colors';

export const logo: SxProps = {
  width: 'auto',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  pr: 4,
  '& a': {
    height: '100%',
  },
  '& a img': {
    width: 'auto',
    height: '100%',
  },
};

export const baseInput: SxProps<Theme> = (theme) => ({
  flex: '1 0 auto',
  '& div.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '& .Mui-focused': {
    border: `1px solid ${colors.black}`,
  },
  '& input.MuiOutlinedInput-input': {
    padding: theme.spacing(1.2, 1.75, 1.2, 0),
    '&::placeholder': {
      color: 'text.secondary',
      opacity: 1,
      fontWeight: 500,
    },
  },
});

export const drawerWrapper: SxProps<Theme> = (theme) => ({
  width: { xs: 'unset', md: 1 },
  height: { xs: '100vh', md: 'unset' },
  display: 'flex',
  alignItems: 'flex-start',
  margin: {
    xs: 0,
    md: theme.spacing(5.625, 'auto', 0, 'auto'),
  },
  maxWidth: { xs: 'unset', md: '1060px' },
  padding: theme.spacing(2, 0),
});

export const autocompleteWrapper: SxProps = {
  flex: { xs: '1 1 auto', md: '1 0 auto' },
  display: 'flex',
  flexDirection: 'column',
  ml: { xs: 0, md: 4 },
  '& .MuiAutocomplete-root': {
    display: 'flex',
    alignItems: 'flex-start',
  },
};

export const autocompleteInput: SxProps<Theme> = (theme) => ({
  margin: { xs: theme.spacing(0, 0, 0, 2.5), md: 0 },
  padding: theme.spacing(0),
  '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
    padding: theme.spacing(0.25, 1.75),
    minHeight: { xs: '48px', md: '40px' },

    '& .MuiAutocomplete-input': {
      padding: theme.spacing(1, 3.75, 1, 0),
    },

    '& .MuiAutocomplete-endAdornment': {
      right: 16,
      top: { xs: 8, md: 4 },
    },

    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
    },
  },
});

export const helperText: SxProps<Theme> = (theme) => ({
  display: 'inline-block',
  mt: 2,
  mb: 1.5,
  marginLeft: { xs: theme.spacing(0.75), md: theme.spacing(1.2) },
});

export const closeButton: SxProps = {
  marginLeft: 4,
  px: 5,
};

export const closeButtonMobile: SxProps = {
  mt: 0.5,
  ml: 1,
  mr: 1.5,
};

export const suggestionsList: SxProps<Theme> = (theme) => ({
  margin: { xs: theme.spacing(0, 1.5, 0, 2.5), md: 0 },
  paddingLeft: theme.spacing(1),
  '& .MuiPaper-root': {
    boxShadow: 'none',
    '& .MuiAutocomplete-listbox': {
      maxHeight: 'none',
      padding: 0,
      mb: 1,
    },
    '& .MuiMenuItem-root': {
      padding: { xs: theme.spacing(1.2, 1.5), md: theme.spacing(1.2, 2) },
      marginBottom: theme.spacing(0.5),

      '&:hover': {
        background: alpha(
          theme.palette.primary.main,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  },
});

export const allResults: SxProps<Theme> = (theme) => ({
  display: 'inline-flex',
  alignSelf: 'flex-start',
  margin: {
    xs: theme.spacing(0, 1.5, 0, 3),
    md: theme.spacing(0, 0, 0, 1),
  },
});

export const suggestedOption: SxProps<Theme> = (theme) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'pre-wrap',
  '&:hover': {
    color: theme.palette.primary.main,
  },
});

export const highlight: SxProps<Theme> = (theme) => ({
  color: theme.palette.text.primary,
});

export const paper = (padding: number): SxProps => ({
  '& .MuiDrawer-paper': {
    paddingRight: `${padding}px`,
  },
});

export const divider: SxProps = {
  my: 1,
};
