import * as TK from 'translations/locales/translation-keys.constant';
import { Breadcrumbs, Link, LinkProps } from '@mui/material';
import { icon } from 'components/PDP/GoBack/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { FC } from 'react';
import i18next from 'i18next';

export const BreadcrumbBack: FC<LinkProps> = ({
  sx,
  children,
  ...props
}: LinkProps) => {
  return (
    <Breadcrumbs>
      <Link
        {...props}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: 12,
          lineHeight: 1.6,
          color: 'text.primary',
          ...sx,
        }}
        data-test="back-button"
      >
        <ArrowBackIosNewIcon sx={icon} fontSize="inherit" role="presentation" />
        {children || i18next.t(TK.BACK)}
      </Link>
    </Breadcrumbs>
  );
};
