import * as TK from 'containers/RewardsContainer/translations/locale.constants';
import * as TKR from 'components/Rewards/translations/locale.constants';
import * as actions from 'components/Payment/feature/actions';
import * as select from 'components/Payment/feature/selectors';
import * as styles from 'components/Rewards/RewardLanding/MyRewards/AddressBook/contact-dialog-styles';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { FullName } from 'components/PDP/Order/RecipientForm/components/FullName/FullName';
import { Option } from 'components/common/Select/Option';

import { AccountInfo } from 'providers/Session/feature/user.types';
import {
  AddressBookParams,
  ContactInfoData,
} from 'components/Rewards/RewardLanding/MyRewards/AddressBook/types';
import { AddressBookResponse } from 'api/rewards/types/address-book-response.type';
import { AddressType } from 'types/cart.types';
import { ContactInformationFormSchema } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/schema';
import { Forms } from 'service/adobe-analytics/enums';
import { Select } from 'components/common/Select';
import { Status } from 'redux/types/state.interface';
import { ZIP_LENGTH } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.constants';
import { analytics } from 'service/adobe-analytics/analytics';
import {
  isFullZip,
  sanitizeZip,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.utils';
import { isUS } from 'utils/country';
import { phoneModifier } from 'utils/phone-modifier';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCountries } from 'components/Payment/BillingInformation/hooks/use-fetch-countries';
import { useFetchStates } from 'components/Payment/BillingInformation/hooks/use-fetch-states';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

const currentCountry = isUS ? 'US' : 'CA';
export type Props = {
  open?: boolean;
  onConfirm?: () => void;
  onReject: () => void;
  address?: AddressBookResponse;
  addEditAddress: (data: AddressBookParams) => void;
};

export const AddContactDialog: FC<Props> = ({
  onConfirm,
  onReject,
  open = false,
  address,
  addEditAddress,
}) => {
  useFetchCountries();
  useFetchStates();
  const dispatch = useDispatch();

  const area = useSelector(select.selectArea);
  const areaStatus = useSelector(select.selectAreaStatus);
  const states = useSelector(select.selectStates);
  const cities = useSelector(select.selectCities);
  const countries = useSelector(select.selectCountries);
  const isLoading = useSelector(select.selectIsAreaPending);
  const isEmailExist = useSelector(select.selectEmailExist);

  const [showStateOtherOption, setShowStateOtherOption] = useState(false);
  const [isCountrycheck, setIsCountrycheck] = useState(false);

  const stateOptions = states || [];

  const defaultValues = {
    title: address?.title,
    firstName: address?.firstName,
    lastName: address?.lastName,
    addressType: address?.addressType,
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city,
    state: address?.stateCode || '',
    otherState: '',
    country: currentCountry,
    zipCode: address?.zipCode,
    phone: address?.cellPhone || address?.homePhone || address?.workPhone,
    email: address?.email,
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ContactInfoData>({
    mode: 'onChange',
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(ContactInformationFormSchema),
  });

  const userAccountInformation = useSelector(selectUserAccountInformation);
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId =
    accountInfo.length && accountInfo[0].id ? accountInfo[0].id : 0;

  useEffect(() => {
    if (areaStatus === Status.ERROR) {
      setValue('zipCode', '', { shouldValidate: true });
      setValue('city', '');
      setValue('state', '');
      return;
    }
    const { state, country, cities: areaCities } = area;
    type FieldName = 'city' | 'state' | 'country';
    if (state && country && !!areaCities.length) {
      const fieldsToUpdate: Record<FieldName, string> = {
        country,
        state,
        city: areaCities[0].name,
      };
      (Object.entries(fieldsToUpdate) as [FieldName, string][]).forEach(
        ([key, value]) => {
          setValue(key, value, { shouldValidate: true });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, areaStatus]);

  const validateZip = (zipValue: string) => {
    if (!isCountrycheck) {
      const value = sanitizeZip(zipValue);

      setValue('zipCode', value, { shouldValidate: true });

      if (isFullZip(value)) {
        dispatch(actions.getArea(value));
      }
    } else {
      setValue('zipCode', zipValue, { shouldValidate: true });
    }
  };

  const addressTypeOptions = Object.keys(AddressType)
    .filter((value) => Number.isNaN(Number(value)))
    .map((key, index) => ({
      text: index === 0 ? i18next.t(TK.CHOOSE_OPTION) : key,
      value: index,
    }));

  const onSubmit = handleSubmit((data) => {
    analytics.formRewards(Forms.AddressBook);
    addEditAddress(data);
    reset();
    onReject();
  });

  const onCancel = () => {
    reset();
    onReject();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: '688px !important',
          height: ['730px', '664px', '664px'],
          overflow: 'hidden',
        },
      }}
      sx={styles.contactDialogContainer}
      onClose={onCancel}
      open={open}
    >
      <DialogTitle>
        <Typography variant="h6" align="center" fontWeight="500">
          {i18next.t(TK.ADDRESS_BOOK)}
        </Typography>
        <IconButton
          onClick={onCancel}
          sx={styles.closeBtnStyle}
          aria-label="x"
          size="large"
          color="secondary"
          data-test="reminder-dialog-close"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ margin: 'auto', padding: 0 }}>
          <input type="hidden" {...register('accountId')} value={accountId} />
          <input
            type="hidden"
            {...register('id')}
            value={Number(address?.id || 0)}
          />
          <Box>
            <Typography variant="subtitle1" mb={2} fontWeight="500">
              {i18next.t(TK.CONTACT_DIALOG_TITLE)}
            </Typography>
            <Box sx={styles.contactContainer}>
              <Box>
                <FormControl sx={styles.titleBoxStyle} error={!!errors.title}>
                  <InputLabel
                    id="contact-title-label"
                    error={Boolean(errors.title)}
                  >
                    {i18next.t(TK.ADDRESS_TITLE)}
                  </InputLabel>
                  <Controller
                    control={control}
                    name="title"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        label={i18next.t(TK.ADDRESS_TITLE)}
                        labelId="contact-title-label"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={Boolean(errors.title)}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <MenuItem value="">
                          {i18next.t(TK.CHOOSE_OPTION)}
                        </MenuItem>
                        <MenuItem value="Dr.">
                          {i18next.t(TKR.TITLE_TEXT1)}
                        </MenuItem>
                        <MenuItem value="Mr.">
                          {i18next.t(TKR.TITLE_TEXT2)}
                        </MenuItem>
                        <MenuItem value="Mrs.">
                          {i18next.t(TKR.TITLE_TEXT3)}
                        </MenuItem>
                        <MenuItem value="Ms.">
                          {i18next.t(TKR.TITLE_TEXT4)}
                        </MenuItem>
                        <MenuItem value="Prof.">
                          {i18next.t(TKR.TITLE_TEXT5)}
                        </MenuItem>
                      </Select>
                    )}
                    rules={{ required: true }}
                  />
                  <FormHelperText>{errors.title?.message}</FormHelperText>
                </FormControl>
              </Box>
              <FullName
                firstName={watch('firstName')}
                lastName={watch('lastName')}
                register={register}
                firstNameError={errors.firstName?.message}
                lastNameError={errors.lastName?.message}
              />
            </Box>

            <Box sx={styles.contactContainer} mb={1} mt={[2, 0, 0]}>
              <FormControl>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      {...register('phone')}
                      sx={styles.phoneBoxStyle}
                      onChange={(event) => {
                        onChange(phoneModifier(event.target.value));
                      }}
                      value={value || ''}
                      variant="outlined"
                      data-test="billing-info-phone"
                      label={i18next.t(TK.PHONE_NUMBER)}
                      error={Boolean(errors.phone)}
                      autoComplete="off"
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={styles.emailBoxStyle}
                      onChange={({
                        target: { value: v },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(v);
                        if (!errors.email && getValues('rewardsRegistration')) {
                          dispatch(actions.isEmailAlreadyExist(v));
                        }
                      }}
                      value={value || ''}
                      variant="outlined"
                      label={i18next.t(TK.EMAIL_ADDRESS)}
                      error={Boolean(errors.email) || isEmailExist}
                      autoComplete="off"
                      data-test="billing-info-email"
                      InputLabelProps={{ shrink: !!watch('email') }}
                      helperText={
                        isEmailExist
                          ? i18next.t(TK.EMAIL_ALREADY_EXIST)
                          : errors.email?.message
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Typography variant="subtitle1" mb={1} mt={1} fontWeight="500">
              {i18next.t(TK.ADDRESS_BOOK_TITLE)}
            </Typography>
            <Box sx={styles.contactContainer} mb={2.5}>
              <Controller
                control={control}
                name="addressType"
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <InputLabel
                      id="address-book-addresstype-label"
                      error={Boolean(error)}
                    >
                      {`${i18next.t(TK.ADDRESS_TYPE_LABEL)}*`}
                    </InputLabel>
                    <Select
                      label={i18next.t(TK.ADDRESS_TYPE_LABEL)}
                      sx={styles.addressTypeContainer}
                      labelId="address-book-addresstype-label"
                      error={Boolean(errors.addressType)}
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      {addressTypeOptions.map((c) => (
                        <Option key={c.value} value={c.value}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {errors.addressType?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
            <Box sx={styles.contactContainer} mb={2.5}>
              <TextField
                label={`${i18next.t(TK.STREET_ADDRESS)}*`}
                {...register('address1', { required: true })}
                value={watch('address1')}
                InputLabelProps={{ shrink: !!watch('address1') }}
                variant="outlined"
                sx={styles.streetBoxStyle}
                error={Boolean(errors.address1)}
                helperText={errors.address1?.message}
              />
              <TextField
                label={i18next.t(TK.APT_FLOOR_SUITE_BUTTON)}
                {...register('address2')}
                InputLabelProps={{ shrink: !!watch('address2') }}
                value={watch('address2')}
                variant="outlined"
                sx={styles.apatBoxStyle}
                error={Boolean(errors.address2)}
              />
            </Box>

            <Box sx={styles.contactContainer}>
              <FormControl
                sx={styles.countryComponent}
                error={!!errors.country}
              >
                <Controller
                  control={control}
                  name="country"
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl>
                      <InputLabel
                        id="billing-information-country-label"
                        error={Boolean(error)}
                      >
                        {`${i18next.t(TK.COUNTRY_LABEL)}*`}
                      </InputLabel>
                      <Select
                        label={`${i18next.t(TK.COUNTRY_LABEL)}*`}
                        labelId="billing-information-country-label"
                        value={value}
                        error={Boolean(errors.country)}
                        onChange={(event) => {
                          onChange(event.target.value);
                          if (event.target.value !== currentCountry) {
                            setValue('state', 'Others');
                            setShowStateOtherOption(true);
                            setIsCountrycheck(true);
                            setValue('zipCode', '');
                            setValue('city', '');
                          } else {
                            setValue('state', '');
                            setShowStateOtherOption(false);
                            setIsCountrycheck(false);
                            setValue('zipCode', '');
                            setValue('city', '');
                          }
                        }}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="">{i18next.t(TK.CHOOSE_OPTION)}</Option>
                        {countries.map((data) => (
                          <Option key={data.ID} value={data.Code}>
                            {data.Name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                <FormHelperText>{errors.country?.message}</FormHelperText>
              </FormControl>

              <FormControl sx={styles.countryComponent} error={!!errors.city}>
                {!isCountrycheck && cities.length ? (
                  <>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: true }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <FormControl>
                          <InputLabel
                            id="billing-information-city-label"
                            error={Boolean(error)}
                          >
                            {`${i18next.t(TK.CITY_LABEL)}*`}
                          </InputLabel>
                          <Select
                            label={`${i18next.t(TK.CITY_LABEL)}*`}
                            labelId="billing-information-city-label"
                            value={value}
                            onChange={(event) => onChange(event.target.value)}
                            error={Boolean(errors.city)}
                            MenuProps={{ disableScrollLock: true }}
                          >
                            <Option value="">
                              {i18next.t(TK.CHOOSE_OPTION)}
                            </Option>
                            {cities.map((c) => (
                              <Option key={c.mlId} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <FormHelperText>{errors.city?.message}</FormHelperText>
                  </>
                ) : (
                  <TextField
                    {...register('city')}
                    value={watch('city')}
                    label={`${i18next.t(TK.CITY_LABEL)}*`}
                    error={Boolean(errors.city)}
                    helperText={errors.city?.message}
                  />
                )}
              </FormControl>
              <FormControl sx={styles.countryComponent} error={!!errors.state}>
                <Controller
                  control={control}
                  name="state"
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl>
                      <InputLabel
                        id="billing-information-state-label"
                        error={Boolean(error)}
                      >
                        {`${i18next.t(TK.STATE_LABEL)}*`}
                      </InputLabel>
                      <Select
                        label={`${i18next.t(TK.STATE_LABEL)}*`}
                        labelId="billing-information-state-label"
                        value={value}
                        error={Boolean(errors.state)}
                        onChange={(event) => {
                          onChange(event.target.value);
                          if (event.target.value === 'Others') {
                            setShowStateOtherOption(true);
                          }
                        }}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Option value="">{i18next.t(TK.CHOOSE_OPTION)}</Option>
                        {!!stateOptions.length &&
                          stateOptions.map((data) => (
                            <Option key={data.ID} value={data.Code}>
                              {data.Name}
                            </Option>
                          ))}
                        <Option value="Others">
                          {i18next.t(TKR.OTHERS_TEXT)}
                        </Option>
                      </Select>
                    </FormControl>
                  )}
                />
                <FormHelperText>{errors.state?.message}</FormHelperText>
                {showStateOtherOption && (
                  <TextField
                    label="State Name"
                    {...register('otherState', { required: true })}
                    value={watch('otherState')}
                    error={Boolean(errors.otherState)}
                    helperText={errors.otherState?.message}
                  />
                )}
              </FormControl>
              <TextField
                sx={styles.apatBoxStyle}
                {...register('zipCode')}
                value={watch('zipCode')}
                onChange={(event) => validateZip(event.target.value)}
                label={`${i18next.t(TK.ZIP_LABEL)}*`}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode?.message}
                InputProps={{
                  inputProps: {
                    maxLength: !isCountrycheck && isUS ? ZIP_LENGTH : '',
                    inputMode: !isCountrycheck && isUS ? 'numeric' : 'text',
                  },
                  endAdornment: isLoading && (
                    <InputAdornment position="end">
                      <CircularProgress color="secondary" size={24} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button
            onClick={onCancel}
            sx={styles.shoppingBtnReject}
            variant="outlined"
            color="primary"
          >
            {i18next.t(TKR.CANCEL_TEXT)}
          </Button>
          <Button
            onClick={onConfirm}
            sx={styles.shoppingBtnSelect}
            variant="contained"
            color="primary"
            type="submit"
          >
            {i18next.t(TKR.REWARD_DIALOG_SAVE)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
