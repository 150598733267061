import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';

export const anchorText: ThemedSxProps = () => ({
  fontSize: '14px',
  '& a': {
    color: theme.palette.primary.main,
    fontFamily: fonts.poppins,
    fontWeight: 400,
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const AccordionStyles: ThemedSxProps = () => ({
  border: 'none',
  width: ['45%', '16%', '20%'],
  height: ['40px', 'auto', 'auto'],
  [theme.breakpoints.between(810, 1100)]: {
    width: '27%',
  },
  '&::before': {
    height: '0px',
  },
  '& .MuiAccordionSummary-root': {
    margin: 0,
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
  '& .MuiAccordionDetails-root': {
    margin: 0,
    padding: 0,
    display: 'inline-flex',
  },
  '& .MuiTypography-root': {
    marginBottom: 0,
  },
  '& p': {
    color: colors.primaryBlack,
  },
  '& .MuiTableCell-root': {
    padding: '6px',
  },
});

export const redExpandMoreIcon: ThemedSxProps = () => ({
  color: theme.palette.primary.main,
});

export const plusIcon: ThemedSxProps = () => ({
  display: 'table',
  pt: 1.5,
});

export const shoppingBtnReject: ThemedSxProps = () => ({
  width: ['150px', '294px', '220px'],
  height: ['40px', '48px', '48px'],
});

export const shoppingBtnSelect: ThemedSxProps = () => ({
  width: ['150px', '294px', '220px'],
  height: ['40px', '48px', '48px'],
  marginTop: ['0', '2px', '0'],
});

export const cancelBtnStyle: ThemedSxProps = () => ({
  width: ['146px', '310px', '310px'],
  height: ['40px', '56px', '56px'],
  mr: '2.5',
});

export const saveBtnStyle: ThemedSxProps = () => ({
  width: ['146px', '310px', '310px'],
  height: ['40px', '56px', '56px'],
  marginLeft: '0 !important',
});

export const actions: ThemedSxProps = () => ({
  display: 'flex',
  justifyContent: ['space-between', 'center'],
  alignItems: 'center',
  gap: '20px',
  flexDirection: 'row',
  '&.MuiDialogActions-root>:not(:first-of-type)': {
    ml: [0, 0, 2],
  },
});

export const reminderDialogContainer: SxProps = {
  '& .MuiDialogContent-root': {
    width: ['320px', '640px', '640px'],
    overflow: 'hidden',
    p: 0,
    [theme.breakpoints.between(400, 435)]: {
      width: '360px',
    },
  },
  '& .MuiDialogTitle-root': {
    pb: 0.5,
  },
};

export const reminderTopContainer: SxProps = {
  display: ['block', 'flex', 'flex'],
  marginBottom: '30px',
};

export const inputLeftStyle: SxProps = {
  width: '310px',
  height: '56px',
  mr: 2.5,
  mb: [5, 0, 0],
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
  },
};

export const inputRightStyle: SxProps = {
  width: '310px',
  height: '56px',
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
  },
};

export const reminderNotesStyle: SxProps = {
  mt: 5,
};

export const noRewardStyles: SxProps = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
};

export const noRewardImage: SxProps = {
  padding: '8px',
  borderRadius: '50px',
  opacity: ' 0.5',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  '& > img': {
    height: '48px',
    width: '48px',
  },
};

export const noRewardMessageHeading: SxProps = {
  display: 'flex',
  flexDirection: ['column'],
  alignItems: 'flex-start',
  gap: '4px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  color: '#252525',
  opacity: '50%',
};

export const noRewardMessageHeadingText: SxProps = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '19.68px',
};

export const noRewardMessageHeadingSubText: SxProps = {
  color: colors.primaryBlack,
  fontFamily: fonts.spartan,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '19.68px',
};

export const accountContainer: SxProps = {
  display: ['block', 'flex', 'flex'],
  marginTop: '-14px',
  width: ['325px', 'auto', 'auto'],
  [theme.breakpoints.up('xs')]: {
    mb: 3,
  },
};

export const accountTitle: SxProps = {
  width: ['330px', '455px', 'auto'],
  color: colors.primaryBlack,
  fontFamily: fonts.poppins,
  fontSize: '34px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '124.5%',
  letterSpacing: ' 0.25px',
  textTransform: 'lowercase',
  [theme.breakpoints.up('xs')]: {
    mb: 3,
    width: '330px',
  },
};
export const accountSubTitle: SxProps = {
  color: colors.primaryBlack,
  fontFamily: fonts.poppins,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '150%',
};

export const accountInfoSubContainer: SxProps = {
  marginRight: '50px',
  marginBottom: '10px',
};

export const accountInfoTitle: SxProps = {
  color: colors.placeholderText,
  fontFamily: fonts.poppins,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  letterSpacing: '0.15px',
  marginTop: '5px',
};
export const accountInfoDetails: SxProps = {
  color: colors.placeholderText,
  fontFamily: fonts.poppins,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.15px',
  marginTop: '5px',
};

export const accountGridList: SxProps = {
  display: ['block', 'flex', 'flex'],
  marginLeft: '15px',
  mb: 3,
};

export const addressContainer: SxProps = {
  mt: 2.5,
  mb: 6,
  pb: 2,
  borderBottom: '1px solid #0000001F',
};

export const editAccount: SxProps = {
  height: '50px',
  marginLeft: [0, '70px', '70px'],
  width: ['100%', '120px', '120px'],
  padding: '15px 32px',
  flexDirection: 'column',
  border: `4px solid ${colors.primaryRed}`,
};

export const saveAccount: SxProps = {
  marginTop: '-90px',
  marginLeft: '83%',
  width: '120px',
  height: '56px',
};

export const saveEditAccount: SxProps = {
  marginTop: '-90px',
  marginLeft: '83%',
  width: '112px',
};
export const passwordBtnReject: ThemedSxProps = () => ({
  width: '112px',
  marginTop: ['20px', '0', '0'],
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
});

export const changePassword: SxProps = {
  height: '56px',
  width: ['100%', '200px', '200px'],
  flexDirection: 'column',
  mt: 3,
  border: `4px solid ${colors.primaryRed}`,
};

export const tableCellStyle: SxProps = {
  width: '19%',
  border: 'none',
  marginTop: '13px',
};

export const closeBtnStyle: SxProps = {
  float: 'right',
  mt: -4,
  display: 'table',
  mr: [-2.5, 0, 0],
};

export const formControl: SxProps = {
  width: '100%',
};

export const textLimitField: SxProps = {
  width: ['310px', '100%', '100%'],
  ml: ['5px', 0, 0],
  [theme.breakpoints.between(400, 435)]: {
    width: '360px',
    ml: 0,
  },
  '& .MuiTextField-root': {
    '& .MuiOutlinedInput-root': {
      height: '119px',
    },
  },
};

export const addressBookContainer: SxProps = {
  '& .MuiTableContainer-root': {
    width: ['320px', '60%', '65%'],
    boxShadow: 'none',
    [theme.breakpoints.between(400, 435)]: {
      width: '59%',
    },
    [theme.breakpoints.between(820, 1100)]: {
      width: '79%',
    },
  },
  '& .MuiAccordion-root': {
    width: ['30%', '22%', '23%'],
    [theme.breakpoints.between(820, 1100)]: {
      width: '30%',
    },
  },
};

export const cityStyle: SxProps = {
  width: ['25%', '21%', '23%'],
  marginTop: '13px',
  border: 'none',
  [theme.breakpoints.between(820, 1100)]: {
    width: '28%',
  },
};

export const addressHeadStyle: SxProps = {
  width: ['23%', '22%', '23.3%'],
  [theme.breakpoints.between(820, 1100)]: {
    width: '30%',
  },
  [theme.breakpoints.only('xs')]: {
    paddingLeft: '30px',
  },
};

export const reminderContainerStyle: SxProps = {
  display: ['block', 'flex', 'flex'],
};

export const reminderContainer: SxProps = {
  '& .MuiTableContainer-root': {
    boxShadow: 'none',
  },
};

export const reminderStyle: SxProps = {
  width: '546px',
  [theme.breakpoints.between(820, 1100)]: {
    width: '820px',
  },
};
export const reminderBtnStyle: SxProps = {
  width: ['100%', '194px', '194px'],
  height: '56px',
};

export const addressBookStyle: SxProps = {
  width: '521px',
  [theme.breakpoints.between(820, 1100)]: {
    width: '820px',
  },
};

export const newContactBtnStyle: SxProps = {
  width: ['100%', '219px', '219px'],
  height: '56px',
};

export const alertContainer: SxProps = {
  backgroundColor: 'linear-gradient(0deg, #9BBD52, #9BBD52)',
  height: ['64px', '44px', '44px'],
  padding: '12px 16px',
  mt: 8,
  mb: 4,
};

export const actionColor: SxProps = {
  [theme.breakpoints.only('xs')]: {
    color: '#E10600',
  },
};

export const actionLink: SxProps = {
  fontWeight: '600',
  fontSize: '13px',
  borderBottom: '1px solid #09101D',
  [theme.breakpoints.only('xs')]: {
    marginRight: '205px',
    marginTop: '-10px',
    borderBottom: '1px solid #E10600',
    color: '#E10600',
    [theme.breakpoints.between(400, 435)]: {
      marginRight: '250px',
    },
  },
};

export const tableRowMobileStyle: SxProps = {
  display: 'flex',
  width: '310px',
  paddingBottom: '16px',
  marginBottom: '16px',
  [theme.breakpoints.between(400, 435)]: {
    width: '380px',
  },
};

export const tableMobileStyle: SxProps = {
  display: 'grid',
  width: '30%',
  '& .MuiTableCell-root': {
    textAlign: 'left',
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export const tableMobileContentStyle: SxProps = {
  display: 'grid',
  width: '70%',
  mt: 1.5,
  '& .MuiTableCell-root': {
    textAlign: 'right',
    borderBottom: 'none',
    fontSize: '14px',
    fontWeight: '400',
    padding: '6px',
  },
};

export const mobileContainer: SxProps = {
  '& .lineStyle': {
    borderBottom: '1px solid #C9C9C9',
    mt: 2,
    mb: 2,
  },
};

export const reminderEventStyle: SxProps = {
  width: ['20%', '16%', '20%'],
  [theme.breakpoints.between(820, 1100)]: {
    width: '27%',
  },
};
