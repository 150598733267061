import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { Locale } from 'enums/locale';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import { createSlice } from '@reduxjs/toolkit';
import { fetchArrangement } from 'redux/PDP/arrangement/arrangement.actions';

type State = {
  data: Arrangement;
  status: Status;
  error: ResponseError | null;
};

const initialState: State = {
  data: {
    activeFromDate: null,
    activeToDate: null,
    catalogNumber: 0,
    id: 0,
    groupId: 0,
    products: [],
    ingredients: [],
    reviewsInfo: {
      count: 0,
      rating: 0,
    },
    name: '',
    description: '',
    allergyinformation: '',
    disclaimer: '',
    pageTitle: '',
    couponsApplicable: false,
    culture: Locale.EN_US,
    availability: {
      delivery: false,
      shipment: false,
      pickup: false,
    },
    metaTagDescription: '',
    imageAltTag: '',
    promotionalMessage: '',
    promotionalMessageFG: '',
    nonPromotionalMessage: '',
    nonPromotionalMessageFG: '',
    salePriceFG: '',
    brands: [],
    isOwnBox: false,
    ownBox: {
      addons: [],
      type: OwnBoxTypes.NONE,
    },
  },
  status: Status.INIT,
  error: null,
};

const slice = createSlice({
  name: 'arrangement',
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArrangement.pending, (state) => {
        state.status = Status.PENDING;
      })
      .addCase(fetchArrangement.fulfilled, (state, { payload }) => {
        state.status = Status.FULFILLED;
        state.data = { ...state.data, ...payload };
      })
      .addCase(fetchArrangement.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.error = payload as ResponseError;
      })
      .addDefaultCase((state) => state);
  },
  initialState,
});

export const arrangementReducer = slice.reducer;
export const { reset } = slice.actions;
