import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { OrderConfirmation } from 'redux/order-confirmation/types/types';

class OrderConfirmationApiService extends ApiBaseService {
  find(saleId: number): AxiosPromise<OrderConfirmation> {
    return this.apibase.get<OrderConfirmation>(
      `/order-confirmation/sale-details/${saleId}`,
    );
  }
}
const orderConfirmationApiService = new OrderConfirmationApiService();

export { orderConfirmationApiService };
