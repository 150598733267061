import * as TK from 'components/PDP/Details/components/Reviews/translations/reviews.locale.constants';
import * as styles from 'components/PDP/Details/components/Reviews/style';
import { Box, Rating, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { REVIEWS_LIMIT } from 'components/PDP/Details/components/Reviews/constants';
import { format } from 'date-fns';
import { isPositive } from 'utils/numbers';
import {
  selectReviews,
  selectReviewsInfo,
} from 'components/PDP/Details/components/Reviews/feature/selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18n from 'i18next';

export const Reviews: FC = () => {
  const [totalLeft, setTotalLeft] = useState<number>(0);
  const reviews = useSelector(selectReviews);
  const { count } = useSelector(selectReviewsInfo);

  const hasMore = isPositive(totalLeft);
  const limit = totalLeft >= REVIEWS_LIMIT ? REVIEWS_LIMIT : totalLeft;
  const shownReviewsCount = count - totalLeft;

  const loadMore = () => {
    if (!limit) {
      return;
    }

    setTotalLeft(totalLeft - limit);
  };

  useEffect(() => {
    setTotalLeft(count - REVIEWS_LIMIT);
  }, [count]);

  return (
    <Box sx={styles.wrapper} data-test="pdp-details-reviews">
      <Box width={1}>
        {reviews.slice(0, shownReviewsCount).map((item) => (
          <Box key={item.id} display="flex" flexDirection="column" mb={4}>
            <Rating value={item.rating} sx={{ mb: 1 }} size="small" readOnly />
            <Typography variant="subtitle2">{item.authorName}</Typography>
            <Box mb={1} display="flex" alignItems="center">
              <Typography variant="body3">{item.authorLocation}</Typography>
              <Box sx={styles.divider} />
              <Typography variant="body3">
                {format(new Date(item.createdAt), 'd MMMM, y')}
              </Typography>
            </Box>
            <Typography variant="body2">{item.text}</Typography>
          </Box>
        ))}
      </Box>
      {hasMore && (
        <Box textAlign="center">
          <LoadingButton onClick={loadMore} sx={{ mb: 2 }}>
            {i18n.t(TK.SHOW_MORE, { count: limit })}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};
