import * as styles from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/styles';
import { ArrowButton } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/components/ArrowButton/ArrowButton';
import { ArrowButtonType } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/components/ArrowButton/arrow-button-type.enum';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { DateView } from 'components/PDP/Details/components/PasControl/components/DateSelection/enums/date-selector.enum';
import { LocalizationProvider, PickersDay } from '@mui/lab';
import { StyledCalendar } from 'components/PDP/Details/components/PasControl/components/DateSelection/components/Calendar/styles';
import { add, addMonths, format } from 'date-fns';
import { isDate } from 'utils/date/is-date.guard';
import { isDayView } from 'components/PDP/Details/components/PasControl/components/DateSelection/utils/is-day-view';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { FC, useEffect, useState } from 'react';

const MONTH_BUTTON_FORMAT = 'MMMM';

const MIN_DATE = new Date();
const MAX_DATE = add(MIN_DATE, { years: 10 });

interface Props {
  date: Date | null;
  setDate(date: Date): void;
}

export const Calendar: FC<Props> = ({ date, setDate }) => {
  const [monthPage, setMonthPage] = useState(0);
  const [currentView, setCurrentView] = useState<CalendarPickerView>(
    DateView.DAY,
  );
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledCalendar
        components={{
          LeftArrowButton: ArrowButton,
          RightArrowButton: ArrowButton,
        }}
        componentsProps={{
          leftArrowButton: {
            type: ArrowButtonType.LEFT,
            text: format(
              addMonths(new Date(), monthPage - 1),
              MONTH_BUTTON_FORMAT,
            ),
            onClickAction: () => setMonthPage(monthPage - 1),
          },
          rightArrowButton: {
            type: ArrowButtonType.RIGHT,
            text: format(
              addMonths(new Date(), monthPage + 1),
              MONTH_BUTTON_FORMAT,
            ),
            onClickAction: () => setMonthPage(monthPage + 1),
          },
        }}
        date={selectedDate}
        onYearChange={() => {
          setCurrentView(DateView.MONTH);
        }}
        onMonthChange={() => {
          setCurrentView(DateView.DAY);
        }}
        view={currentView}
        onChange={(newDate) => {
          if (!isDate(newDate)) {
            return;
          }

          setSelectedDate(newDate);
          if (isDayView(currentView)) {
            setDate(newDate);
          }
        }}
        disablePast
        showDaysOutsideCurrentMonth
        onViewChange={(view) => setCurrentView(view)}
        renderDay={(day, selectedDates, props) => (
          <PickersDay
            data-test={`pdp-pas-date-picker-day-${new Date(
              String(day),
            ).getDate()}`}
            {...props}
            disabled={props.disabled || props.outsideCurrentMonth}
            sx={styles.pickersDay}
            selected={
              date
                ? new Date(String(day)).toDateString() === date.toDateString()
                : false
            }
          >
            {(day as Date).getDate()}
          </PickersDay>
        )}
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
      />
    </LocalizationProvider>
  );
};
