import * as TK from 'components/PDP/Details/components/ProductAccordion/components/FAQ/translations/locale.constants';
import { ExpandMore } from '@mui/icons-material';
import { FaqSkeleton } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/FaqSkeleton';
import {
  ProductAccordionDetails,
  ProductAccordionItem,
  ProductAccordionSummary,
} from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { Faq as ProductFaq, faqApiService } from 'api/faq-api.service';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import { RootState } from 'redux/store';
import { Typography } from '@mui/material';
import { answer } from 'components/PDP/Details/components/ProductAccordion/components/FAQ/styles';
import { checkIsBlocked as checkIsArrangementBlocked } from 'redux/PDP/arrangement/arrangement.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const Faq: FC = () => {
  const [faqs, setFaqs] = useState<ProductFaq[]>([]);
  const isArrangementBlocked = useSelector(checkIsArrangementBlocked);
  const arrangementId = useSelector(
    ({ arrangement: { data } }: RootState) => data.id,
  );

  useEffect(() => {
    const fetchFaqs = async () => {
      const res = await faqApiService.getFaq(arrangementId);
      setFaqs(res);
    };
    if (arrangementId && !faqs.length) {
      fetchFaqs();
    }
  }, [arrangementId]);

  return (
    <div>
      <Typography
        variant="h4"
        sx={{ fontWeight: 600, fontSize: 20, mb: 3, mx: [2.5, 0] }}
      >
        {i18next.t(TK.PRODUCT_FAQ)}
      </Typography>
      <div>
        {isArrangementBlocked || !faqs ? (
          <FaqSkeleton />
        ) : (
          faqs.map((item, index) => (
            <ProductAccordionItem key={item.question} sx={{ width: 1 }}>
              <ProductAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`question_${index}_content`}
                id={`question_${index}_header`}
                data-test={`pdp-faq-item-${index + 1}-summary`}
              >
                <Typography
                  variant="subtitle3"
                  fontSize={16}
                  data-test={`pdp-faq-item-${index + 1}-question`}
                >
                  {item.question}
                </Typography>
              </ProductAccordionSummary>
              <ProductAccordionDetails>
                <PureHtmlTypography
                  data-test={`pdp-faq-item-${index + 1}-answer`}
                  component="div"
                  sx={answer}
                  variant="body2"
                >
                  {item.answer}
                </PureHtmlTypography>
              </ProductAccordionDetails>
            </ProductAccordionItem>
          ))
        )}
      </div>
    </div>
  );
};
