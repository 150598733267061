import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  styled,
} from '@mui/material';

export const ProductAccordionWrapper = styled('div')(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('md')]: {
    maxWidth: 610,
  },
}));

export const ProductAccordionItem = styled(Accordion)({
  boxShadow: 'none',
  ':before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
});

export const ProductAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    padding: theme.spacing(3.5, 2.5),
    minHeight: 'unset',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      fontSize: 16,
      lineHeight: '175%',
      fontWeight: 600,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: theme.palette.secondary.main,
      '&.Mui-expanded': {
        color: theme.palette.action.active,
      },
      '& svg': {
        fontSize: '30px',
      },
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

export const ProductAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(4),
    fontSize: 13,
    '& > *:not(.MuiSkeleton-root)': {
      display: 'inline-block',
      lineHeight: '145%',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& b': {
      fontWeight: '600',
    },
    '& ul': {
      listStyleType: 'none',
      marginTop: -5,
    },
  }),
);

export const expandIconSkeleton: SxProps = {
  width: 24,
  height: 24,
  borderRadius: '100%',
};

export const warningWrapper: SxProps = {
  display: 'flex !important',
  flexDirection: 'row',
  alignItems: 'center',
};

export const disclaimerText: SxProps = {
  fontSize: '14px',
};

export const warningCoupanText: SxProps = {
  color: '#F6685E',
  fontFamily: 'spartan',
  fontSize: '14px',
  fontWeight: 600,
};

export const warningIcon: SxProps = {
  color: '#F6685E',
};

export const warningContentWp: SxProps = {
  height: ['100%'],
  display: 'flex !important',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'rgb(255, 246, 234)',
  borderRadius: '16px',
  padding: '12px',
};

export const warningContentText: SxProps = {
  fontFamily: 'spartan',
  fontSize: '14px',
  fontWeight: 600,
  color: 'rgba(0, 0, 0, 0.87',
};

export const warningContentTitle: SxProps = {
  fontFamily: 'spartan',
  fontSize: '14px',
  fontWeight: 600,
};

export const warningContentsubtext: SxProps = {
  fontFamily: 'spartan',
  fontSize: '14px',
  fontWeight: 600,
};

export const warningContentLogo: SxProps = {
  color: '#FF9900',
};
