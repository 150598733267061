import * as TK from 'components/Rewards/translations/locale.constants';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { Box, Typography } from '@mui/material';
import { Element } from 'react-scroll';
import { StoreFinderWidget } from 'components/StoreFinder/interfaces/store-finder-page.interfaces';
import { StoreWidget } from 'components/common/StoreWidget/StoreWidget';
import { StoreWidgetListWrapper } from 'components/StoreFinder/styles';
import { loadLocale } from 'redux/locale/utils';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const MyLocations: FC = () => {
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const [myLocations, setMyLocations] = useState<StoreFinderWidget[]>([]);
  const locale = loadLocale();

  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  useEffect(() => {
    const fetchMyLocations = async () => {
      const area = `${accountInfo[0].area}`;
      const distance = 100;
      const res = await rewardsApiService.getMyLocationList(distance, area);
      setMyLocations(res);
    };
    if (accountInfo.length && accountInfo[0].area) fetchMyLocations();
  }, [accountInfo]);

  const renderStoreWidgets = (): JSX.Element => {
    return (
      <StoreWidgetListWrapper>
        {myLocations.map((widget) => {
          let originalSequenceNumberBasedOnSearch;
          const roundedDistance = Math.round(widget.distance * 10) / 10;

          if (roundedDistance) {
            originalSequenceNumberBasedOnSearch = (
              myLocations.indexOf(widget) + 1
            ).toString();
          }
          return (
            <Box key={widget.storeId} display="inline">
              <Element
                name={widget.storeId.toString()}
                id={widget.storeId.toString()}
              >
                <StoreWidget
                  sequence={originalSequenceNumberBasedOnSearch}
                  storeName={widget.formalName}
                  storeAddress={widget.address}
                  storeHours={widget.timingsShort}
                  storePhoneNumber={widget.phoneNumber}
                  isSmoothieStore={widget.isSmoothieStore}
                  isKosherCertified={widget.isKosherCertified}
                  isCurbsidePickupAvailable={widget.isCurbsidePickupAvailable}
                  distance={roundedDistance}
                  pageFriendlyUrl={widget.pageFriendlyUrl}
                  locale={locale}
                  directionsMapAddress={widget.mapAddress}
                />
              </Element>
            </Box>
          );
        })}
      </StoreWidgetListWrapper>
    );
  };

  return (
    <>
      {(!myLocations || myLocations.length === 0) && (
        <Typography
          variant="subtitle2"
          textAlign="center"
          fontWeight="500"
          mb={2}
        >
          {i18next.t(TK.NO_LOCATION_MSG)}
        </Typography>
      )}
      {myLocations.length > 0 && (
        <Box display="flex">{renderStoreWidgets()}</Box>
      )}
    </>
  );
};
