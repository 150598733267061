import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'components/PDP/Details/components/OwnBox/translations/own-box.locale.constants';
import * as styles from 'components/PDP/Details/components/OwnBox/styles';
import { ARRANGEMENT_TITLE_ELEMENT } from 'components/PDP/Details/constants';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { BottomContinuePanel } from 'components/PDP/Details/containers/BottomContinuePanel/BottomContinuePanel';
import {
  Box,
  Grid,
  Step,
  StepContent,
  Stepper,
  Typography,
} from '@mui/material';
import { CarouselControl } from 'components/PDP/Details/components/OwnBox/CarouselControl';
import { ContinueButton } from 'components/PDP/Details/components/ContinueButton/ContinueButton';
import { ContinueButtonSkeleton } from 'components/PDP/Details/components/ContinueButton/ContinueButtonSkeleton';
import { DessertBoardPreviewImage } from 'components/PDP/Details/components/OwnBox/DessertBoardPreviewImage';
import { HideUpMd, useIsUpMd } from 'utils/hidden/hide-up-md';
import { KeyConst } from 'components/Paypal/enums/key.enum';
import { LimitAlert } from 'components/PDP/Details/components/OwnBox/LimitAlert/LimitAlert';
import { LockAlert } from 'components/Session/Alert/LockAlert';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { OwnBoxAddonItem } from 'components/PDP/Details/components/OwnBox/OwnBoxAddonItem/OwnBoxAddonItem';
import { OwnBoxTypes } from 'enums/own-box-types.enum';
import {
  PayLaterMessage,
  calculatePayLaterAmount,
} from 'components/Paypal/PayLaterMessage';
import { PaypalSkeleton } from 'components/Paypal/PaypalSkeleton';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductOptionsSkeleton } from 'components/PDP/Details/components/ProductOptions/components/ProductOptionsSkeleton';
import { Quantity } from 'components/PDP/Details/components/ProductOptions/components/Quantity/Quantity';
import { QuantityButtonSkeleton } from 'components/PDP/Details/components/ProductOptions/components/Quantity/QuantityButtonSkeleton';
import { ROOT_CONTAINER_ID } from 'utils/root';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { StepSummary } from 'components/PDP/Details/components/OwnBox/StepSummary/StepSummary';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { ViewAvailableProductsAlert } from 'components/PDP/Details/components/ProductOptions/components/ViewAvailableProductsAlert/ViewAvailableProductsAlert';
import { analytics } from 'service/adobe-analytics/analytics';
import { animateScroll, scroller } from 'react-scroll';
import {
  checkIsBlocked,
  selectOwnBox,
} from 'redux/PDP/arrangement/arrangement.selectors';
import {
  cookieBoxSettings,
  settings,
} from 'components/PDP/Details/components/OwnBox/settings';
import {
  cookieEverydayStep,
  cookieLimitedStep,
  notSelectedStep,
} from 'components/PDP/Details/components/PasControl/contants/steps';
import { getOwnBoxOptions } from 'components/PDP/Details/components/OwnBox/utils/get-own-box-options';
import { isEmpty } from 'utils/is-empty';
import { isSelected } from 'components/PDP/Details/components/OwnBox/utils/is-selected';
import {
  payPalEnabled,
  payPalVersion,
} from 'redux/settings/settings.selectors';
import {
  selectIsAreaError,
  selectIsAreaPending,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.selectors';
import { selectIsLoading } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import { toggleAddon } from 'components/PDP/Details/components/OwnBox/utils/toggle-addon';
import { updateQuantity } from 'components/PDP/Details/components/OwnBox/utils/update-quantity';
import { useInView } from 'react-intersection-observer';
import { useOnlyXs } from 'utils/hidden/use-only-xs';
import { useSelector } from 'react-redux';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import React, { FC, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

const CAROUSEL_MIN_ITEMS = 8;

type Props = {
  setOwnBoxAddons: (addons: UpsellItem[]) => void;
  unavailable?: boolean;
  product: Product | null;
  selectedOwnBoxAddons: UpsellItem[];
  quantity: number;
  setQuantity: (d: number) => void;
  onContinue: (event?: React.MouseEvent) => void;
  focusedAddonId: number;
  onAnimationEnd: () => void;
  productUnavailable?: boolean;
  hidePasAlert: () => void;
  isCookie12?: boolean;
};

export const OwnBox: FC<Props> = ({
  setOwnBoxAddons,
  unavailable,
  quantity,
  setQuantity,
  product,
  onContinue,
  selectedOwnBoxAddons,
  focusedAddonId,
  onAnimationEnd,
  productUnavailable,
  hidePasAlert,
  isCookie12,
}) => {
  const isPayPalEnabled = useSelector(payPalEnabled);
  const isPayPalVersion = useSelector(payPalVersion);

  const ownBox = useSelector(selectOwnBox);
  const isAreaError = useSelector(selectIsAreaError);
  const isAreaLoading = useSelector(selectIsAreaPending);
  const ownBoxPending = useSelector(checkIsBlocked);
  const isAvailabilityLoading = useSelector(selectIsLoading);

  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [addons] = useState<OwnBoxAddon[]>([...ownBox.addons]);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);

  const isLoading = ownBoxPending || isAvailabilityLoading;
  const isDisabled = isLoading || isAreaLoading;
  /* TODO: move outside of the product options after old PAS is removed */
  const isContinueBlocked = isDisabled || isAreaError;
  const { ref: continueButtonRef, inView: isMainContinueButtonInView } =
    useInView();

  const { ref: ownBoxContainerRef, inView: isOwnBoxContainerInView } =
    useInView({
      triggerOnce: false,
      threshold: 1,
      trackVisibility: true,
      delay: 1000,
    });

  const { inView: isOwnBoxMobileGridInView, entry: ownBoxMobileGridEntry } =
    useInView({
      triggerOnce: false,
      threshold: 1,
      trackVisibility: true,
      delay: 500,
    });

  const sliderRef = useRef<Slider | null>(null);

  const isMobile = useOnlyXs();
  const isTabletDesktop = useIsUpMd();
  const boxOptions = getOwnBoxOptions(ownBox.type);
  const cookieOptionLimit = isCookie12 ? 6 : boxOptions?.optionsLimit;
  const isSelectionLimit = selectedOwnBoxAddons.length === cookieOptionLimit;

  const showContent = !unavailable && !isLoading;
  const cookieLimitedName = 'Cookie Box - LTO';
  const everydayCookies = selectedOwnBoxAddons.filter(
    (addon) => !addon.option.optionName?.includes(cookieLimitedName),
  );
  const limitedCookies = selectedOwnBoxAddons.filter((addon) =>
    addon.option.optionName?.includes(cookieLimitedName),
  );

  const showPayLaterMessage =
    showContent &&
    isPayPalEnabled &&
    isPayPalVersion === KeyConst.PaypalVersion;

  const scrollToOwnBoxVertical = () => {
    if (!isOwnBoxContainerInView && isTabletDesktop) {
      scroller.scrollTo(ARRANGEMENT_TITLE_ELEMENT, {
        containerId: ROOT_CONTAINER_ID,
        smooth: 'true',
      });
    } else if (!isOwnBoxMobileGridInView && !isTabletDesktop) {
      const ownBoxScrollOffsetHeight =
        (ownBoxMobileGridEntry?.boundingClientRect.bottom || 0) +
        (ownBoxMobileGridEntry?.boundingClientRect.height || 0) -
        (ownBoxMobileGridEntry?.rootBounds?.height || 0);
      animateScroll.scrollTo(ownBoxScrollOffsetHeight, {
        containerId: ROOT_CONTAINER_ID,
      });
    }
  };

  const scrollToOwnBoxItemHorizontal = (focusedItemId: number) => {
    const index = addons.findIndex((item) => item.id === focusedItemId);
    if (index !== -1) {
      sliderRef.current?.slickGoTo(Math.floor(index / 2), false);
    }
  };

  useEffect(() => {
    if (focusedAddonId && !isOwnBoxContainerInView) {
      scrollToOwnBoxVertical();
    }
    if (isOwnBoxContainerInView && !isMobile) {
      scrollToOwnBoxItemHorizontal(focusedAddonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons, isTabletDesktop, focusedAddonId, isOwnBoxContainerInView]);

  useEffect(() => {
    if (isAlertShown && isEmpty(selectedOwnBoxAddons)) {
      setIsAlertShown(false);
    }
  }, [isAlertShown, selectedOwnBoxAddons]);

  const handleClickOwnBoxAddon = (
    ownBoxAddon: OwnBoxAddon,
    index: number,
    event?: React.MouseEvent,
  ): void => {
    event?.stopPropagation();
    hidePasAlert();

    const isOwnBoxAddonSelected = isSelected(selectedOwnBoxAddons, ownBoxAddon);
    if (
      everydayCookies.length === 3 &&
      ownBox.type === OwnBoxTypes.COOKIE_BOX &&
      !ownBoxAddon.optionName?.includes(cookieLimitedName) &&
      !isOwnBoxAddonSelected
    ) {
      setIsAlertShown(true);
      return;
    }
    if (
      limitedCookies.length === 3 &&
      ownBox.type === OwnBoxTypes.COOKIE_BOX &&
      ownBoxAddon.optionName?.includes(cookieLimitedName) &&
      !isOwnBoxAddonSelected
    ) {
      setIsAlertShown(true);
      return;
    }
    if (isSelectionLimit && !isOwnBoxAddonSelected) {
      setIsAlertShown(true);
      return;
    }

    if (isSelectionLimit && isOwnBoxAddonSelected) {
      setIsAlertShown(false);
    }

    setOwnBoxAddons(
      updateQuantity(
        toggleAddon(selectedOwnBoxAddons, ownBoxAddon),
        boxOptions,
        isCookie12,
      ),
    );

    analytics.cyoSelection(
      ownBoxAddon?.optionName,
      ownBoxAddon?.name,
      index + 1,
    );
  };

  const checkIsDisabled = (addon: OwnBoxAddon): boolean => {
    if (isSelectionLimit) {
      return !isSelected(selectedOwnBoxAddons, addon);
    }

    return Boolean(productUnavailable);
  };

  const handleContinue = (event?: React.MouseEvent) => {
    if (isAlertShown) {
      setIsAlertShown(false);
    }
    onContinue(event);
  };
  const [activeStepperStep, setActiveStepperStep] =
    useState<number>(cookieEverydayStep);
  const [lastStepperStep, setLastStepperStep] =
    useState<number>(cookieEverydayStep);
  const everydayCookiesImgArr: string[] = [];
  const limitedCookiesImgArr: string[] = [];
  const [everydayCookiesImage, setEverydayCookiesImage] = useState<string[]>(
    [],
  );
  const [limitedCookiesImage, setLimitedCookiesImage] = useState<string[]>([]);

  const [isShowText, setIsShowText] = useState<boolean>(false);

  const toggleActiveStepperStep = (step: number) => {
    if (step === activeStepperStep) {
      setActiveStepperStep(notSelectedStep);
      return;
    }
    setActiveStepperStep(step);
  };

  useEffect(() => {
    everydayCookies.map((item) => {
      return everydayCookiesImgArr.push(item.option.image);
    });
    limitedCookies.map((item) => {
      return limitedCookiesImgArr.push(item.option.image);
    });

    setLastStepperStep(1);
    setLastStepperStep(2);
    setEverydayCookiesImage(everydayCookiesImgArr);
    setLimitedCookiesImage(limitedCookiesImgArr);

    if (everydayCookies.length === 0) {
      setEverydayCookiesImage([]);
    }
    if (everydayCookies.length === 0 && limitedCookies.length === 0) {
      setActiveStepperStep(cookieEverydayStep);
      setLastStepperStep(cookieEverydayStep);
    }
    if (limitedCookies.length === 0) {
      setLimitedCookiesImage([]);
    }

    if (
      everydayCookies.length === 3 &&
      activeStepperStep <= cookieEverydayStep
    ) {
      setActiveStepperStep(1);
      setIsShowText(false);
    }
    if (
      limitedCookies.length === 3 &&
      activeStepperStep === cookieLimitedStep
    ) {
      setActiveStepperStep(2);
      setIsShowText(true);
    }
  }, [selectedOwnBoxAddons]);

  const cookieBoxAddonItem = () => (
    <Box mt={[1, 3, 3]} width="100%" height="100%">
      <Stepper orientation="vertical" connector={null}>
        <Step
          active={activeStepperStep === cookieEverydayStep}
          completed={activeStepperStep > cookieEverydayStep}
          disabled={lastStepperStep < cookieEverydayStep}
        >
          <Box sx={styles.borderStyle} mb={2}>
            <StepSummary
              availabilityStep={AvailabilityStep.OWN_COOKIE_BOX_EVERYDAY}
              setActiveStep={toggleActiveStepperStep}
              clickable={lastStepperStep >= cookieEverydayStep}
              preview={
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <DessertBoardPreviewImage
                    imgPreview={
                      activeStepperStep !== cookieEverydayStep
                        ? everydayCookiesImage
                        : []
                    }
                  />
                </Box>
              }
            >
              <Box sx={styles.description} ref={descriptionRef}>
                <Typography variant="subtitle1" fontWeight="500">
                  {boxOptions?.title}
                </Typography>
              </Box>
            </StepSummary>
            <StepContent sx={styles.contentStyle}>
              <Box sx={styles.cookieCarousel}>
                <Typography variant="body2" sx={styles.subtitleStyle}>
                  {i18next.t(TK.OWN_BOX_COOKIE_BOX_SUBTITLE)}
                </Typography>
                <Slider
                  {...cookieBoxSettings}
                  ref={sliderRef}
                  rows={
                    addons.filter(
                      (addon) => !addon.optionName?.includes(cookieLimitedName),
                    ).length > 4
                      ? 2
                      : 1
                  }
                  swipe={!focusedAddonId}
                  nextArrow={
                    <CarouselControl
                      data-test="pdp-details-own-box-next-button"
                      disabled={!!focusedAddonId}
                    >
                      <ArrowCircleRightSharpIcon fontSize="medium" />
                    </CarouselControl>
                  }
                  prevArrow={
                    <CarouselControl
                      data-test="pdp-details-own-box-prev-button"
                      disabled={!!focusedAddonId}
                    >
                      <ArrowCircleLeftSharpIcon fontSize="medium" />
                    </CarouselControl>
                  }
                >
                  {addons
                    .filter(
                      (addon) => !addon.optionName?.includes(cookieLimitedName),
                    )
                    .map((addon, index) => (
                      <OwnBoxAddonItem
                        key={addon.id}
                        onSelect={() => handleClickOwnBoxAddon(addon, index)}
                        addon={addon}
                        selected={isSelected(selectedOwnBoxAddons, addon)}
                        disabled={checkIsDisabled(addon) || isDisabled}
                        focused={focusedAddonId === addon.id}
                        onAnimationEnd={onAnimationEnd}
                        isContainerInView={isOwnBoxContainerInView}
                      />
                    ))}
                </Slider>
              </Box>
            </StepContent>
          </Box>
        </Step>
        <Step
          active={activeStepperStep === cookieLimitedStep}
          completed={activeStepperStep > cookieLimitedStep}
          disabled={lastStepperStep < cookieLimitedStep}
        >
          <Box sx={styles.borderStyle} mb={2}>
            <StepSummary
              availabilityStep={AvailabilityStep.OWN_COOKIE_BOX_LIMITED}
              setActiveStep={toggleActiveStepperStep}
              clickable={lastStepperStep >= cookieLimitedStep}
              preview={
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <DessertBoardPreviewImage
                    imgPreview={
                      activeStepperStep !== cookieLimitedStep
                        ? limitedCookiesImage
                        : []
                    }
                  />
                </Box>
              }
            >
              <Box sx={styles.description} ref={descriptionRef}>
                <Typography variant="subtitle1" width="100%" fontWeight="500">
                  {i18next.t(TK.OWN_BOX_COOKIE_BOX_LIMITED_TITLE)}
                </Typography>
              </Box>
            </StepSummary>
            <StepContent sx={styles.contentStyle}>
              <Typography
                variant="body2"
                sx={styles.subtitleStyle}
                mt={['-45px', '-25px', '-25px']}
              >
                {i18next.t(TK.OWN_BOX_COOKIE_BOX_LIMITED_SUBTITLE)}
              </Typography>
              <Box sx={styles.cookieCarousel}>
                <Slider
                  {...cookieBoxSettings}
                  rows={
                    addons.filter((addon) =>
                      addon.optionName?.includes(cookieLimitedName),
                    ).length > 4
                      ? 2
                      : 1
                  }
                  ref={sliderRef}
                  swipe={!focusedAddonId}
                  nextArrow={
                    <CarouselControl
                      data-test="pdp-details-own-box-next-button"
                      disabled={!!focusedAddonId}
                    >
                      <ArrowCircleRightSharpIcon fontSize="medium" />
                    </CarouselControl>
                  }
                  prevArrow={
                    <CarouselControl
                      data-test="pdp-details-own-box-prev-button"
                      disabled={!!focusedAddonId}
                    >
                      <ArrowCircleLeftSharpIcon fontSize="medium" />
                    </CarouselControl>
                  }
                >
                  {addons
                    .filter((addon) =>
                      addon.optionName?.includes(cookieLimitedName),
                    )
                    .map((addon, index) => (
                      <OwnBoxAddonItem
                        key={addon.id}
                        onSelect={() => handleClickOwnBoxAddon(addon, index)}
                        addon={addon}
                        selected={isSelected(selectedOwnBoxAddons, addon)}
                        disabled={checkIsDisabled(addon) || isDisabled}
                        focused={focusedAddonId === addon.id}
                        onAnimationEnd={onAnimationEnd}
                        isContainerInView={isOwnBoxContainerInView}
                        limitedOnly={
                          ownBox.type === OwnBoxTypes.COOKIE_BOX &&
                          addon.optionName?.includes(cookieLimitedName)
                        }
                      />
                    ))}
                </Slider>
              </Box>
            </StepContent>
          </Box>
        </Step>
        {isShowText && (
          <Typography variant="body2" sx={styles.selectionText}>
            {i18next.t(TK.OWN_BOX_SUBTITLE)}
          </Typography>
        )}
      </Stepper>
    </Box>
  );
  const ownBoxAddonItems = () =>
    addons.map((addon, index) => (
      <OwnBoxAddonItem
        key={addon.id}
        onSelect={() => handleClickOwnBoxAddon(addon, index)}
        addon={addon}
        selected={isSelected(selectedOwnBoxAddons, addon)}
        disabled={checkIsDisabled(addon) || isDisabled}
        focused={focusedAddonId === addon.id}
        onAnimationEnd={onAnimationEnd}
        isContainerInView={isOwnBoxContainerInView}
        limitedOnly={
          ownBox.type === OwnBoxTypes.COOKIE_BOX &&
          addon.optionName?.includes(cookieLimitedName)
        }
      />
    ));

  return (
    <>
      <LimitAlert
        hideAlert={() => setIsAlertShown(false)}
        isShown={isAlertShown}
      />
      <Box sx={styles.wrapper} ref={ownBoxContainerRef}>
        {showContent && (
          <>
            {!isCookie12 && (
              <Box sx={styles.description} ref={descriptionRef}>
                {ownBox.type === 4 ? (
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    sx={{ mb: 1, mt: 2 }}
                  >
                    {i18next.t(TK.OWN_BOX_6_COOKIE_BOX_TITLE)}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    {boxOptions?.title}
                  </Typography>
                )}
                {ownBox.type === 4 ? (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {i18next.t(TK.OWN_BOX_6_COOKIE_BOX_SUBTITLE)}
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {i18next.t(TK.OWN_BOX_SUBTITLE)}
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
        {isLoading && isEmpty(addons) ? (
          <ProductOptionsSkeleton />
        ) : (
          <>
            {isCookie12 ? (
              cookieBoxAddonItem()
            ) : (
              <>
                {addons.length <= CAROUSEL_MIN_ITEMS ? (
                  <Grid container spacing={1} sx={styles.grid}>
                    {ownBoxAddonItems()}
                  </Grid>
                ) : (
                  <Box sx={styles.carousel}>
                    <Slider
                      {...settings}
                      rows={2}
                      ref={sliderRef}
                      swipe={!focusedAddonId}
                      nextArrow={
                        <CarouselControl
                          data-test="pdp-details-own-box-next-button"
                          disabled={!!focusedAddonId}
                        >
                          <ArrowCircleRightSharpIcon fontSize="medium" />
                        </CarouselControl>
                      }
                      prevArrow={
                        <CarouselControl
                          data-test="pdp-details-own-box-prev-button"
                          disabled={!!focusedAddonId}
                        >
                          <ArrowCircleLeftSharpIcon fontSize="medium" />
                        </CarouselControl>
                      }
                    >
                      {ownBoxAddonItems()}
                    </Slider>
                    <HideUpMd>
                      <Box sx={{ mt: 3 }}>
                        <LockAlert />
                      </Box>
                    </HideUpMd>
                  </Box>
                )}
              </>
            )}
          </>
        )}
        <>
          {showPayLaterMessage && product ? (
            <Box mt={3}>
              <PayLaterMessage
                amount={calculatePayLaterAmount(product.price, quantity)}
              />
            </Box>
          ) : (
            <PaypalSkeleton />
          )}
          <Box
            mt={3}
            mx={[2.5, 0]}
            display="flex"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
          >
            <Box width={1}>
              {showContent && product ? (
                <Quantity
                  quantity={quantity}
                  setQuantity={setQuantity}
                  disabled={isDisabled}
                  selectedProduct={product}
                />
              ) : (
                <QuantityButtonSkeleton />
              )}
            </Box>
            <Box width={1} ml={[0, 2]}>
              {showContent ? (
                <ContinueButton
                  ref={continueButtonRef}
                  onClick={handleContinue}
                  disabled={isContinueBlocked}
                  dataTest="pdp-details-own-box-continue-button"
                />
              ) : (
                <ContinueButtonSkeleton ref={continueButtonRef} />
              )}
            </Box>
          </Box>
        </>
        {productUnavailable && (
          <Box mt={2}>
            <ViewAvailableProductsAlert />
          </Box>
        )}
        <ShowUpSm>
          <BottomContinuePanel
            selectedVariantName={product?.name ?? ''}
            quantity={quantity}
            onContinue={handleContinue}
            isBlocked={isContinueBlocked}
            reveal={!isMainContinueButtonInView}
          />
        </ShowUpSm>
      </Box>
    </>
  );
};
