import * as icon from 'utils/cloudinaryAssets';
import { OrderStatus } from 'api/order/types';
import { SxProps } from '@mui/material';
import { isStatus } from 'utils/order-status';
import { isUS } from 'utils/country';
import { orderStatusDate } from 'utils/date';
import { tracking } from 'components/CustomerService/translations/locale.constants';

type TrackingOptions = {
  icon: string;
  text: string;
  disabled: boolean;
  sx?: SxProps;
  statusText?: string;
  divider?: boolean;
};

export const callCenterNumber = '678-992-2390';

export const retrieveGenericStatuses = (
  status: OrderStatus[],
): TrackingOptions[] => {
  const orderPlacedStatus = status.find((s) => s.status === 0);
  return [
    {
      icon: icon.orderPlaced,
      text: tracking.ORDER_PLACED,
      disabled: !isStatus(status, 0),
      statusText: orderPlacedStatus
        ? orderStatusDate(orderPlacedStatus.date)
        : '',
    },
    {
      icon: icon.storeReceived,
      text: tracking.RECEIVED_BY_STORE,
      disabled: !isStatus(status, 1),
    },
    {
      icon: icon.craftingOrder,
      text: tracking.CRAFTING_ORDER,
      disabled: !isStatus(status, 2),
    },
  ];
};

export const fedExTrackingUrl = (trackingNumber = ''): string =>
  `https://www.fedex.com/apps/fedextrack/index.html?cntry_code=${
    isUS ? 'us' : 'ca'
  }&tracknumbers=${trackingNumber}`;

export const addOnUrl = (img: string): string =>
  `https://resources.ediblearrangements.com/resources/en-us/i/a/addon/${img}`;

export const bannerUrl = (img: string): string =>
  `https://resources.ediblearrangements.com/resources/en-us/i/b/${img}`;

export const productUrl = (img: string): string =>
  `https://resources.ediblearrangements.com/resources/en-us/i/a/${img}`;

export const orderInquiryLink = (orderNumber = ''): string =>
  `/customer-service/contact-us?isCancel=false&orderNumber=${orderNumber}`;

export const cancelOrderLink = (orderNumber = ''): string =>
  `/customer-service/contact-us?isCancel=true&orderNumber=${orderNumber}`;
