import * as TK from 'containers/RewardsContainer/translations/locale.constants';

export const rewardsCommonLocale = {
  [TK.BILLING_INFORMATION_TITLE]: 'Billing Information',
  [TK.STREET_ADDRESS]: 'Street Address',
  [TK.CONTACT_DIALOG_TITLE]: 'Contact Information',
  [TK.ADDRESS_BOOK_TITLE]: 'Address',
  [TK.ADDRESS_TITLE]: 'Title',
  [TK.ADDRESS_TYPE_LABEL]: 'Address Type',
  [TK.APT_FLOOR_SUITE_BUTTON]: 'Apt/Floor/Suite',
  [TK.APT_FLOOR_SUITE_LABEL]: 'Apt/Floor/Suite (optional)',
  [TK.CHOOSE_OPTION]: 'Please Choose',
  [TK.COUNTRY_LABEL]: 'Country',
  [TK.CITY_LABEL]: 'City/Town',
  [TK.ZIP_LABEL]: 'Zip Code',
  [TK.STATE_LABEL]: 'State/Province',
  [TK.PHONE_NUMBER]: 'Phone Number*',
  [TK.EMAIL_ADDRESS]: 'Email Address*',
  [TK.REWARDS_BIRTHDAY]: 'Birthday',
  [TK.REWARDS_BIRTH_DATE]: 'Day',
  [TK.REWARDS_BIRTH_DAY]: 'Day',
  [TK.REWARDS_BIRTH_DAY_REQUIRED]: 'Enter birth day',
  [TK.REWARDS_BIRTH_MONTH]: 'Month',
  [TK.REWARDS_BIRTH_MONTH_REQUIRED]: 'Enter birth month',
  [TK.REWARDS_BIRTH_YEAR]: 'Year (Optional)',
  [TK.REWARDS_BIRTH_YEAR_PLACEHOLDER]: 'Year',
  [TK.PO_REFERENCE_LABEL]: 'P.O. or Other Reference #',
  [TK.REWARD_TITLE]: 'Become an Edible Rewards® Member Today!',
  [TK.REWARD_SUBTITLE]: "You'll Enjoy",
  [TK.REWARD_LIST1]: '$5 off with sign up',
  [TK.REWARD_LIST2]: 'Free gifts',
  [TK.REWARD_LIST3]: 'Fast checkout',
  [TK.REWARD_LIST4]: 'Gift reminders',
  [TK.REWARD_LIST5]: 'In-store offers',
  [TK.REWARD_LIST6]: 'Birthday rewards',
  [TK.REWARD_LIST7]: 'and more',
  [TK.REWARD_BENEFITS]:
    'Check the box below to start enjoying these benefits today.',
  [TK.REWARD_MEMBER]: 'Become an Edible Rewards® member!',
  [TK.REWARD_MEMBER_CHECKBOX]:
    'By checking this box, you agree to the Edible Rewards® {{termsOfUse}}',
  [TK.REWARD_SIGNUP_LABEL]:
    'Sign up to receive our emails for the best deals, exclusive promotions, and more!',
  [TK.PLACE_ORDER]: 'Place Order',
  [TK.SUBMITTING]: 'Please wait, while we process your payment',
  [TK.PLACE_ORDER_TERMS_CONDITIONS]:
    'By clicking "Place Order", I agree to the {{termsOfUse}} and the {{productsPolicy}}.',
  [TK.ERROR_ENTER_FIRST_NAME]: 'Enter a valid first name',
  [TK.ERROR_ENTER_LAST_NAME]: 'Enter a valid last name',
  [TK.ERROR_ENTER_STREET]: 'Enter a valid street address',
  [TK.ERROR_ENTER_ADDRESS_TYPE]: 'Enter a valid address type',
  [TK.ERROR_ENTER_ZIP_CODE]: 'Enter a valid zip/postal code',
  [TK.ERROR_ENTER_CITY]: 'Enter a valid city/town',
  [TK.ERROR_SELECT_STATE]: 'Select a state/province',
  [TK.ERROR_ENTER_STATE]: 'Enter a valid state/province',
  [TK.ERROR_ENTER_COUNTRY]: 'Select a country',
  [TK.ERROR_ENTER_PHONE]: 'Enter a valid phone number',
  [TK.ERROR_ENTER_PASSWORD]: 'Enter a valid password',
  [TK.ERROR_PASSWORD_INVALID]:
    'Password must contain at least 1 special character(!@#$%^&*)',
  [TK.ERROR_PASSWORD_MINIMUM]: 'Password must contain at least 8 characters',
  [TK.ERROR_VERIFY_PASSWORD_MATCH]: 'Passwords must match',
  [TK.CANT_BEGIN_WITH_O_OR_1]:
    "Phone Number can't begin with '0' or '1'. Please provide a 10-digit phone number, starting with the area code.",
  [TK.ERROR_ENTER_EMAIL]: 'Enter a valid email address',
  [TK.VENMO_CANCELLED]: 'App is not available or user aborted payment flow',
  [TK.VENMO_APP_CANCELLED]: 'User canceled payment flow',
  [TK.CART_COUNT_MULTI]: 'Checkout: {{productCount}} Items',
  [TK.CART_COUNT_SINGLE]: 'Checkout: {{productCount}} Item',
  [TK.RECIPIENT_COUNT_MULTI]: '{{recipientCount}} Recipients',
  [TK.RECIPIENT_COUNT_SINGLE]: '{{recipientCount}} Recipient',
  [TK.RETURN_TO_CART]: 'Return to Cart',
  [TK.VIEW_CART_SUMMARY]: 'View Cart Summary',
  [TK.LOG_IN]: 'Log In',
  [TK.ACCESS_ADDRESS_BOOK]: 'to access your address book.',
  [TK.ADDRESS_BOOK]: 'address book',
  [TK.SAME_AS]: ' Same as ',
  [TK.LOGIN_OR]: ' Or, ',
  [TK.ORDER_TOTAL]: 'Order Total: ',
  [TK.CREATE_YOUR_PASSWORD]: 'Create your password',
  [TK.PASSWORD_LABEL]: 'Password*',
  [TK.CONFIRM_PASSWORD_LABEL]: 'Confirm Password*',
  [TK.EMAIL_ALREADY_EXIST]: 'This email is tied to an existing account',
  [TK.PASSWORD_STRENGTH]: 'Strength',
  [TK.EMAIL_ALREADY_EXIST_TITLE]: 'There was an issue creating your account.',
  [TK.LOG_IN_BUTTON_BEFORE_TEXT]:
    'The email you entered above is associated with an existing account. ',
  [TK.LOG_IN_BUTTON_TEXT]: 'Log in',
  [TK.LOG_IN_BUTTON_AFTER_TEXT]:
    'to your existing account, or enter a new email address to create an account.',
  [TK.APPROVE_WITH_ID]:
    'There seems to be a problem with your payment. Please try again shortly.',
  [TK.CALL_ISSUER]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.CARD_NOT_SUPPORTED]:
    'This type of payment is not supported by your card. Please try a different card or contact your bank for more information.',
  [TK.CARD_VELOCITY_EXCEEDED]:
    'Your purchase exceeds your card spending limit. Please contact your bank to adjust your limit or try a different payment method.',
  [TK.CURRENCY_NOT_SUPPORTED]:
    ' your card does not work with the currency we use. Please try again with a different payment method?',
  [TK.DO_NOT_HONOR]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.DO_NOT_TRY_AGAIN]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.DUPLICATE_TRANSACTION]:
    'There was a problem with your payment. Please contact us for assistance.',
  [TK.EXPIRED_CARD]:
    'Your card seems to be expired. Please update your payment information with a valid card.',
  [TK.FRAUDULENT]:
    'There was a problem with your payment. Please contact us for assistance. Error Code: F000',
  [TK.GENERIC_DECLINE]:
    'There was a problem with your payment. Please contact us for assistance.',
  [TK.INCORRECT_NUMBER]:
    'We could not find a card matching that information. Please double-check your card number and try again.',
  [TK.INCORRECT_CVC]:
    'The security code (CVC) you entered does not match your card. Please check the code on the back of your card and try again.',
  [TK.INCORRECT_PIN]:
    'The pin you entered does not match your card pin. Please double-check your card pin and try again.',
  [TK.INCORRECT_ZIP]:
    'There seems to be a problem with your billing zip code. Please double-check your information and try again.',
  [TK.INSUFFICIENT_FUNDS]:
    'There are not enough funds available on your card to complete this purchase. Please try a different card or contact your bank for more information.',
  [TK.INVALID_ACCOUNT]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.INVALID_AMOUNT]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.INVALID_CVC]:
    'The security code (CVC) you entered does not match your card. Please check the code on the back of your card and try again.',
  [TK.INVALID_EXPIRY_MONTH]:
    'The expiration month you entered does not match your card. Please double-check the date on your card and try again',
  [TK.INVALID_EXPIRY_YEAR]:
    'The expiration year you entered does not match your card. Please double-check the year on your card and try again',
  [TK.INVALID_NUMBER]:
    'We could not find a card matching that information. Please double-check your card number and try again.',
  [TK.INVALID_PIN]: 'The PIN you entered is incorrect. Please try again. ',
  [TK.ISSUER_NOT_AVAILABLE]:
    'There seems to be a problem with your payment. Please try again shortly',
  [TK.LOST_CARD]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.MERCHANT_BLACKLIST]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.NEW_ACCOUNT_INFORMATION_AVAILABLE]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.NO_ACTION_TAKEN]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.NOT_PERMITTED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.OFFLINE_PIN_REQUIRED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.ONLINE_OR_OFFLINE_PIN_REQUIRED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.PICKUP_CARD]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.PIN_TRY_EXCEEDED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.PROCESSING_ERROR]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.REENTER_TRANSACTION]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.RESTRICTED_CARD]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.REVOCATION_OF_ALL_AUTHORIZATIONS]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.REVOCATION_OF_AUTHORIZATION]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.SECURITY_VIOLATION]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.SERVICE_NOT_ALLOWED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.STOLEN_CARD]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.TESTMODE_DECLINE]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.TRANSACTION_NOT_ALLOWED]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.TRY_AGAIN_LATER]:
    'Your bank declined your payment. Please contact your bank for more information.',
  [TK.WITHDRAWAL_COUNT_LIMIT_EXCEEDED]:
    'We are unable to process your payment at this time. Please try again with a different payment method',
  [TK.ACCOUNT_TITLE]: 'My contact information',
  [TK.ACCOUNT_MY_ADDRESS]: 'My address',
};
