import * as TK from 'containers/RewardsContainer/translations/locale.constants';
import * as yup from 'yup';
import i18next from 'i18next';

const validChars = /^((?![<>]).)*$/g;
const nameRegExp = /^[a-z ,.'-]+$/i;
const birthMonthRegExp = new RegExp(/^(1[0-2]|[1-9])$/);

export const AccountInformationFormSchema = yup.object().shape({
  id: yup.number(),
  title: yup.string(),
  firstName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_ENTER_FIRST_NAME))
    .required(i18next.t(TK.ERROR_ENTER_FIRST_NAME)),
  lastName: yup
    .string()
    .matches(nameRegExp, i18next.t(TK.ERROR_ENTER_LAST_NAME))
    .required(i18next.t(TK.ERROR_ENTER_LAST_NAME)),
  birthDay: yup.string().required(i18next.t(TK.REWARDS_BIRTH_DAY_REQUIRED)),
  birthMonth: yup
    .string()
    .matches(birthMonthRegExp, i18next.t(TK.REWARDS_BIRTH_MONTH_REQUIRED))
    .required(i18next.t(TK.REWARDS_BIRTH_MONTH_REQUIRED)),
  birthYear: yup.string().notRequired(),
  address1: yup.string().matches(validChars, i18next.t(TK.ERROR_ENTER_STREET)),
  address2: yup.string(),
  addressType: yup.number(),
  city: yup.string(),
  state: yup.string(),
  otherState: yup.string().when('state', {
    is: 'Others',
    then: yup
      .string()
      .trim()
      .matches(validChars, i18next.t(TK.ERROR_ENTER_STATE)),
  }),
  country: yup.string(),
  zipCode: yup.string(),
  phone: yup
    .string()
    .min(10, `${i18next.t(TK.ERROR_ENTER_PHONE)}.`)
    .required(`${i18next.t(TK.ERROR_ENTER_PHONE)}.`)
    .test(
      'phone',
      i18next.t(TK.CANT_BEGIN_WITH_O_OR_1),
      (value) => !value || (!value.startsWith('1') && !value.startsWith('0')),
    ),
  email: yup
    .string()
    .email(i18next.t(TK.ERROR_ENTER_EMAIL))
    .required(i18next.t(TK.ERROR_ENTER_EMAIL)),
});
