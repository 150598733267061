import * as styles from 'components/common/StoreWidget/styles';
import { InfoOutlined } from '@mui/icons-material';
import { SpecialStoreHoursAlert } from 'components/common/StoreWidget/styles';
import { Typography } from '@mui/material';
import { formatMonthDateYear } from 'utils/date';
import React, { FC } from 'react';

interface Props {
  bulkHoursOpeningTime: string | null;
  bulkHoursClosingTime: string | null;
  occasionOpeningTime: string | null;
  occasionClosingTime: string | null;
  occasionTitle: string | null;
  occasionHoursApplicableDate: Date | undefined;
}
export const SpecialHours: FC<Props> = ({
  bulkHoursClosingTime,
  bulkHoursOpeningTime,
  occasionOpeningTime,
  occasionClosingTime,
  occasionTitle,
  occasionHoursApplicableDate,
}) => {
  const displayBulkStoreHours =
    !!bulkHoursOpeningTime && !!bulkHoursClosingTime;

  const displayOccasionStoreHours =
    !!occasionOpeningTime &&
    !!occasionClosingTime &&
    !!occasionTitle &&
    !!occasionHoursApplicableDate;

  const monthDayYearDateBulkHours = formatMonthDateYear(new Date());
  const monthDayYearDateOccasionHoursApplicableDate =
    occasionHoursApplicableDate
      ? formatMonthDateYear(new Date(occasionHoursApplicableDate))
      : null;
  return (
    <SpecialStoreHoursAlert severity="warning" icon={<InfoOutlined />}>
      {displayBulkStoreHours ? (
        <Typography
          sx={displayOccasionStoreHours ? styles.topSpecialStoreHoursText : {}}
        >{`Today's Store Hours - ${monthDayYearDateBulkHours}: ${bulkHoursOpeningTime!}-${bulkHoursClosingTime!}`}</Typography>
      ) : null}
      {displayOccasionStoreHours &&
      !!monthDayYearDateOccasionHoursApplicableDate ? (
        <Typography>
          {`${occasionTitle!} Hours - ${monthDayYearDateOccasionHoursApplicableDate}: ${occasionOpeningTime!}-${occasionClosingTime!}`}
        </Typography>
      ) : null}
    </SpecialStoreHoursAlert>
  );
};
