import {
  CUSTOMER_SERVICE_FAQ_PAGE,
  CUSTOMER_SERVICE_META_TITLE_TAG,
  CUSTOMER_SERVICE_PAGE,
  HOME,
  allergySafetyInformationPage,
  certifiedHappinessPage,
  sale,
  trackOrder,
  tracking,
} from 'components/CustomerService/translations/locale.constants';

export const trackYourOrderCommonLocale = {
  [trackOrder.TITLE]: 'Track Your Order',
  [trackOrder.HELPER_TEXT]:
    'To track the current status of your order, please enter your order number',
  [trackOrder.ORDER_NUMBER_LABEL_TEXT]: 'Order Number',
  [trackOrder.BTN_TEXT]: 'TRACK ORDER',
  [trackOrder.ORDER_NUMBER_VALID_ERROR]:
    'The Order Number you entered is invalid',
  [trackOrder.ORDER_NUMBER_REQUIRED_ERROR]: 'Order Number Required',
  [trackOrder.ORDER_NUMBER_OVER_ERROR]:
    'Order Number must be at most 12 characters',
  [trackOrder.ORDER_NUMBER_UNDER_ERROR]:
    'Order Number must be at least 10 characters',
  [trackOrder.ORDER_NOT_FOUND_TITLE]: 'Order Not Found',
  [trackOrder.ORDER_NOT_FOUND_ERROR]:
    'No record of this tracking number can be found at this time, please check the number and try again later. For further assistance, please contact',
  [trackOrder.CUSTOMER_SERVICE]: 'Customer Service',
  [trackOrder.TOOLTIP_TEXT]:
    'Your order number can be found within the Order Confirmation email you received from us.',
  [trackOrder.EXAMPLE_ORDER_NUMBER]: 'e.g. W3121611323-1',
};

export const trackingStatusCommonLocale = {
  [tracking.TITLE]: 'Tracking Status',
  [tracking.NUM]: 'Order Number: {{#}}',
  [tracking.ORDER_PLACED]: 'Order Placed',
  [tracking.RECEIVED_BY_STORE]: 'Received by store',
  [tracking.CRAFTING_ORDER]: 'Crafting your order',
  [tracking.OUT_FOR_DELIVERY]: 'Out for delivery',
  [tracking.DELIVERED]: 'Delivered',
  [tracking.ATTEMPTED_DELIVERY]: 'Attempted Delivery',
  [tracking.READY_FOR_PICKUP]: 'Order ready for pickup',
  [tracking.PICKED_UP]: 'Order picked up',
  [tracking.SHIPPED]: 'Order has been shipped',
  [tracking.CANCELED]: 'Your order has been canceled on {{Date}}',
  [tracking.CANCELED_SUBTITLE]:
    'If this is an error, please contact our customer support team.',
  [tracking.CALL_STORE]: 'Call Store: {{StoreNumber}}',
  [tracking.CUSTOMER_SUPPORT]: 'Customer Support: {{SupportNumber}}',
  [tracking.CANCEL_ORDER]: 'Cancel Order',
  [tracking.ORDER_INQUIRY]: 'Order Inquiry',
  [tracking.TRACK_ORDER]: 'Track Order: {{TrackingNumber}}',
  [tracking.TRACK_ORDER_ROUTE_INFO]:
    'Your Edible driver is currently {{info}} stop away',
  [tracking.TRACK_ORDER_ROUTE_INFO_MULTI_STOP]:
    'Your Edible driver is currently {{info}} stops away',
  [tracking.TRACK_ORDER_ROUTE_INFO_NEXT_STOP]:
    'Your delivery is just around the corner!',
};

export const orderDetailsCommonLocale = {
  [sale.TITLE]: 'Order Details',
  [sale.ITEM_COUNT]: '{{Number}} Items:',
  [sale.QTY]: '{{#}} x  {{Price}}',
  [sale.ADDON_QTY]: 'x{{#}}',
  [sale.PRICE]: '{{Price}}',
  [sale.SUMMARY]: 'Order Summary',
  [sale.DELIVERY_FEE]: 'Hand-Delivery Charge:',
  [sale.SERVICE_FEE]: 'Service Fee:',
  [sale.SALES_TAX]: 'Sales Tax ({{Rate}}%):',
  [sale.SUBTOTAL]: 'Subtotal:',
  [sale.TOTAL]: 'Total:',
  [sale.MADE_IT_SPECIAL]: 'You Made it Special With:',
  [sale.SELECTED_UPGRADES]: 'You Selected These Upgrades',
  [sale.BUNDLE_SPECIAL_TREATS]:
    'Bundle containing {{bundleCount}} special treats',
  [sale.CYOA_TITLE1]: 'Box with',
  [sale.CYOA_TITLE2]: 'fruit/topping ',
  [sale.CYODB_TITLE]: 'Board with baked goods and dipped fruit/berries',
  [sale.CYODB_NAME]: 'Create Your Own Desserts Board',
  [sale.CYO_6COOKIEBOX_TITLE]: 'Box with up to 3 cookie combinations',
  [sale.CYO_6COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 6 Cookies',
  [sale.CYO_12COOKIEBOX_TITLE]: 'Box with up to 6 cookie combinations',
  [sale.CYO_12COOKIEBOX_NAME]: 'Create Your Own Cookie Box - 12 Cookies',
  [sale.CYO_DESSERT_NAME]: 'Create Your Own Desserts Box - One Size',
  [sale.CYO_DIPPEDFRUIT_NAME]: 'Create Your Own Dipped Fruit Box',
  [sale.FREE_TEXT]: 'Free',
};

export const customerServiceCommonLocale = {
  [HOME]: 'Home',
  [CUSTOMER_SERVICE_PAGE]: 'Customer Service',
  [CUSTOMER_SERVICE_META_TITLE_TAG]:
    'Edible Arrangements Customer Service | {{pageName}}',
  [CUSTOMER_SERVICE_FAQ_PAGE]: 'Frequently Asked Questions',
};

export const customerServiceCertifiedHappinessLocale = {
  [certifiedHappinessPage.TITLE]: 'Certified Happiness',
  [certifiedHappinessPage.DESCRIPTION]:
    ' is our commitment to quality and customer service, from every Edible Arrangements® store owner and all of the Fruit Experts® in our system.',
  [certifiedHappinessPage.POLICY1]: 'The purpose of the ',
  [certifiedHappinessPage.POLICY2]:
    ' policy is to ensure our guests and gift recipients are satisfied with the service and quality they receive from us each and every time.',
};

export const customerServiceAllergySafetyInformationLocale = {
  [allergySafetyInformationPage.TITLE]:
    'At Edible Arrangements It Is Our Mission To Wow You With Every Fruit Arrangement And Dipped Fruit Box.',
  [allergySafetyInformationPage.DESCRIPTION1]:
    'We know that not everyone can enjoy our full range of product offerings due to possible food allergies. This page will provide you with some additional information regarding possible allergen contamination and ingredients used in the production of our standard products, based on product information provided by our approved food manufacturers. This page does not include allergen information about limited time offers, test products, or regional items as ingredients vary.',
  [allergySafetyInformationPage.DESCRIPTION2]:
    'Edible Arrangements® products are made from fresh fruit with no added preservatives. (Our toppings may contain some preservatives).  Please enjoy immediately, or refrigerate within 4 hours to ensure quality.  Fruit Arrangements are typically made with a combination of strawberries, apples, melons, pineapples, bananas, oranges and other seasonal fruit.  If you have allergies to any fruits, we recommend that you review the ingredients posted on our product pages. Color swizzle may include a non AZO food additive. See fruit expert.',
  [allergySafetyInformationPage.DESCRIPTION3]:
    'Allergen warning: Edible Arrangements® products contain, or may have come in contact with, tree nuts (almonds, hazelnuts, coconuts, and pistachios), peanuts, wheat, eggs, wheat, milk, and soy. We cannot promise that a gift will not have come in contact with nuts, nut oil, or another allergen. We recommend that you take the necessary precautions based on any related allergies.',
  [allergySafetyInformationPage.SUBTITLE_ONE]: 'Nuts',
  [allergySafetyInformationPage.SUBDESCRIPTION_ONE]:
    'Our stores produce products that contain nuts including almonds, pistachios, coconuts, and hazelnuts.  Some other toppings may be processed in a facility that process other tree nuts, as well as peanuts.',
  [allergySafetyInformationPage.SUBTITLE_TWO]: 'Milk and Soy',
  [allergySafetyInformationPage.SUBDESCRIPTION_TWO]:
    'Many of our toppings including our semi-sweet and white chocolate contain soy and milk. We also sell products containing yogurt, and our stores use equipment that may have come in contact with soy and milk products. We recommend that you take the necessary precautions based on any food allergies, and ask our Fruit Experts® about any possible equipment exposure to soy and milk.',
  [allergySafetyInformationPage.SUBTITLE_THREE]: 'Gluten',
  [allergySafetyInformationPage.SUBDESCRIPTION_THREE]:
    'Some of our toppings, cookies and crumbles may have gluten-containing ingredients (wheat, barley, rye). Further some toppings may be processed in a facility that process wheat, barley, or rye or some combination thereof. Therefore, products are not certified gluten-free and amounts of gluten and wheat may be present. Again, we recommend you take the necessary precautions based on any food allergies.',
  [allergySafetyInformationPage.SUBTITLE_FOUR]: 'Egg',
  [allergySafetyInformationPage.SUBDESCRIPTION_FOUR]:
    'Although, Edible Arrangements fruit bouquets and chocolate dipped fruit products do not contain egg, our toppings may have been manufactured in a facility that may also manufacture products containing egg based products. In addition, our cookies, cheesecake and toppings may contain eggs.',
  [allergySafetyInformationPage.SUBTITLE_FIVE]:
    'Dipped Fruit Cross Contamination Warning',
  [allergySafetyInformationPage.SUBDESCRIPTION_FIVE]:
    'Our process for dipping our fruit in our chocolate, may cause some cross contamination in the preparation of those fruit products.  The fruit that could be affected are typically, strawberries, pineapple, granny smith apples, bananas, oranges, and our seasonal chocolate dipped fruit offerings.  We recommend you take necessary precautions based upon any food allergies and ask our Fruit Experts® about any possible exposure in the dipping process.',
  [allergySafetyInformationPage.SUBTITLE_SIX]: 'Important Safety Warning',
  [allergySafetyInformationPage.SUBDESCRIPTION_SIX]:
    'Arrangements are made with skewers that may have sharp points. Carefully remove fruit with utensil and dispose of skewers immediately. Keep skewers away from small children. Containers are intended for display only and are not toys to be played with by children.  For safety, transport arrangements in a secure place away from passengers and never allow a passenger to hold an arrangement while vehicle is in motion.',
  [allergySafetyInformationPage.SUBTITLE_SEVEN]: 'Cookies',
  [allergySafetyInformationPage.COOKIES_PLEASE]: 'Please ',
  [allergySafetyInformationPage.COOKIES_CLICK_HERE]: 'click here',
  [allergySafetyInformationPage.SUBDESCRIPTION_SEVEN]:
    ' for nutritional information on our cookie products.',
};
