import * as TK from 'components/PDP/Details/components/ProductOptions/translations/product-options.locale.constants';
import * as styles from 'components/PDP/Details/components/ProductOptions/components/ProductOption/product-option.styles';
import { Badge, Card, Collapse, Grid, Typography } from '@mui/material';
import { Image } from 'components/common/Image/Image';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductOptionInformation } from 'components/PDP/Details/components/ProductOptions/components/ProductOption/product-option.styles';
import { addCurrency } from 'utils/add-currency';
import { oneHourDeliveryChip } from 'utils/cloudinaryAssets';
import { updateAssetUrls } from 'utils/assets-url-builder';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  product: Product;
  onSelect(product: Product): void;
  selected?: boolean;
  imageAltTag?: string;
  unavailable?: boolean;
  disabled?: boolean;
  dataTest: string;
  salePriceFG: string;
};

export const ProductOption: FC<Props> = ({
  product,
  onSelect,
  selected,
  imageAltTag,
  unavailable,
  disabled,
  dataTest,
  salePriceFG,
}) => (
  <Grid item xs={12} sm={6} data-test={dataTest}>
    <Card
      tabIndex={0}
      role="radio"
      aria-checked={selected}
      onClick={() => !unavailable && !disabled && onSelect(product)}
      sx={styles.productOptionsItem({
        active: selected,
        disabled: unavailable || disabled,
      })}
    >
      <Image
        src={updateAssetUrls(product.image)}
        alt={imageAltTag}
        errorFallback={product.image}
        sx={styles.getImageSx({ disabled: unavailable || disabled })}
      />
      <ProductOptionInformation>
        {product.isOneHourDelivery && (
          <Image src={oneHourDeliveryChip} sx={styles.oneHourDelivery} />
        )}
        <Typography variant="subtitle2" component="p">
          {product.name}
        </Typography>
        <Collapse in={selected && unavailable}>
          <Badge
            color="primary"
            sx={styles.badge}
            badgeContent={i18next.t(TK.UNAVAILABLE)}
          />
        </Collapse>
        <div>
          {product.regularPrice ? (
            <Typography
              component="span"
              variant="body2"
              sx={styles.regularPrice}
            >
              {addCurrency(product.regularPrice.toFixed(2))}
            </Typography>
          ) : null}
          <Typography
            component="span"
            variant="subtitle2"
            sx={styles.salePrice(product.regularPrice ? salePriceFG : '')}
          >
            {addCurrency(product.price.toFixed(2))}
          </Typography>
        </div>
      </ProductOptionInformation>
    </Card>
  </Grid>
);
