import * as styles from 'components/Home/SecondaryBanner/styles';
import { Box } from '@mui/material';
import { ComponentProps } from 'components/Home/types';
import { RenderWhenInView } from 'components/common/RenderWhenInView/RenderWhenInView';
import {
  SecondaryBannerContent,
  SecondaryBannerItem,
} from 'components/Home/SecondaryBanner/types';
import { SecondaryBannerLink } from 'components/Home/SecondaryBanner/components/secondary-banner-link';
import { SecondaryBannerSkeleton } from 'components/Home/SecondaryBanner/components/Skeleton';
import {
  addHomePageWidgetClickEventToCookies,
  addWidgetClickEventToCookies,
} from 'utils/cookies-storage';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkForFrench } from 'components/Home/utils';
import { getId } from 'components/Home/utils/get-html-id';
import { plpGetComponents } from 'components/PLP/feature/selectors';
import { useCmsWidgets } from 'hooks/use-get-cms-widget-component';
import { useImagePreloader } from 'hooks/use-image-preloader';
import { useResponsiveContent } from 'hooks/use-responsive-content';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

export const SecondaryBanner: FC<ComponentProps> = ({
  type,
  id,
  sequence,
  name,
}) => {
  const componentData = useCmsWidgets(type, id);
  const secondaryBannerContent = useResponsiveContent<SecondaryBannerContent>(
    componentData,
    'md',
  );
  const isPlpPageActive = useSelector(plpGetComponents);
  const hasValidSrcAttribute = (item: SecondaryBannerItem) =>
    'src' in item && item.src.trim().length > 0;

  const validSecondaryBannerContent = secondaryBannerContent.items.filter(
    (item) => hasValidSrcAttribute(item),
  );

  const { imagesPreloaded } = useImagePreloader(secondaryBannerContent.items);

  const handleAnalyticsClick = () => {
    const clickEvent = {
      zone: ((sequence ?? 0) + 1).toString(),
      position: '0',
      module_type: type,
      module_name: name,
    };
    analytics.homePageClick(clickEvent);
    if (isPlpPageActive) {
      addWidgetClickEventToCookies(clickEvent);
    } else {
      addHomePageWidgetClickEventToCookies(clickEvent);
    }
  };

  if (!imagesPreloaded) {
    return <SecondaryBannerSkeleton />;
  }

  if (
    !secondaryBannerContent.available ||
    !validSecondaryBannerContent.length
  ) {
    return null;
  }

  return (
    <RenderWhenInView>
      <section
        id={getId(type, id)}
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Box
          sx={
            validSecondaryBannerContent.length > 1
              ? styles.twoBannerContainer
              : styles.defaultBannerContainerStyles
          }
        >
          {validSecondaryBannerContent.map((secondaryBannerItem) => {
            return (
              <SecondaryBannerLink
                key={checkForFrench(
                  secondaryBannerItem.src,
                  secondaryBannerItem.frSrc,
                )}
                secondaryBannerItemUrl={checkForFrench(
                  secondaryBannerItem.url,
                  secondaryBannerItem.frUrl,
                )}
                secondaryBannerItemSrc={checkForFrench(
                  secondaryBannerItem.src,
                  secondaryBannerItem.frSrc,
                )}
                secondaryBannerItemName={secondaryBannerItem.bannerName}
                secondaryBannerItemAltText={checkForFrench(
                  secondaryBannerItem.altText,
                  secondaryBannerItem.frAltText,
                )}
                analyticsClickHandler={handleAnalyticsClick}
              />
            );
          })}
        </Box>
      </section>
    </RenderWhenInView>
  );
};
