import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';

export type Faq = {
  arrangementId: number;
  question: string;
  answer: string;
  culture: string;
  sequence: number;
};

export type FaqEntity = {
  id: number;
  faqCategoryId: number;
  question: string;
  answer: string;
  source: number;
  culture: string;
  sequence: number;
};

export type FaqCategory = {
  ID: number;
  Name: string;
};

class FaqApiService extends ApiBaseService {
  async getFaq(arrangementId: number): Promise<Faq[]> {
    const {
      data: { faq },
    } = await this.apibase.get<{ faq: Faq[] }>(`/faq/${arrangementId}`);
    return faq;
  }

  getFaqCategory(type: number): AxiosPromise<FaqCategory[]> {
    return this.apibase.get<FaqCategory[]>('/faq/categories', {
      params: { type },
    });
  }

  getFaqs(source: number, faqCategoryId?: number): AxiosPromise<FaqEntity[]> {
    const categoryId = faqCategoryId || -1;
    return this.apibase.get<FaqEntity[]>(
      `/faq?source=${source}&categoryId=${categoryId}`,
    );
  }
}

const faqApiService = new FaqApiService();

export { faqApiService };
