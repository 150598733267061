import { ApiBaseService } from 'api/apibase.service';
import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { ArrangementPreview, ArrangementsParams } from 'api/arrangements/types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { dateToSQLDateTimeString } from 'utils/date';

export const ALL_ARRANGEMENTS_FILTER_VALUE = '5';
export const VIEW_ALL_ARRANGEMENTS_FILTER_VALUE = '635';

class ArrangementApiService extends ApiBaseService {
  getArrangements(params: {
    pageFriendlyUrl?: string;
    limit?: number;
  }): Promise<Arrangement[]> {
    return this.apibase
      .get<Arrangement[]>(`/arrangement`, { params })
      .then(({ data }) => data);
  }

  getProductDetails(
    arrangementId: number,
    storeId: number,
  ): Promise<Arrangement[]> {
    return this.apibase
      .get<Arrangement[]>(
        `/arrangement/product-details/${arrangementId}/${storeId}`,
      )
      .then(({ data }) => data);
  }

  find(
    { date, ...params }: ArrangementsParams,
    config: AxiosRequestConfig = {},
  ): AxiosPromise<ArrangementPreview[]> {
    const formattedDate = date ? dateToSQLDateTimeString(date) : undefined;

    return this.apibase.get('/arrangements', {
      ...config,
      params: {
        ...params,
        date: formattedDate,
      },
    });
  }

  getArrangementsOneHourDelivery(): Promise<number[]> {
    return this.apibase
      .get<number[]>('/products/one-hour-delivery')
      .then(({ data }) => data);
  }
}

const arrangementApiService = new ArrangementApiService();

export { arrangementApiService };
