export const TITLE = 'cart.title';
export const CONTINUE_SHOPPING = 'cart.continue_shopping';
export const START_SHOPPING = 'cart.start_shopping';
export const SUGGESTED_PRODUCTS = 'cart.suggested_products';
export const SUGGESTED_PRODUCTS_EMPTY = 'cart.suggested_products_empty';
export const SHOP_NOW = 'cart.shop_now';
export const VIEW_ALL_PRODUCTS = 'cart.view_all_products';
export const NEED_HELP = 'cart.need_help';
export const CHANGE_SELECTION = 'cart.change_selection';
export const PRICE_EA = 'cart.price_ea';
export const SHOP_POPULAR_GIFTS = 'Shop Popular Gifts';
export const WANT_TO_SHOP_LABEL = 'want to start shopping?';
