import { ApiBaseService } from 'api/apibase.service';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { AxiosPromise } from 'axios';
import { formatSQLDate } from 'utils/get-sql-date-format';

export class AvailabilityOnDateApiService extends ApiBaseService {
  getDates(
    areaId: number,
    arrangementId: number,
    date: Date,
  ): AxiosPromise<AvailabilityOnDate> {
    return this.apibase.get('availability/on-date', {
      params: {
        areaId,
        arrangementId,
        date: formatSQLDate(date),
        debug: new URL(window.location.href).searchParams.get('debug-flex'),
      },
    });
  }
}

export const availabilityOnDateService = new AvailabilityOnDateApiService();
