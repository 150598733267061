import * as TK from 'components/PDP/Details/components/ProductAccordion/translations/constants';
import * as styles from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { Arrangement } from 'redux/PDP/arrangement/types/arrangement.types';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { Reviews as BvReviews } from 'lib/bazaarvoice/components/Reviews';
import { DescriptionSkeleton } from 'components/PDP/Details/components/ProductAccordion/components/DescriptionSkeleton';
import { Element } from 'react-scroll';
import { ErrorBoundary } from 'react-error-boundary';
import { ExpandMore, Warning } from '@mui/icons-material';
import { Ingredients } from 'components/PDP/Details/components/Ingredients/Ingredients';
import {
  ProductAccordionDetails,
  ProductAccordionItem,
  ProductAccordionSummary,
  ProductAccordionWrapper,
} from 'components/PDP/Details/components/ProductAccordion/product-accordion.styles';
import { Reviews } from 'components/PDP/Details/components/Reviews/Reviews';
import { ReviewsRating } from 'components/PDP/Details/components/Reviews/ReviewsRating';
import { StarRating } from 'lib/bazaarvoice/components/StarRating/StarRating';
import { appInsights } from 'utils/telemetry/app-insights';
import { descriptionDetails } from 'components/PDP/Details/components/ProductAccordion/styles';
import { isNotEmpty } from 'utils/array/size';
import { segment } from 'service/segment/segment';
import { selectAreReviewsExist } from 'components/PDP/Details/components/Reviews/feature/selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useAccordionReviewsTitle } from 'components/PDP/Details/components/Reviews/use-accordion-reviews-title';
import { useIsBvEnabled } from 'lib/bazaarvoice/hooks/use-is-bv-and-c8-enabled';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import React, { FC } from 'react';
import i18next from 'i18next';
import parse from 'html-react-parser';

type Props = {
  loading?: boolean;
  arrangement: Arrangement;
  reviewsOpen: boolean;
  setReviewsOpen: (open: boolean) => void;
};

export const ProductAccordion: FC<Props> = ({
  loading = false,
  arrangement,
  reviewsOpen,
  setReviewsOpen,
}) => {
  const areReviewsExist = useSelector(selectAreReviewsExist);
  const isBvEnabled = useIsBvEnabled();
  const reviewsAccordionTitle = useAccordionReviewsTitle();
  const parsedAllergyInformation = arrangement.allergyinformation
    ? parse(arrangement.allergyinformation.replace(/\n/g, '<br />'))
    : '';

  const parsedDisclaimer = arrangement.disclaimer
    ? parse(arrangement.disclaimer.replace(/\n/g, '<br />'))
    : '';

  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const ExpandIcon = loading ? (
    <Skeleton variant="circular" sx={styles.expandIconSkeleton} />
  ) : (
    <ExpandMore />
  );

  return (
    <ProductAccordionWrapper>
      <Divider />
      <ProductAccordionItem disableGutters square defaultExpanded>
        <ProductAccordionSummary
          expandIcon={ExpandIcon}
          disabled={loading}
          aria-controls="description-panel-content"
          id="description-panel-header"
        >
          {i18next.t(TK.PRODUCT_DESCRIPTION)}
        </ProductAccordionSummary>
        <ProductAccordionDetails sx={descriptionDetails}>
          {loading ? <DescriptionSkeleton /> : parse(arrangement.description)}
          {arrangement.disclaimer && (
            <>
              <Box mt={2} sx={styles.warningWrapper}>
                <InfoIcon />

                <Typography ml={1} sx={styles.disclaimerText}>
                  {i18next.t(TK.DISCLAIMER_TITLE)}
                  {parsedDisclaimer}
                </Typography>
              </Box>
            </>
          )}
          {!arrangement.couponsApplicable && (
            <>
              <Box mt={2} sx={styles.warningWrapper}>
                <InfoIcon sx={styles.warningIcon} />

                <Typography ml={1} sx={styles.warningCoupanText}>
                  {i18next.t(TK.COUPONS_APPLICABLE_TITLE)}
                </Typography>
              </Box>
            </>
          )}
          {!!arrangement.allergyinformation && (
            <Box mt={2} sx={styles.warningContentWp}>
              <Warning sx={styles.warningContentLogo} />
              <Typography ml={1} sx={styles.warningContentText}>
                <span style={{ fontWeight: '600' }}>
                  {i18next.t(TK.ALLERGY_WARNING_TITLE)}
                </span>
                {parsedAllergyInformation}
              </Typography>
            </Box>
          )}
        </ProductAccordionDetails>
      </ProductAccordionItem>
      {isNotEmpty(arrangement.ingredients) && (
        <>
          <Divider />
          <ProductAccordionItem disableGutters square>
            <ProductAccordionSummary
              expandIcon={ExpandIcon}
              aria-controls="ingredients-panel-content"
              id="ingredients-panel-header"
              disabled={loading}
            >
              {i18next.t(TK.PRODUCT_INGREDIENTS)}
            </ProductAccordionSummary>
            <ProductAccordionDetails>
              <Ingredients ingredients={arrangement.ingredients} />
            </ProductAccordionDetails>
          </ProductAccordionItem>
        </>
      )}
      <Divider />
      <Element name="reviews" />
      <ProductAccordionItem
        expanded={reviewsOpen}
        onChange={() => {
          setReviewsOpen(!reviewsOpen);
          if (!reviewsOpen)
            segment.productReviewsExpanded(
              arrangement,
              userSession,
              userAccountInformation,
            );
        }}
        disableGutters
        square
      >
        <ProductAccordionSummary
          expandIcon={isBvEnabled || areReviewsExist ? ExpandIcon : null}
          disabled={!isBvEnabled && !areReviewsExist}
          data-test="pdp-reviews-mobile"
          aria-controls="reviews-panel"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" component="p" mr={1}>
              {reviewsAccordionTitle}
            </Typography>
            {isBvEnabled ? (
              <ErrorBoundary
                onError={(error) =>
                  appInsights.trackEvent(
                    { name: 'BazaarVoice Star Rating Error' },
                    error,
                  )
                }
                FallbackComponent={ReviewsRating}
              >
                <StarRating productId={arrangement.catalogNumber} hideButton />
              </ErrorBoundary>
            ) : (
              <ReviewsRating />
            )}
          </Box>
        </ProductAccordionSummary>
        {reviewsOpen && (
          <ProductAccordionDetails>
            {isBvEnabled ? (
              <BvReviews productId={arrangement.catalogNumber} />
            ) : (
              <Reviews />
            )}
          </ProductAccordionDetails>
        )}
      </ProductAccordionItem>
      <Divider />
    </ProductAccordionWrapper>
  );
};
