import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked, isFulfilled } from 'utils/status.comparer';

export const checkIsUnavailableBySchedule = createSelector(
  ({ arrangement: { data } }: RootState) => ({
    from: data.activeFromDate,
    to: data.activeToDate,
  }),
  ({ from, to }) => {
    if (!from && !to) {
      return false;
    }

    const now = new Date();

    const isAfter = !from || new Date(from) < now;
    const isBefore = !to || new Date(to) > now;
    const betweenFromAndTo = isAfter && isBefore;
    return !betweenFromAndTo;
  },
);

export const selectCatalogNumber = ({
  arrangement: {
    data: { catalogNumber },
  },
}: RootState) => catalogNumber;

export const getArrangementId = ({
  arrangement: {
    data: { id },
  },
}: RootState) => id;

export const checkIsBlocked = createSelector(
  ({ arrangement: a }: RootState) => a.status,
  (status) => isBlocked(status),
);

export const selectArrangement = ({ arrangement: { data } }: RootState) => data;
export const selectArrangementName = ({ arrangement: { data } }: RootState) =>
  data.name;
export const selectProducts = ({ arrangement: { data } }: RootState) =>
  data?.products || [];
export const selectProductsIds = (state: RootState) =>
  selectProducts(state).map(({ id }) => id);
export const selectAlt = ({ arrangement: { data } }: RootState) =>
  data?.imageAltTag || '';
export const selectArrangementInlinePAS = ({
  arrangement: { data },
}: RootState) => Boolean(data?.inlinePAS);

export const selectArrangementErrorStatus = ({
  arrangement: { error },
}: RootState) => error?.status;

export const selectArrangementFulfilled = (state: RootState) =>
  isFulfilled(state.arrangement.status);

export const selectIsOwnBox = (state: RootState) =>
  state.arrangement.data.isOwnBox;

export const selectOwnBox = (state: RootState) => state.arrangement.data.ownBox;
