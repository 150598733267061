import * as TK from 'components/common/StickyAlert/translations/locale.constants';
import { Alert, IconButton, Snackbar, Typography } from '@mui/material';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { DessertBoardType } from 'api/products/types';
import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { analytics } from 'service/adobe-analytics/analytics';
import { selectArrangement } from 'redux/PDP/arrangement/arrangement.selectors';
import { snackbar } from 'components/common/StickyAlert/styles';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  isShown: boolean;
  hideAlert: () => void;
  availabilityStep: AvailabilityStep;
  selectedOwnBoxAddons: UpsellItem[];
  clickOwnBoxAddon: OwnBoxAddon[];
  isCookie12?: boolean;
}

const HIDING_TIME_MILLIS = 8000;

const getAlertText = (step: AvailabilityStep): string =>
  ({
    [AvailabilityStep.NOT_SELECTED]: '',
    [AvailabilityStep.OWN_BOX_ADDONS]: TK.ALERT_OWN_BOX_ADDONS,
    [AvailabilityStep.PRODUCT_OPTIONS]: TK.ALERT_PRODUCT_OPTION,
    [AvailabilityStep.ZIP_AVAILABILITY]: TK.ALERT_AVAILABILITY_ZIP,
    [AvailabilityStep.DATE_SELECTION]: TK.ALERT_AVAILABILITY_DATE,
    [AvailabilityStep.DELIVERY_METHOD_SELECTION]:
      TK.ALERT_AVAILABILITY_DELIVERY_METHOD,
    [AvailabilityStep.STORE_LIST_SELECTION]: TK.ALERT_AVAILABILITY_STORE,
    [AvailabilityStep.SUGGESTED_DATE_SELECTION]: TK.ALERT_SUGGESTED_DATE,
    [AvailabilityStep.SUGGESTED_PRODUCT_SELECTION]: TK.ALERT_SUGGESTED_PRODUCT,
    [AvailabilityStep.CONTINUE]: '',
    [AvailabilityStep.OWN_DESSERT_BOARD_BAKED]: TK.OWN_DESSERT_BOARD_BAKED,
    [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_BERRIES]:
      TK.OWN_DESSERT_BOARD_DIPPED_BERRIES,
    [AvailabilityStep.OWN_DESSERT_BOARD_DIPPED_FRUIT]:
      TK.OWN_DESSERT_BOARD_DIPPED_FRUIT,
    [AvailabilityStep.DESSERT_OWN_BOX_ADDONS]: TK.ALERT_DESSERT_OWN_BOX_ADDONS,
    [AvailabilityStep.TITLE]: TK.TITLE,
    [AvailabilityStep.OWN_COOKIE_BOX_EVERYDAY]: TK.ALERT_DESSERT_OWN_BOX_ADDONS,
    [AvailabilityStep.OWN_COOKIE_BOX_LIMITED]: TK.ALERT_DESSERT_OWN_BOX_ADDONS,
    [AvailabilityStep.OWN_6_COOKIE_BOX]: TK.ALERT_6_COOKIE_BOX_ADDONS,
    [AvailabilityStep.OWN_12_COOKIE_BOX]: TK.ALERT_12_COOKIE_BOX_ADDONS,
  }[step] || '');

export const StickyAlert: FC<Props> = ({
  isShown,
  hideAlert,
  availabilityStep,
  selectedOwnBoxAddons = [],
  clickOwnBoxAddon,
  isCookie12,
}) => {
  const [currentAlertText, setCurrentAlertText] = useState('');
  const arrangement = useSelector(selectArrangement);
  const addOnIds = [
    ...new Set(arrangement.ownBox.addons.map((addon) => addon.addonId)),
  ];

  const [isDessertBoardLimit, setDessertBoardLimit] = useState(false);
  const [isCookieBoxLimit, setCookieBoxLimit] = useState(false);
  const cookieLimitedName = 'Cookie Box - LTO';
  const everydayCookies = selectedOwnBoxAddons.filter(
    (addon) => !addon.option.optionName?.includes(cookieLimitedName),
  );
  useEffect(() => {
    if (isShown) {
      analytics.pushErrors([i18next.t(getAlertText(availabilityStep))]);
    }
  }, [isShown, availabilityStep]);

  useEffect(() => {
    if (isShown) {
      setCurrentAlertText(getAlertText(availabilityStep));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  useEffect(() => {
    const backedAddons = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.BakedGoods,
      ),
    );
    const dippedBerriesAdon = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.DippedBerries,
      ),
    );
    const dippedFruitsAdon = selectedOwnBoxAddons.filter((x) =>
      clickOwnBoxAddon.some(
        (addon) =>
          addon.addonId === x.option.addonId &&
          addon.dessertBoxType === DessertBoardType.DippedFruits,
      ),
    );

    if (
      backedAddons.length === 1 &&
      dippedBerriesAdon.length === 1 &&
      dippedFruitsAdon.length === 1
    ) {
      setDessertBoardLimit(true);
    }

    const limitedCookies = selectedOwnBoxAddons.filter((addon) =>
      addon.option.optionName?.includes(cookieLimitedName),
    );

    if (everydayCookies.length === 1 || limitedCookies.length === 1) {
      setCookieBoxLimit(true);
    }
  }, [selectedOwnBoxAddons]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isShown}
      onClose={hideAlert}
      autoHideDuration={HIDING_TIME_MILLIS}
      sx={snackbar}
      action={
        <IconButton aria-label="close" color="inherit" onClick={hideAlert}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Alert variant="filled" severity="warning" onClose={hideAlert}>
        {addOnIds.length === 3 && !isDessertBoardLimit && (
          <Typography variant="subtitle1">{i18next.t(TK.TITLE)}</Typography>
        )}
        {addOnIds.length === 2 && isCookie12 && everydayCookies.length < 1 && (
          <Typography variant="subtitle1">{i18next.t(TK.TITLE)}</Typography>
        )}
        {addOnIds.length === 2 && !isCookie12 && !isCookieBoxLimit && (
          <Typography variant="subtitle1">{i18next.t(TK.TITLE)}</Typography>
        )}
        <Typography data-test="pdp-details-error-alert">
          {i18next.t(currentAlertText)}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
