export enum HeaderLinks {
  TRACK_ORDER = '/customer-service/track-your-order.aspx',
  CONTACT_US = '/customer-service/contact-us',
  ALLERGY_SAFETY_INFORMATION = '/allergy-safety-information.aspx',
  PROMOTION_TERMS_CONDITIONS = '/terms-and-conditions/',
  LEGAL_TERMS = '/legal/terms-of-use',
  CERTIFIED_HAPPINESS = '/customer-service/certified-happiness.aspx',
  DESKTOP_FAQS = '/customer-service/faq.aspx',
  PRODUCTS_POLICY = '/legal/products-policy',
  SHIPMENT_POLICY = '/legal/shipment-policy',
  STORE_FINDER = '/store-finder',
  REWARDS = '/rewards',
  MY_ACCOUNT = '/my-account/',
  PROFILE_FAQ = '/my-account/?active=2',
  PROFILE_MY_INFORMATION = '/my-account/?active=3',
  PROFILE_ORDER_HISTORY = '/my-account/?active=4',
  PROFILE_GIFT_REMINDERS = '/my-account/?active=1&section=gf',
  PROFILE_ADDRESS_BOOK = '/my-account/?active=1&section=ab',
  FRANCHISE_OPPORTUNITIES = 'https://ediblefranchise.com/',
  EDIBLE_TREATS = '/in-store-only',
  WHO_WE_ARE = '/about/who-we-are.aspx',
  OUR_MISSION = '/about/our-mission.aspx',
  OUR_HISTORY = '/about/timeline.aspx',
  MOBILE_FAQS = '/about/faq.aspx',
  FOUNDER = '/about/tariq-farid-founder-edible-arrangements',
  ABOUT_CONTACT_US = '/customer-service/contact-us',
  CAREERS = '/edible-careers/edible-arrangements-careers',
  PRESS = '/about/press-room.aspx/',
  BLOG = 'https://blog.ediblearrangements.com/',
}
