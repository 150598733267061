export const LOG_IN = 'log_in';
export const OR = 'or';
export const EMAIL = 'email';
export const MAIN_PAGE = 'main.page';
export const LOGO = 'logo';
export const ERROR = 'error';
export const RESULT = 'result';
export const SUBMITTING = 'submitting';
export const NO_RESULTS = 'no_results';
export const LOADING = 'loading';
export const WE_GOT_AN_ERROR = 'we_got_an_error';
export const DAY_OF_WEEK_MONDAY_LONG = 'day_of_week.monday_long';
export const DAY_OF_WEEK_TUESDAY_LONG = 'day_of_week.tuesday_long';
export const DAY_OF_WEEK_WEDNESDAY_LONG = 'day_of_week.wednesday_long';
export const DAY_OF_WEEK_THURSDAY_LONG = 'day_of_week.thursday_long';
export const DAY_OF_WEEK_FRIDAY_LONG = 'day_of_week.friday_long';
export const DAY_OF_WEEK_SATURDAY_LONG = 'day_of_week.saturday_long';
export const DAY_OF_WEEK_SUNDAY_LONG = 'day_of_week.sunday_long';
export const DAY_OF_WEEK_MONDAY_SHORT = 'day_of_week.monday_short';
export const DAY_OF_WEEK_TUESDAY_SHORT = 'day_of_week.tuesday_short';
export const DAY_OF_WEEK_WEDNESDAY_SHORT = 'day_of_week.wednesday_short';
export const DAY_OF_WEEK_THURSDAY_SHORT = 'day_of_week.thursday_short';
export const DAY_OF_WEEK_FRIDAY_SHORT = 'day_of_week.friday_short';
export const DAY_OF_WEEK_SATURDAY_SHORT = 'day_of_week.saturday_short';
export const DAY_OF_WEEK_SUNDAY_SHORT = 'day_of_week.sunday_short';
export const SUGGESTED_CALENDAR_TOP = 'pdp.suggested_calendar.top';
export const SUGGESTED_CALENDAR_LINK = 'pdp.suggested_calendar.link';
export const SUGGESTED_CALENDAR_BOTTOM = 'pdp.suggested_calendar.bottom';
export const STORE_TIMINGS = 'pas.store_timings';
export const STORE_IS_NOT_FOUND_FOR_ZIP =
  'pdp.main.error.store_not_found_for_zip';
export const STORE_IS_NOT_FOUND_FOR_ARRANGEMENT_FOR_ZIP =
  'pdp.main.error.store_not_found_for_arrangement_for_zip';
export const STORE_IS_NOT_AVAILABLE_FOR_DATE =
  'pdp.main.error.store_not_available_for_date';
export const RECIPIENT_INFORMATION_ERROR_CAR_MODEL =
  'recipient_information.error_car_model';
export const RECIPIENT_INFORMATION_ERROR_CAR_COLOR =
  'recipient_information.error_car_color';
export const RECIPIENT_INFORMATION_ERROR_VEHICLE_MODEL =
  'recipient_information.error_car_model';
export const RECIPIENT_INFORMATION_ERROR_VEHICLE_COLOR =
  'recipient_information.error_car_color';
export const RECIPIENT_INFORMATION_ERROR_PICKUP_TIME =
  'recipient_information.error_pickup_time';
export const PICKUP_FORM_ERROR_PHONE =
  'recipient_information.error_pickup_phone';
export const NUMBER_OF_SIZES_ONE = 'arrangement_list_item.number_of_sizes.one';
export const NUMBER_OF_SIZES_TWO = 'arrangement_list_item.number_of_sizes.two';
export const NUMBER_OF_SIZES_THREE =
  'arrangement_list_item.number_of_sizes.three';
export const NUMBER_OF_SIZES_FOUR =
  'arrangement_list_item.number_of_sizes.four';
export const NUMBER_OF_SIZES_FIVE =
  'arrangement_list_item.number_of_sizes.five';
export const NUMBER_OF_SIZES_SIX = 'arrangement_list_item.number_of_sizes.six';
export const NUMBER_OF_SIZES_SEVEN =
  'arrangement_list_item.number_of_sizes.seven';
export const NUMBER_OF_SIZES_EIGHT =
  'arrangement_list_item.number_of_sizes.eight';
export const NUMBER_OF_SIZES_NINE =
  'arrangement_list_item.number_of_sizes.nine';
export const NUMBER_OF_SIZES_TEN = 'arrangement_list_item.number_of_sizes.ten';
export const NUMBER_OF_SIZES_STARTING_AT =
  'arrangement_list_item.number_of_sizes.starting_at';
export const NUMBER_OF_SIZES_NUMERIC_QUANTITY =
  'arrangement_list_item.number_of_sizes.sizes_starting_at';
export const NUMBER_OF_SIZES_NO_SIZES =
  'arrangement_list_item.number_of_sizes.no_sizes';
export const IMAGE_NOT_AVAILABLE = 'plp.image.not_available';
export const BACK = 'common.back';
export const CLOSE = 'close';
export const SUBMIT = 'submit';
export const SELECT_ADDRESS_TYPE =
  'recipient_information.validation.select_address_type';
export const ENTER_BUSINESS_NAME =
  'recipient_information.validation.enter_business_name';
export const ENTER_SCHOOL_NAME =
  'recipient_information.validation.enter_school_name';
export const ENTER_HOSPITAL_NAME =
  'recipient_information.validation.enter_hospital_name';
export const PAS_NETWORK_ERR = 'pas.network_err';
export const HEADER_USER_PROFILE_MENU_SIGN_OUT = 'header.user_profile.sign_out';
export const HEADER_USER_PROFILE_MENU_SIGN_UP = 'header.user_profile.sign_up';
export const HEADER_USER_PROFILE_ACCOUNT = 'header.account';
export const HEADER_USER_PROFILE_MY_REWARDS = 'header.user_profile.my_rewards';
export const HEADER_USER_PROFILE_FAQ = 'header.user_profile.faq_and_benefits';
export const HEADER_USER_PROFILE_MY_INFORMATION =
  'header.user_profile.my_information';
export const HEADER_USER_PROFILE_ORDER_HISTORY =
  'header.user_profile.order_history';
export const HEADER_USER_PROFILE_GIFT_REMINDERS =
  'header.user_profile.gift_reminders';
export const HEADER_USER_PROFILE_ADDRESS_BOOK =
  'header.user_profile.address_book';
export const HEADER_HELP = 'header.help';
export const HEADER_FIND_STORE = 'header.find_store';
export const HEADER_EDIBLE_REWARDS = 'header.edible_rewards';
export const HEADER_SHOPPING_CART = 'header.shopping_cart';
export const HEADER_HELP_TRACK_YOUR_ORDER = 'header.help_track_your_orders';
export const HEADER_HELP_CONTACT_US = 'header.help_contact_us';
export const HEADER_HELP_ALLERGY_SAFETY_INFORMATION =
  'header.help_allergy_safety_information';
export const HEADER_HELP_PROMOTION_TERMS_CONDITIONS =
  'header.help_promotion_terms_conditions';
export const HEADER_HELP_CERTIFIED_HAPPINESS =
  'header.help_certified_happiness';
export const HEADER_HELP_FAQS = 'header.help_faqs';
export const HEADER_HELP_PRODUCTS_POLICY = 'header.help_products_policy';
export const HEADER_STORES_FRANCHISE_OPPORTUNITIES =
  'header.stores.franchise_opportunities';
export const HEADER_STORES_EDIBLE_TREATS = 'header.stores.edible_treats';
export const HEADER_ABOUT_WHO_WE_ARE = 'header.about.who_we_are';
export const HEADER_ABOUT_OUR_MISSION = 'header.about.our_mission';
export const HEADER_ABOUT_OUR_HISTORY = 'header.about.our_history';
export const HEADER_ABOUT_FOUNDER = 'header.about.founder';
export const HEADER_ABOUT_CONTACT_US = 'header.about.contact_us';
export const HEADER_OTHER_CAREERS = 'header.other.careers';
export const HEADER_OTHER_PRESS = 'header.other.press';
export const HEADER_OTHER_BLOG = 'header.other.blog';
export const HEADER_NAV_CUSTOMER_SERVICE = 'header.nav.customer_service';
export const HEADER_NAV_STORES = 'header.nav.stores';
export const HEADER_NAV_ABOUT = 'header.nav.about';
export const HEADER_NAV_ABOUT_EDIBLE = 'header.nav.about_edible';
export const HEADER_NAV_OTHER = 'header.nav.other';
export const HEADER_NAV_MENU = 'header.nav.menu';
export const HEADER_NAV_MORE_LINKS = 'header.nav.more_links';
export const HEADER_NAV_LANGUAGE = 'header.nav.language';
export const SLIDER_NEXT = 'slider.next';
export const SLIDER_PREVIOUS = 'slider.previous';
export const SUBSCRIPTION_FOOTER_TITLE = 'subscription.title';
export const SUBSCRIPTION_SHOP_NOW = 'subscription.shop_now';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BEFORE =
  'subscription.terms_and_conditions.before';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_BETWEEN_AND =
  'subscription.terms_and_conditions.between_and';
export const SUBSCRIPTION_EMAIL_FORM_BUTTON_LABEL =
  'subscription.email_form.button_label';
export const SUBSCRIPTION_TERMS_AND_CONDITIONS_AFTER =
  'subscription.terms_and_conditions.after';
export const SUBSCRIPTION_EMAIL_FORM_LABEL = 'subscription.email_form.label';
export const SUBSCRIPTION_EMAIL_FORM_TERMS_AND_CONDITIONS =
  'subscription.email_form.terms_and_conditions';
export const NEW_SUBSCRIPTION_FIRST_POPUP_TITLE = 'subscription.popup_title';
export const NEW_SUBSCRIPTION_FIRST_POPUP_JOIN_EMAIL_TEXT =
  'subscription.Join_our_email';
export const NEW_SUBSCRIPTION_SIGNUP_FIRST_JOIN_EMAIL_SMS_TEXT =
  'subscription.Join_our_email_or_SMS';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_SAVINGS_TEXT =
  'subscription.instant_savings';
export const NEW_SUBSCRIPTION_FIRST_POPUP_INSTANT_TEXT = 'subscription.instant';
export const NEW_SUBSCRIPTION_FIRST_POPUP_SAVINGS_TEXT = 'subscription.savings';
export const NEW_SUBSCRIPTION_FIRST_POPUP_PICKUP_ORDER_TEXT =
  'subscription.on_your_next_pickup';
export const SUBSCRIPTION_FIRST_POPUP_TITLE =
  'subscription.Something_You_ll_Love';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_VAL = 'subscription.15';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_PER = 'subscription.percent';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_TEXT = 'subscription.off';
export const SUBSCRIPTION_FIRST_POPUP_OFFER_STAR = 'subscription.star';
export const SUBSCRIPTION_FIRST_POPUP_DESCRIPTION =
  'subscription.your_next_pickup_order';
export const SUBSCRIPTION_NEW_SUCCESS_POPUP_TITLE = 'subscription.sweet';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_FIRST =
  'subscription.thanks_for_signing_up';
export const SUBSCRIPTION_SUCCESS_POPUP_DESCRIPTION_LAST =
  'subscription.for_15_off';
export const SUBSCRIPTION_SUCCESS_POPUP_SOCIAL_LINKS_TITLE =
  'subscription.connect_with_us';
export const SUBSCRIPTION_SUCCESS_POPUP_GIFT_TEXT =
  'subscription.order_a_freshly-crafted_gift';
export const SUBSCRIPTION_IMAGE_TITLE = 'subscription.delicious_fruit_design';
export const ERROR_PHONE = 'subscription.invalid_phone_number';
export const ERROR_EMAIL = 'subscription.invalid_email';
export const REQUIRED_EMAIL = 'subscription.email_is_required';
export const ERROR_AGREE = 'subscription.you_must_agree';
export const SUBSCRIPTION_FOOTER_FORM_EMAIL_LABEL =
  'subscription.email.address';
export const SUBSCRIPTION_FOOTER_FORM_SUBMIT = 'subscription.form.submit';
export const SUBSCRIPTION_FOOTER_FORM_EMAIL_ERROR =
  'subscription.form.email.error';
export const SUBSCRIPTION_FOOTER_SIGNUP_FORM_EMAIL_ERROR =
  'subscription.signup.form.email.error';
export const SUBSCRIPTION_TERMS_OF_USE = 'subscription.form.terms.use';
export const SUBSCRIPTION_FORM_PHONE_LABEL = 'subscription.form.phone.label';
export const SUBSCRIPTION_FORM_PHONE_ERROR = 'subscription.form.phone.error';
export const SUBSCRIPTION_FORM_SIGNUP_PHONE_ERROR =
  'subscription.form.signup.phone.error';
export const SUBSCRIPTION_FORM_POLICY_LABEL = 'subscription.form.policy.label';
export const SUBSCRIPTION_FORM_POLICY_TOOLTIP =
  'subscription.form.policy.tooltip';
export const SUBSCRIPTION_FORM_POLICY_TOOLTIP_LABEL =
  'subscription.form.policy.tooltip.label';
export const SUBSCRIPTION_FORM_POLICY_ERROR = 'subscription.form.policy.error';
export const SUBSCRIPTION_SIGNUP_POLICY_ERROR =
  'subscription.signup.form.policy.error';
export const ERROR_SPL_CHARS_BUSINESS_NAME =
  'recipient_information.validation.spl_chars_business_name';
export const ERROR_SPL_CHARS_SCHOOL_NAME =
  'recipient_information.validation.spl_chars_school_name';
export const ERROR_SPL_CHARS_HOSPITAL_NAME =
  'recipient_information.validation.spl_chars_hospital_name';
export const RECIPIENT_INFORMATION_ERROR_CAR_MODEL_SPL_CHARS =
  'recipient_information.error_car_model_spl_chars';
export const RECIPIENT_INFORMATION_ERROR_CAR_COLOR_SPL_CHARS =
  'recipient_information.error_car_color_spl_chars';
export const SOCIAL_LINKS_TITLE = 'social.links.title';
export const PAYMENT_OPTIONS_TITLE = 'payment.options.title';
export const NEAREST_STORE_TITLE = 'nearest.store.title';
export const NEAREST_STORE_TODAY_LABEL = 'nearest.store.today.label';
export const NEAREST_STORE_DIRECTIONS_LABEL = 'nearest.store.directions.label';
export const TERMS_OF_USE_LABEL = 'terms.of.use.label';
export const PRIVACY_POLICY_LABEL = 'privacy.policy.label';
export const CALIFORNIA_PRIVACY_RIGHTS_LABEL = 'california.privacy.label';
export const CORPORATE_PROMOTION_LABEL = 'corporate.promotion.label';
export const COOKIE_SETTINGS_LABEL = 'cookie.settings.label';
export const CORPORATE_COPYRIGHT_LABEL = 'corporate.copyright.label';
export const DESKTOP_SEARCH_PLACEHOLDER = 'desktop.search.placeholder';
export const SEARCH_HELPER_TEXT = 'search.helper.text';
export const SEARCH_SUGGESTED_RESULTS = 'suggested.results.text';
export const SEARCH_NO_SUGGESTIONS = 'search.no.suggestions.text';
export const SEARCHING_TEXT = 'searching.text';
export const ALL_RESULTS = 'all.results';
export const CHOOSE_VARIANT = 'choose.variant';
export const SINGLE_VARIANT = 'single.variant';
export const BLOG_CAROUSEL_TITLE = 'blog_carousel.title';
export const CANT_BEGIN_WITH_O_OR_1 = 'phone.cant_begin_with_0_or_1';
export const ENTER_ONE_PHONE_NUMBER = 'phone.enter_one_phone_number';
export const ENTER_ONE_VALID_PHONE_NUMBER =
  'phone.enter_one_valid_phone_number';
export const HOME_PAGE_VIEW_ALL = 'home_page.view_all';
export const HOME_PAGE_CAROUSEL_FORWARD_CONTROL =
  'home_page.carousel_forward_control';
export const HOME_PAGE_CAROUSEL_BACKWARDS_CONTROL =
  'home_page.carousel_backward_control';
export const NEAREST_STORE_WEEKLY_HOURS = 'nearest_store.weekly_hours';
export const NEAREST_STORE_PHONE_CONTACT = 'nearest_store.phone_contact';
export const NEAREST_STORE_DIRECTIONS = 'nearest_store.directions';
export const HOME_PAGE_H1_HEADING = 'home_page.h1_heading';
export const FRANCHISE_BANNER_TITLE = 'home_page.franchise_banner.title';
export const FRANCHISE_BANNER_BODY = 'home_page.franchise_banner.body';
export const FRANCHISE_BANNER_BUTTON = 'home_page.franchise_banner.button';
export const POPULAR_CATEGORIES = 'home_page.popular_categories';
export const VIEW_DETAILS = 'product_listing.view_details';
export const HIDE_DETAILS = 'product_listing.hide_details';
