import {
  CART_ERROR_RELOAD,
  CART_ERROR_SUMMARY,
  CART_ERROR_TITLE,
  ERROR_STRAWBERRY_MASCOT,
  FULL_PAGE_ERROR_RELOAD,
  FULL_PAGE_ERROR_SUMMARY,
  FULL_PAGE_ERROR_TITLE,
  ORDER_CONFIRMATION_ERROR_CONTACT_US,
  ORDER_CONFIRMATION_ERROR_RELOAD,
  ORDER_CONFIRMATION_ERROR_SUMMARY,
  ORDER_CONFIRMATION_ERROR_TITLE,
  PAYMENT_PAGE_ERROR_RELOAD,
  PAYMENT_PAGE_ERROR_SUMMARY,
  PAYMENT_PAGE_ERROR_TITLE,
  PRODUCT_DISPLAY_PAGE_ERROR_RELOAD,
  PRODUCT_DISPLAY_PAGE_ERROR_SHOP_ALL,
  PRODUCT_DISPLAY_PAGE_ERROR_SUMMARY,
  PRODUCT_DISPLAY_PAGE_ERROR_TITLE,
  PRODUCT_LISTING_PAGE_ERROR_RELOAD,
  PRODUCT_LISTING_PAGE_ERROR_SHOP_ALL,
  PRODUCT_LISTING_PAGE_ERROR_SUMMARY,
  PRODUCT_LISTING_PAGE_ERROR_TITLE,
  REWARDS_PAGE_ERROR_RELOAD,
  REWARDS_PAGE_ERROR_SUMMARY,
  REWARDS_PAGE_ERROR_TITLE,
  STORE_FINDER_ERROR_RELOAD,
  STORE_FINDER_ERROR_SUMMARY,
  STORE_FINDER_ERROR_TITLE,
  TRACK_ORDER_ERROR_RELOAD,
  TRACK_ORDER_ERROR_STORE_LOCATOR,
  TRACK_ORDER_ERROR_SUMMARY,
  TRACK_ORDER_ERROR_TITLE,
} from 'components/Error/translations/constants';

export const orderConfirmationErrorPage = {
  [ERROR_STRAWBERRY_MASCOT]: 'Strawberry Error page Mascot',
  [ORDER_CONFIRMATION_ERROR_TITLE]: 'Well, now this is berry embarrassing!',
  [ORDER_CONFIRMATION_ERROR_RELOAD]: 'Reload Page',
  [ORDER_CONFIRMATION_ERROR_CONTACT_US]: 'Contact us',
  [ORDER_CONFIRMATION_ERROR_SUMMARY]:
    "It seems as though we're having trouble retrieving your order confirmation details.The good news is that if you're reading this, then your order was placed successfully, and you should be receiving an email with your confirmation shortly.",
};

export const productDisplayPageError = {
  [PRODUCT_DISPLAY_PAGE_ERROR_RELOAD]: 'Reload Page',
  [PRODUCT_DISPLAY_PAGE_ERROR_SHOP_ALL]: 'Shop All Products',
  [PRODUCT_DISPLAY_PAGE_ERROR_SUMMARY]:
    "Unfortunately, it seems that you've chosen a product that is currently unavailable or we're experiencing difficulty locating it. Please try reloading the page or feel free to browse through the rest of our delicious catalog!",
  [PRODUCT_DISPLAY_PAGE_ERROR_TITLE]: "I'm in a jam... of embarrassment!",
};

export const productListingPageError = {
  [PRODUCT_LISTING_PAGE_ERROR_RELOAD]: 'Reload Page',
  [PRODUCT_LISTING_PAGE_ERROR_SHOP_ALL]: 'Shop All Products',
  [PRODUCT_LISTING_PAGE_ERROR_SUMMARY]:
    "Unfortunately, it seems that you've chosen a category that is currently unavailable or we're experiencing difficulty locating it. Please try reloading the page or feel free to browse through the rest of our delicious catalog!",
  [PRODUCT_LISTING_PAGE_ERROR_TITLE]:
    "I've reached peak strawberry blush levels of embarrassment.",
};

export const trackOrderError = {
  [TRACK_ORDER_ERROR_RELOAD]: 'Reload Page',
  [TRACK_ORDER_ERROR_STORE_LOCATOR]: 'Store Locator',
  [TRACK_ORDER_ERROR_SUMMARY]:
    "Unfortunately, there was an issue on our end while tracking your order. Please try reloading the page and attempting again, if you're still experiencing trouble, kindly locate your delivery store's contact information and give them a call. We apologize for any inconvenience.",
  [TRACK_ORDER_ERROR_TITLE]: 'Well, now this is berry embarrassing!',
};

export const cartError = {
  [CART_ERROR_RELOAD]: 'Reload Page',
  [CART_ERROR_SUMMARY]:
    "Unfortunately, there was an issue on our end while attempting to load your cart. Please try reloading the page to see if that resolves the issue. If you're still experiencing trouble, kindly locate your nearest Edible store to place your order. We apologize for any inconvenience.",
  [CART_ERROR_TITLE]:
    "I've reached peak strawberry blush levels of embarrassment.",
};

export const fullPageError = {
  [FULL_PAGE_ERROR_RELOAD]: 'Reload Page',
  [FULL_PAGE_ERROR_SUMMARY]:
    "Unfortunately, there was an issue on our end while attempting to load the site. Please try reloading the page to see if that resolves the issue. If you're still experiencing trouble, kindly locate your nearest Edible store to place your order. We apologize for any inconvenience.",
  [FULL_PAGE_ERROR_TITLE]:
    "I've reached peak strawberry blush levels of embarrassment.",
};

export const paymentPageError = {
  [PAYMENT_PAGE_ERROR_RELOAD]: 'Reload Page',
  [PAYMENT_PAGE_ERROR_SUMMARY]:
    "Unfortunately, there was an issue on our end while attempting to load your cart. Please try reloading the page to see if that resolves the issue. If you're still experiencing trouble, kindly locate your nearest Edible store to place your order. We apologize for any inconvenience.",
  [PAYMENT_PAGE_ERROR_TITLE]:
    "I've reached peak strawberry blush levels of embarrassment.",
};

export const storeFinderError = {
  [STORE_FINDER_ERROR_RELOAD]: 'Reload Page',
  [STORE_FINDER_ERROR_SUMMARY]:
    'Unfortunately, there was an issue on our end while attempting to display all of our Edible stores. Please try reloading the page to see if that resolves the issue. We apologize for any inconvenience.',
  [STORE_FINDER_ERROR_TITLE]: "I'm in a jam... of embarrassment!",
};

export const rewardsPageError = {
  [REWARDS_PAGE_ERROR_RELOAD]: 'Reload Page',
  [REWARDS_PAGE_ERROR_SUMMARY]:
    'Unfortunately, there was an issue on our end while attempting to display all of our Edible rewards, accounts, reminders and address book. Please try reloading the page to see if that resolves the issue. We apologize for any inconvenience.',
  [REWARDS_PAGE_ERROR_TITLE]:
    "I've reached peak strawberry blush levels of embarrassment.",
};
