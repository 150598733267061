import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/Account/styles/styles';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { Box, Button, Grid, TextField } from '@mui/material';
import { ChangePasswordRequest } from 'api/account/type/account-request';
import { InfoOutlined } from '@mui/icons-material';
import { StrengthMeter } from 'components/Payment/BillingInformation/strength-meter';
import { accountApiService } from 'api/account/account.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';
import i18next from 'i18next';

interface Props {
  setIsChangingPassword: (value: boolean) => void;
}

export const ChangePasswordForm: FC<Props> = ({ setIsChangingPassword }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const verifyConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const [errors, setErrors] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  const accountId =
    accountInfo.length && accountInfo[0].id ? accountInfo[0].id : 0;

  const validateNewPassword = (newPassword: string): string => {
    if (
      newPassword &&
      new RegExp(/(?=.{8,})/).test(newPassword) &&
      new RegExp(/(?=.*?[#!@$ %^&*])/).test(newPassword)
    ) {
      return '';
    }
    return i18next.t(TK.CORRECT_PASSWORD_REQ_MSG);
  };
  // Validation check
  const validateForm = () => {
    let valid = true;
    const newErrors = { oldPassword: '', password: '', confirmPassword: '' };

    if (!oldPassword) {
      newErrors.oldPassword = i18next.t(TK.OLD_PASSWORD_REQ_MSG);
      valid = false;
    }
    if (!password) {
      newErrors.password = i18next.t(TK.PASSWORD_REQ_MSG);
      valid = false;
    } else if (password) {
      const val = validateNewPassword(password);
      if (val !== '') {
        newErrors.password = val;
        valid = false;
      }
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = i18next.t(TK.CONFIRMED_PASSWORD_REQ_MSG);
      valid = false;
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = i18next.t(TK.PASSWORD_NOT_MATCHED_MSG);
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    const res = validateForm();
    if (res) {
      const request: ChangePasswordRequest = {
        id: Number(accountId),
        password,
      };
      await accountApiService.changePassword(request);
      setIsChangingPassword(false);
    }
  };

  const handleCancel = () => {
    setIsChangingPassword(false);
  };

  return (
    <Box mt={3}>
      <Box sx={styles.textFieldWrapper}>
        <TextField
          label={i18next.t(TK.OLD_PASSWORD)}
          variant="outlined"
          type="password"
          sx={styles.passwordText}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          error={!!errors.oldPassword}
          helperText={errors.oldPassword}
        />
        <Box sx={styles.passwordWrapper}>
          <TextField
            label={i18next.t(TK.NEW_PASSWORD)}
            variant="outlined"
            type="password"
            sx={styles.passwordText}
            value={password}
            onChange={handlePasswordChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            label={i18next.t(TK.CONFIRM_PASSWORD)}
            variant="outlined"
            type="password"
            sx={styles.passwordText}
            value={confirmPassword}
            onChange={verifyConfirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Box>
      </Box>
      <StrengthMeter password={password} />
      <Grid item sx={styles.formInput}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexWrap="nowrap"
          p={2}
          sx={styles.tooltipInfo}
        >
          <Grid
            display="flex"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
          >
            <InfoOutlined />
          </Grid>
          <Grid pl={1}>{i18next.t(TK.CHANGE_PASSWORD_REQUIREMENTS)}</Grid>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Button
          onClick={handleSubmit}
          sx={styles.passwordBtnSelect}
          variant="contained"
          color="primary"
        >
          {i18next.t(TK.REWARD_DIALOG_SAVE)}
        </Button>
        <Button
          onClick={handleCancel}
          sx={styles.passwordBtnReject}
          variant="outlined"
          color="primary"
        >
          {i18next.t(TK.CANCEL_TEXT)}
        </Button>
      </Box>
    </Box>
  );
};
