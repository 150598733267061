import * as TK from 'translations/locales/translation-keys.constant';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { HeaderLinks } from 'enums/header-links';
import { IS_MOBILE_HOST } from 'utils/is-mobile-host';
import { Key } from 'enums/key';
import { analytics } from 'service/adobe-analytics/analytics';
import { segment } from 'service/segment/segment';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useMenu } from 'hooks/useMenu';
import { useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

const menuItems = [
  {
    title: TK.HEADER_HELP_TRACK_YOUR_ORDER,
    link: HeaderLinks.TRACK_ORDER,
    dataTest: 'help-option track-order-header',
  },
  {
    title: TK.HEADER_HELP_CONTACT_US,
    link: HeaderLinks.CONTACT_US,
    dataTest: 'help-option contact-us-header',
  },
  {
    title: TK.HEADER_HELP_ALLERGY_SAFETY_INFORMATION,
    link: HeaderLinks.ALLERGY_SAFETY_INFORMATION,
    dataTest: 'help-option allergy-header',
  },
  {
    title: TK.HEADER_HELP_PROMOTION_TERMS_CONDITIONS,
    link: HeaderLinks.PROMOTION_TERMS_CONDITIONS,
    dataTest: 'help-option promo-terms-header',
  },
  {
    title: TK.HEADER_HELP_CERTIFIED_HAPPINESS,
    link: HeaderLinks.CERTIFIED_HAPPINESS,
    dataTest: 'help-option certified-happiness',
  },
  {
    title: TK.HEADER_HELP_FAQS,
    link: IS_MOBILE_HOST ? HeaderLinks.MOBILE_FAQS : HeaderLinks.DESKTOP_FAQS,
    dataTest: 'help-option faqs-header',
  },
  {
    title: TK.HEADER_HELP_PRODUCTS_POLICY,
    link: HeaderLinks.PRODUCTS_POLICY,
    dataTest: 'help-option product-policy-header',
  },
];

export const HelpMenu: FC = () => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleClose();
    }
  };

  useEffect(() => {
    if (anchorEl) {
      analytics.headerMenuItemClicked(i18next.t(TK.HEADER_HELP));
      segment.menuItemClicked(
        i18next.t(TK.HEADER_HELP),
        '',
        userSession,
        userAccountInformation,
      );
    }
  }, [anchorEl, handleClick]);

  return (
    <Box mr={2}>
      <Button
        data-test="help-button"
        color="secondary"
        id="help-menu-button"
        aria-controls={open ? 'help-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ fontWeight: 600 }}
      >
        {i18next.t(TK.HEADER_HELP)}
      </Button>
      <Menu
        data-test="help-div"
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'help-menu-button',
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.link}
            onClick={() => {
              analytics.headerMenuItemClicked(i18next.t(menuItem.title));
              segment.menuItemClicked(
                i18next.t(menuItem.title),
                menuItem.link,
                userSession,
                userAccountInformation,
              );
              handleClose();
            }}
            component="a"
            href={menuItem.link}
            data-test={menuItem.dataTest}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <Typography variant="subtitle2">
              {i18next.t(menuItem.title)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
