import { SxProps, styled } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';

export const skeletonImage: SxProps = {
  minWidth: '56px',
  minHeight: '56px',
  mr: 1.5,
};

export const regularPrice: ThemedSxProps = {
  textDecoration: 'line-through',
  mr: 1,
  color: (theme) => theme.palette.text.secondary,
};
export const salePrice =
  (color?: string): ThemedSxProps =>
  (theme) => ({
    color: color ?? theme.palette.primary.main,
    pb: 0.5,
    display: 'inline-block',
  });

export const ProductOptionInformation = styled('div')(({ theme }) => ({
  textAlign: 'left',
  marginLeft: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const ProductOptionTitle = styled('span')({
  fontSize: 14,
  display: 'block',
  lineHeight: '20px',
  marginBottom: 3,
  fontWeight: 600,
});

interface ProductOptionsItemProps {
  active?: boolean;
  disabled?: boolean;
}

export const productOptionsItem =
  ({ active, disabled }: ProductOptionsItemProps): ThemedSxProps =>
  (theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    boxShadow: 'none',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    position: 'relative',

    ...(!active && {
      '&:hover': {
        '&:before': {
          borderColor: theme.palette.other.standardInputLine,
        },
      },
    }),

    ...(disabled && {
      color: theme.palette.text.disabled,
      cursor: 'default',

      '& .MuiBadge-badge': {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.secondary,
      },
    }),

    '&:before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: `2px solid ${theme.palette.other.outlineBorder}`,

      ...(active && {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
      }),
      ...(disabled && {
        borderColor: theme.palette.action.disabledBackground,
      }),
    },
  });

export const getImageSx = ({
  disabled,
}: {
  disabled?: boolean;
}): ThemedSxProps => ({
  width: 64,
  height: 64,
  border: (theme) => `1px solid ${theme.palette.divider}`,

  ...(disabled && {
    opacity: 0.3,
  }),
});

export const badge: ThemedSxProps = {
  display: 'block',
  mb: 0.5,

  '& .MuiBadge-badge': {
    transform: 'none',
    position: 'static',
    wordBreak: 'break-all',
    fontSize: 12,
    padding: (theme) => theme.spacing(0.5),
  },
};
export const oneHourDelivery: ThemedSxProps = {
  width: '90%',
};
