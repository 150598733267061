import { BrowserRouter } from 'react-router-dom';
import { CanonicalUrlProvider } from 'providers/CanonicalUrlProvider';
import { Composer } from 'components/common/Composer/Composer';
import { MainContainer } from 'containers/MainContainer/MainContainer';
import { SessionProvider } from 'providers/Session/SessionProvider';
import { useAdsTagManager } from 'hooks/google/use-ads-tag-manager';
import { useAnalyticsV4 } from 'hooks/google/use-analytics-v4';
import { useBazaarvoiceScript } from 'lib/bazaarvoice/hooks/use-bazaarvoice';
import { useCordial } from 'service/cordial/use-cordial';
import { useDynamicYield } from 'service/dynamic-yield/use-dynamic-yield';
import { useSegment } from 'service/segment/use-segment';
import { useTagManager } from 'hooks/google/use-tag-manager';
import React, { FC } from 'react';

export const AppContainer: FC = () => {
  useAdsTagManager();
  useTagManager();
  useAnalyticsV4();
  useSegment();
  useCordial();
  useBazaarvoiceScript();
  useDynamicYield();

  return (
    <Composer
      components={[SessionProvider, CanonicalUrlProvider, BrowserRouter]}
    >
      <MainContainer />
    </Composer>
  );
};
