import * as TK from 'components/PDP/Details/components/ContinueButton/translations/continue-button.locale.constants';
import { Box, Button } from '@mui/material';
import { appInsights } from 'utils/telemetry/app-insights';
import { continueButtonWrapper } from 'components/PDP/Details/components/ContinueButton/continue-button.styles';
import React, { forwardRef } from 'react';
import i18next from 'i18next';

interface Props {
  disabled?: boolean;
  onClick: (event?: React.MouseEvent) => void;
  dataTest: string;
}

export const ContinueButton = forwardRef<HTMLButtonElement, Props>(
  ({ disabled, onClick, dataTest }, ref) => (
    <Box sx={continueButtonWrapper}>
      <Button
        variant="contained"
        size="large"
        disabled={disabled}
        onClick={(e) => {
          appInsights.trackEvent({ name: 'PAS continue clicked' });
          onClick(e);
        }}
        ref={ref}
        fullWidth
        data-test={dataTest}
      >
        {i18next.t(TK.PRODUCT_CONTINUE_BUTTON)}
      </Button>
    </Box>
  ),
);
