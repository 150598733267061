export const TITLE = 'account.title';
export const BODY = 'account.body';
export const EARN_TXT = 'account.earn_txt';
export const ORDER_TXT = 'account.order_txt';
export const ACCOUNT_TXT = 'account.account_txt';
export const REWARDS_TXT = 'account.rewards_txt';
export const LOGIN = 'account.login';
export const CREATE_ACCOUNT = 'account.create_account';
export const WELCOME_BACK = 'account.welcome_back';
export const APPLY_REWARDS = 'account.apply_rewards';
export const REWARD_TOOLTIP = 'account.reward_tooltip';
export const ALLERGY_TEXT = 'account.allergy_text';
export const FAQ_TEXT = 'account.faq_text';
export const NO_REWARDS = 'account.no_rewards';
export const NO_REWARDS_MESSAGE = 'account.no_rewards_message';
export const CONTACTUS_TXT = 'contact.contacus_txt';
