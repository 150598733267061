export const BUSINESS_GIFTING_FILTER_ALIAS = 'business-gifting';
export const BUSINESS_GIFTS_FILTER_ALIAS = 'business-gifts';
export const LOCAL_SEO_ALIAS = 'states';
export const ROOT_PATH_LIST = ['edible-gifts', 'pages', 'policy'];
export const LOCAL_LEGAL_ALIAS = 'legal';
export const LOCAL_LEGAL_TOU_ALIAS = 'terms-of-use';
export const LOCAL_LEGAL_PRIVACY_POLICY_ALIAS = 'privacy-policy';
export const CALIFORNIA_PRIVACY_RIGHTS_ALIAS = 'california-privacy-rights';
export const CUSTOMER_SERVICE_ALIAS = 'customer-service';
export const ALLERGY_SAFETY_INFORMATION_ALIAS = 'allergy-safety-information';
export const CERTIFIED_HAPPINESS_ALIAS = 'certified-happiness';
export const TRACK_YOUR_ORDER_ALIAS = 'track-your-order';
export const SHIPMENT_POLICY_PATH = 'shipment-policy';
export const DELIVERY_POLICY_PATH = 'delivery-policy';
export const LEGAL_PATH = '/legal';
export const TERMS_OF_USE_PATH = '/legal/terms-of-use';
export const PRIVACY_POLICY_PATH = '/legal/privacy-policy';
export const DELIVERY_POLICY = '/legal/delivery-policy';
export const FAQ_PAGE_ALIAS = 'faq';
export const STORE_FINDER_PATH = '/store-finder';
export const STORE_COMING_SOON_PATH = '/stores/opening-soon-store-detail';
