import {
  AreaSearchResponse,
  CurrentSearchStatus,
  StoreFinderPageStatus,
  StoreFinderResponse,
  StoreFinderWidget,
  StoresComingSoonResponse,
} from 'components/StoreFinder/interfaces/store-finder-page.interfaces';
import {
  BrowserCoordinates,
  BrowserCoordinatesStatus,
} from 'interfaces/browser-coordinates.interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from 'types/error.types';
import { Status } from 'redux/types/state.interface';
import {
  StoreAvailability,
  StoreOccasionHours,
} from 'interfaces/store-occasion-hours-and-availabilities.interface';
import { filterOutCityAreas } from 'components/StoreFinder/feature/utils/filter-non-city-areas';
import {
  getStoreAreasBasedOnSearchInput,
  getStoreFinderStores,
  getStoreOccasionHoursAndAvailabilities,
  getStoresComingSoon,
} from 'components/StoreFinder/feature/actions';
import { getUniqueResults } from 'components/StoreFinder/feature/utils/get-unique-results';

export interface StoreFinderState {
  data: StoreFinderResponse;
  storeAvailabilities: StoreAvailability[];
  storeOccasionHours: StoreOccasionHours[];
  storesComingSoon: StoresComingSoonResponse[];
  storeFinderPageStatus: StoreFinderPageStatus;
  error: ResponseError | null;
  browserLocationServicesToggle: boolean;
  browserCoordinates: BrowserCoordinates;
  searchValue: string;
  selectedFilters: {
    selectedServices: string[];
    selectedDistance: number | null;
  };
  storesDisplayed: StoreFinderWidget[];
  filteredStores: StoreFinderWidget[];
  storeMapMarkerClicked: StoreFinderWidget | undefined;
  storeClickedIsHighlighted: boolean;
  areasBasedOnSearchInput: AreaSearchResponse[];
  areaSuggestionClicked: AreaSearchResponse | null;
  currentSearchStatus: CurrentSearchStatus | null;
  mapGPSButtonClicked: boolean;
  mapHasBeenDragged: boolean;
  mapZoomHasBeenUpdated: boolean;
}

interface SetFiltersPayload {
  type: 'service' | 'distance';
  value: string[] | number | null;
}

const initialState: StoreFinderState = {
  data: {
    storeData: [],
  },
  storeOccasionHours: [],
  storeAvailabilities: [],
  storesComingSoon: [],
  storeFinderPageStatus: {
    getStoresDataStatus: Status.INIT,
    getAvailabilitiesStatus: Status.INIT,
    getStoresComingSoonStatus: Status.INIT,
    getAreasBasedOnSearchInputStatus: Status.INIT,
  },
  error: null,
  browserLocationServicesToggle: false,
  browserCoordinates: {
    latitude: undefined,
    longitude: undefined,
    status: BrowserCoordinatesStatus.INIT,
  },
  searchValue: '',
  selectedFilters: {
    selectedServices: [],
    selectedDistance: null,
  },
  storesDisplayed: [],
  filteredStores: [],
  storeMapMarkerClicked: undefined,
  storeClickedIsHighlighted: false,
  areasBasedOnSearchInput: [],
  areaSuggestionClicked: null,
  currentSearchStatus: null,
  mapGPSButtonClicked: false,
  mapHasBeenDragged: false,
  mapZoomHasBeenUpdated: false,
};

const slice = createSlice({
  name: 'storeFinder',
  initialState,
  reducers: {
    toggleBrowserLocationServices: (
      state,
      action: PayloadAction<BrowserCoordinates>,
    ) => {
      state.browserLocationServicesToggle =
        !!action.payload.latitude && !!action.payload.longitude;
      state.browserCoordinates = action.payload;
    },
    setMapHasBeenDragged: (state) => {
      state.mapHasBeenDragged = true;
    },
    setMapZoomHasBeenUpdatedStatus: (state) => {
      state.mapZoomHasBeenUpdated = true;
    },
    resetMapZoomHasBeenUpdatedStatus: (state) => {
      state.mapZoomHasBeenUpdated = false;
    },
    gpsButtonClicked: (state) => {
      state.mapGPSButtonClicked = true;
    },
    resetGPSButton: (state) => {
      state.mapGPSButtonClicked = false;
      state.mapHasBeenDragged = false;
      state.mapZoomHasBeenUpdated = false;
    },
    clearSearch: (state) => {
      state.searchValue = '';
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      if (state.storeMapMarkerClicked) {
        state.storeMapMarkerClicked = undefined;
      }
      const { type, value } = action.payload;
      if (type === 'service') {
        state.selectedFilters.selectedServices = value as string[];
      }
      if (type === 'distance') {
        state.selectedFilters.selectedDistance = value as number | null;
      }
    },
    clearAllFilters: (state) => {
      state.searchValue = '';
      state.selectedFilters.selectedDistance = null;
      state.selectedFilters.selectedServices = [];
      if (state.storeMapMarkerClicked) {
        state.storeMapMarkerClicked = undefined;
      }
    },
    setStoresDisplayed: (state, action: PayloadAction<StoreFinderWidget[]>) => {
      state.storesDisplayed = action.payload;
    },
    setFilteredStores: (state, action: PayloadAction<StoreFinderWidget[]>) => {
      state.filteredStores = action.payload;
    },
    setStoreMapMarkerClicked: (
      state,
      action: PayloadAction<StoreFinderWidget>,
    ) => {
      state.storeMapMarkerClicked = action.payload;
      state.storeClickedIsHighlighted = true;
      state.selectedFilters.selectedServices = [];
      state.selectedFilters.selectedDistance = null;
    },
    clearStoreMapMarker: (state) => {
      state.storeMapMarkerClicked = undefined;
      state.storeClickedIsHighlighted = false;
    },
    clearStoreHighlightedState: (state) => {
      state.storeClickedIsHighlighted = false;
    },
    setAreaSuggestionClicked: (
      state,
      action: PayloadAction<AreaSearchResponse>,
    ) => {
      state.areaSuggestionClicked = action.payload;
      state.currentSearchStatus = null;

      state.areaSuggestionClicked = action.payload;
    },
    clearAreaSuggestionClicked: (state) => {
      state.currentSearchStatus = null;
      state.areaSuggestionClicked = null;
    },
    clearAreaSuggestions: (state) => {
      state.areasBasedOnSearchInput = [];
      state.areaSuggestionClicked = null;
    },
    cancelAreaSearch: (state, { payload }: PayloadAction<string>) => {
      state.areasBasedOnSearchInput = [];
      state.areaSuggestionClicked = null;
      state.currentSearchStatus = {
        areaSearch: payload,
        isFinishedStatus: true,
        areaSearchResponse: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreFinderStores.pending, (state) => {
        state.data.storeData = [];
        state.filteredStores = [];
        state.storesDisplayed = [];
        state.storeFinderPageStatus.getStoresDataStatus = Status.PENDING;
      })
      .addCase(getStoreFinderStores.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.storeFinderPageStatus.getStoresDataStatus = Status.ERROR;
      })

      .addCase(getStoreFinderStores.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.storeFinderPageStatus.getStoresDataStatus = Status.FULFILLED;
      })
      .addCase(getStoreOccasionHoursAndAvailabilities.pending, (state) => {
        state.storeFinderPageStatus.getAvailabilitiesStatus = Status.PENDING;
      })
      .addCase(
        getStoreOccasionHoursAndAvailabilities.fulfilled,
        (state, { payload }) => {
          state.storeAvailabilities = payload.storeAvailabilities;
          state.storeOccasionHours = payload.storeOccasionHours;
          state.storeFinderPageStatus.getAvailabilitiesStatus =
            Status.FULFILLED;
        },
      )
      .addCase(
        getStoreOccasionHoursAndAvailabilities.rejected,
        (state, { payload }) => {
          state.error = payload as ResponseError;
          state.storeFinderPageStatus.getAvailabilitiesStatus = Status.ERROR;
        },
      )
      .addCase(getStoresComingSoon.pending, (state) => {
        state.storeFinderPageStatus.getStoresComingSoonStatus = Status.PENDING;
      })
      .addCase(getStoresComingSoon.fulfilled, (state, { payload }) => {
        state.storesComingSoon = payload;
        state.storeFinderPageStatus.getStoresComingSoonStatus =
          Status.FULFILLED;
      })
      .addCase(getStoresComingSoon.rejected, (state, { payload }) => {
        state.error = payload as ResponseError;
        state.storeFinderPageStatus.getStoresComingSoonStatus = Status.ERROR;
      })
      .addCase(getStoreAreasBasedOnSearchInput.pending, (state, { meta }) => {
        const newSearchInput = meta.arg;

        state.areasBasedOnSearchInput = [];

        state.currentSearchStatus = {
          areaSearch: newSearchInput,
          isFinishedStatus: false,
          areaSearchResponse: [],
        };
      })
      .addCase(
        getStoreAreasBasedOnSearchInput.fulfilled,
        (state, { payload, meta }) => {
          const searchInput = meta.arg;

          if (
            !state.currentSearchStatus ||
            state.currentSearchStatus.areaSearch !== searchInput ||
            state.currentSearchStatus.isFinishedStatus
          ) {
            return;
          }

          const uniqueResults = getUniqueResults(payload, searchInput);

          const uniqueResultsFiltered = filterOutCityAreas(uniqueResults);

          state.areasBasedOnSearchInput = uniqueResultsFiltered;
          state.currentSearchStatus.isFinishedStatus = true;
          state.currentSearchStatus.areaSearchResponse = uniqueResults;
        },
      )
      .addCase(
        getStoreAreasBasedOnSearchInput.rejected,
        (state, { payload, meta }) => {
          const existingStatus = state.currentSearchStatus;
          const searchValue = meta.arg;

          if (existingStatus && existingStatus.areaSearch === searchValue) {
            existingStatus.isFinishedStatus = true;
            existingStatus.areaSearchResponse = [];
          }
          state.areasBasedOnSearchInput = [];
          state.error = payload as ResponseError;
          state.storeFinderPageStatus.getAreasBasedOnSearchInputStatus =
            Status.ERROR;
        },
      )
      .addDefaultCase((state) => state);
  },
});

export const storeFinderReducer = slice.reducer;
export const {
  toggleBrowserLocationServices,
  setMapHasBeenDragged,
  setMapZoomHasBeenUpdatedStatus,
  gpsButtonClicked,
  resetGPSButton,
  clearSearch,
  setSearch,
  setFilters,
  clearAllFilters,
  setStoreMapMarkerClicked,
  clearStoreMapMarker,
  clearAreaSuggestionClicked,
  setAreaSuggestionClicked,
  setStoresDisplayed,
  setFilteredStores,
  clearStoreHighlightedState,
  clearAreaSuggestions,
  cancelAreaSearch,
} = slice.actions;
