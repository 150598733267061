import { SxProps } from '@mui/system';
import { theme } from 'utils/theme/theme';

export const reminderDialogContainer: SxProps = {
  top: ['70px', 0, 0],
  '& .MuiDialog-paper': {
    overflow: ['auto', 'hidden', 'hidden'],
  },
  '& .MuiDialogContent-root': {
    width: ['90%', '95%', '95%'],
    overflow: ['auto', 'hidden', 'hidden'],
  },
  '& .MuiDialogTitle-root': {
    padding: '24px 32px 16px 32px',
  },
};

export const contactDialogContainer: SxProps = {
  top: ['70px', 0, 0],
  '& .MuiDialog-paper': {
    overflow: ['auto', 'hidden', 'hidden'],
  },
  '& .MuiDialogContent-root': {
    width: ['90%', '95%', '95%'],
    overflow: ['auto', 'hidden', 'hidden'],
  },
  '& .MuiDialogTitle-root': {
    padding: '16px 32px 8px 32px',
  },
  '& .MuiOutlinedInput-input': {
    paddingY: '15px !important',
  },
};

export const nameContainer: SxProps = {
  '& .MuiTextField-root': {
    mb: 2.5,
  },
};

export const closeBtnStyle: SxProps = {
  float: 'right',
  mt: -3,
  display: 'table',
  p: 0,
};

export const shoppingBtnReject: SxProps = {
  width: ['146px', '310px', '310px'],
  height: ['40px', '56px', '56px'],
};

export const shoppingBtnSelect: SxProps = {
  width: ['146px', '310px', '310px'],
  height: ['40px', '56px', '56px'],
  marginTop: ['0', '2px', '0'],
};

export const actions: SxProps = {
  display: 'flex',
  justifyContent: ['space-between', 'center'],
  alignItems: 'center',
  gap: '20px',
  flexDirection: 'row',
  p: 2,
  '&.MuiDialogActions-root>:not(:first-of-type)': {
    ml: [0, 0, 2],
  },
};

export const contactContainer: SxProps = {
  display: ['block', 'flex', 'flex'],
  width: '98%',
};

export const countryComponent: SxProps = {
  width: ['100%', '140px', '140px'],
  marginRight: [0, '20px', '20px'],
  mb: [2.5, 0, 0],
};

export const addressComponent: SxProps = {
  width: '310px',
  marginRight: '20px',
};

export const inputBoxStyle: SxProps = {
  width: ['96%', '244px', '220px'],
  height: '56px',
  marginTop: ['16px', 0, 0],
  mr: 2.5,
};

export const titleBoxStyle: SxProps = {
  width: ['100%', '160px', '160px'],
  mb: [2.5, 0, 0],
  mr: [0, 2.5, 2.5],
};

export const selectContainer: SxProps = {
  display: 'block',
  width: ['96%', '160px', '160px'],
  mr: 2.5,
  height: '56px',
};

export const boxStyle: SxProps = {
  width: '100%',
};

export const addressTypeContainer: SxProps = {
  display: 'block',
  width: ['320px', '640px', '640px'],
  [theme.breakpoints.between(370, 395)]: {
    width: '340px',
  },
  [theme.breakpoints.between(400, 415)]: {
    width: '363px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '380px',
  },
};

export const phoneBoxStyle: SxProps = {
  width: ['320px', '310px', '310px'],
  marginRight: [0, '20px', '20px'],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(370, 395)]: {
    width: '340px',
  },
  [theme.breakpoints.between(400, 415)]: {
    width: '363px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '380px',
  },
};

export const emailBoxStyle: SxProps = {
  width: ['320px', '310px', '310px'],
  mb: [2.5, 0, 0],
  [theme.breakpoints.between(370, 395)]: {
    width: '340px',
  },
  [theme.breakpoints.between(400, 415)]: {
    width: '363px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '380px',
  },
};

export const streetBoxStyle: SxProps = {
  width: ['100%', '460px', '460px'],
  mr: 2.5,
  mb: [2.5, 0, 0],
};

export const apatBoxStyle: SxProps = {
  width: ['100%', '160px', '160px'],
};
